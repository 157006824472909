import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/styles/HeraldiqueSheet.css'
import HeraldiqueService from '../services/Heraldique.service'
import HeraldiqueOneInterface from '../models/Heraldique/HeraldiqueOne.model'
import HeraldiqueSheetPropsInterface from '../models/Heraldique/HeraldiqueSheetProps.model'

export default function HeraldiqueSheet({id, form, results} : HeraldiqueSheetPropsInterface) {
  const [heraldique, setHeraldique] = useState<HeraldiqueOneInterface>({
    id: 0,
    patronyme: null,
    prenom: null,
    titresDignites: null,
    origine: null,
    date: null,
    siecle: null,
    fief: null,
    aire: null,
    pays: null,
    region: null,
    alliances: null,
    armoiries: null,
    notes: null,
    leClert: null,
    armorialGenChampagne: null,
    notesArmoriaux: null,
    cimiers: null,
    devise: null,
    embleme: null,
    lambrequins: null,
    support: null,
    observations: null,
    refBiblio: null,
    blasonnement_1: null,
    blasonnement_2: null,
    private: null,
  })

    useEffect(() => {        
          HeraldiqueService.getHeraldiqueOne(id)
          .then((response) => {
              setHeraldique(response)
          })
          .catch((error) => {})
               
    }, [id])

  return (
    <div id='heraldique-sheet-container' className='public-container'>

        <Link to="../heraldique" className='return' state={{form: form, results: results}}><h4>Retour à la liste des résultats d'héraldique</h4></Link>

        <h3>{heraldique.patronyme}</h3>

        <div className="show show-armes">

            <div className='results-heraldique-container-sheet'><span className='results-armes'>ID : </span><span className='results-data-armes'>{heraldique.id}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><span className='results-data-armes'>{heraldique.patronyme}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Prénom : </span><span className='results-data-armes'>{heraldique.prenom}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Titres & dignités : </span><span className='results-data-armes'>{heraldique.titresDignites}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Origine : </span><span className='results-data-armes'>{heraldique.origine}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Alliances : </span><span className='results-data-armes'>{heraldique.alliances}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Date : </span><span className='results-data-armes'>{heraldique.date}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Siècle : </span><span className='results-data-armes'>{heraldique.siecle}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Fief : </span><span className='results-data-armes'>{heraldique.fief}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Aire : </span><span className='results-data-armes'>{heraldique.aire}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Pays : </span><span className='results-data-armes'>{heraldique.pays}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Région : </span><span className='results-data-armes'>{heraldique.region}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Le Clert : </span><span className='results-data-armes'>{heraldique.leClert}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Armorial Gen Champagne : </span><span className='results-data-armes'>{heraldique.armorialGenChampagne}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes armoriaux : </span><span className='results-data-armes'>{heraldique.notesArmoriaux}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes : </span><span className='results-data-armes'>{heraldique.notes}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Cimiers : </span><span className='results-data-armes'>{heraldique.cimiers}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Devise : </span><span className='results-data-armes'>{heraldique.devise}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Emblème : </span><span className='results-data-armes'>{heraldique.embleme}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Lambrequins : </span><span className='results-data-armes'>{heraldique.lambrequins}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Support : </span><span className='results-data-armes'>{heraldique.support}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Observations : </span><span className='results-data-armes'>{heraldique.observations}</span></div>
            <div className='results-heraldique-container-sheet'><span className='results-armes'>Réf. Biblio : </span><span className='results-data-armes'>{heraldique.refBiblio}</span></div>
            <div>
              <span className='results-armes'>Blasonnements : </span>
              <ul>
                {heraldique.blasonnement_1 ? <li className='results-data-armes'>{heraldique.blasonnement_1}</li> : null}
                {heraldique.blasonnement_2 ? <li className='results-data-armes'>{heraldique.blasonnement_2}</li> : null}
              </ul>
            </div>

        </div>       
    </div>
  )
}
