import { useState, useEffect, SyntheticEvent } from "react"
import { Link } from "react-router-dom"
import BookService from "../services/Book.service"
import echange from '../assets/images/echange.jpg'
import cross from '../assets/images/supprimer.png'
import dictionnary from '../assets/images/dictionnary-icon.png'
import LoadingImage from '../assets/images/loading.gif'
import left_arrow from '../assets/images/left-arrow.svg'
import right_arrow from '../assets/images/right-arrow.svg'
import aigle from '../assets/images/select/aigle bicéphale.png'
import bande from '../assets/images/select/bande.png'
import bande_e from '../assets/images/select/bandé.png'
import chateau from '../assets/images/select/chateau.png'
import coq from '../assets/images/select/coq.png'
import croix from '../assets/images/select/croix ancrée.png'
import epee from '../assets/images/select/épées passées en sautoir.png'
import fasces from '../assets/images/select/fasces.png'
import force from '../assets/images/select/forces.png'
import losange from '../assets/images/select/losanges en bande.png'
import merlettes from '../assets/images/select/merlettes.png'

import Select from '../assets/Select.json'
import '../assets/styles/BookForm.css'
import BookFormInterface from "../models/Book/BookForm.model"
import BookResponseInterface from "../models/Book/BookResponse.model"

export default function BookForm({results, form}: any) {

    const minioUrl = process.env.REACT_APP_MINIO_BOOK || ""

    const [wordOrder, setWordOrder] = useState<boolean>(form?.ordonnee || true)
    const [books, setBooks] = useState<BookResponseInterface | null>(null)
    const [modal, setModal] = useState(false)
    const [modalContent, setModalContent] = useState([''])
    const [modalIndex, setModalIndex] = useState(0)
    const [select, setSelect] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState<BookFormInterface | null>(form)

    useEffect(() => {
            if(results) {
                setBooks(results)
            }
        }, [setBooks, results])  

    const ActionModal = (index: number, modalState: boolean, list: string[]) => {
        setModalContent(list)
        setModalIndex(index)
        setModal(modalState)
    }
    
    const SubmitFormBooks = (e: SyntheticEvent<HTMLFormElement>) => {
        setLoading(true)

        e.preventDefault()
        let formData = new FormData(e.currentTarget)
        
        if (
            formData.get('armes') === "" &&
            formData.get('patronyme') === "" &&
            formData.get('commune') === "" &&
            formData.get('pays') === "" &&
            formData.get('region') === "" &&
            formData.get('denomination') === "" &&
            formData.get('edifice') === "" &&
            formData.get('auteur') === "" &&
            formData.get('titre') === "" &&
            formData.get('lieuEdition') === "" &&
            formData.get('editeur') === "" &&
            formData.get('anneeEdition') === "" &&
            formData.get('reliure') === "" &&
            formData.get('biographie') === "" &&
            formData.get('cimers') === "" &&
            formData.get('devise') === "" &&
            formData.get('embleme') === "" &&
            formData.get('provenance') === "" &&
            formData.get('site') === "" &&
            formData.get('section') === "" &&
            formData.get('cote') === "" &&
            formData.get('emplacement') === "" &&
            formData.get('bibliographie') === ""
        ) {
            setLoading(false);
            return;
        }

        
        let tempPayload: BookFormInterface = {
            armes: (formData.get('armes') as string).replace('\'', '\\\'') || null,
            ordonnee: wordOrder,
            patronyme: (formData.get('patronyme') as string).replace('\'', '\\\'') || null,
            commune: (formData.get('commune') as string).replace('\'', '\\\'') || null,
            pays: (formData.get('pays') as string).replace('\'', '\\\'') || null,
            region: (formData.get('region') as string).replace('\'', '\\\'') || null,
            denomination: (formData.get('denomination') as string).replace('\'', '\\\'') || null,
            edifice: (formData.get('edifice') as string).replace('\'', '\\\'') || null,
            auteur: (formData.get('auteur') as string).replace('\'', '\\\'') || null,
            titre: (formData.get('titre') as string).replace('\'', '\\\'') || null,
            lieuEdition: (formData.get('lieuEdition') as string).replace('\'', '\\\'') || null,
            editeur: (formData.get('editeur') as string).replace('\'', '\\\'') || null,
            anneeEdition: (formData.get('anneeEdition') as string).replace('\'', '\\\'') || null,
            reliure: (formData.get('reliure') as string).replace('\'', '\\\'') || null,
            biographie: (formData.get('biographie') as string).replace('\'', '\\\'') || null,
            cimers: (formData.get('cimers') as string).replace('\'', '\\\'') || null,
            devise: (formData.get('devise') as string).replace('\'', '\\\'') || null,
            embleme: (formData.get('embleme') as string).replace('\'', '\\\'') || null,
            provenance: (formData.get('provenance') as string).replace('\'', '\\\'') || null,
            site: (formData.get('site') as string).replace('\'', '\\\'') || null,
            section: (formData.get('section') as string).replace('\'', '\\\'') || null,
            cote: (formData.get('cote') as string).replace('\'', '\\\'') || null,
            emplacement: (formData.get('emplacement') as string).replace('\'', '\\\'') || null,
            bibliographie: (formData.get('bibliographie') as string).replace('\'', '\\\'') || null,
        }

        BookService.getBooks(tempPayload).then((response: BookResponseInterface) => {

            setBooks(response)
            setFormState(tempPayload)
            setLoading(false)
        })

    }

    const resetForm = () => {
        setFormState(null)
        setBooks(null)
    }

  return (
    <>
        { !select ? null :                 
            <div className='img-modal-back'>
                <div className='img-modal'>                    
                    <img className='cross-icon' src={cross} alt="cross" onClick={() => setSelect(false)}/>
                    <div id="armes-select-grid">
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' aigle bicéphale';
                        }}>
                            <img src={aigle} alt="aigle bicéphale"/>
                            <span className="results-armes">aigle bicéphale</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' bande';
                        }}>
                            <img src={bande} alt="bande"/>
                            <span className="results-armes">bande</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' bandé';
                        }}>
                            <img src={bande_e} alt="bandé"/>
                            <span className="results-armes">bandé</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' château';
                        }}>
                            <img src={chateau} alt="château"/>
                            <span className="results-armes">château</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' coq';
                        }}>
                            <img src={coq} alt="coq"/>
                            <span className="results-armes">coq</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' croix ancrée';
                        }}>
                            <img src={croix} alt="croix ancrée"/>
                            <span className="results-armes">croix ancrée</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' épées passées en sautoir';
                        }}>
                            <img src={epee} alt="épées passées en sautoir"/>
                            <span className="results-armes">épées passées en sautoir</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' fasces';
                        }}>
                            <img src={fasces} alt="fasces"/>
                            <span className="results-armes">fasces</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' force';
                        }}>
                            <img src={force} alt="force"/>
                            <span className="results-armes">force</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' losanges en bande';
                        }}>
                            <img src={losange} alt="losanges en bande"/>
                            <span className="results-armes">losanges en bande</span>
                        </div>
                        <div className="armes-select-grid-item" onClick={() => {
                            let input = (document.getElementsByName('armes')[0] as HTMLInputElement);
                            input.value = input.value + ' merlettes';
                        }}>
                            <img src={merlettes} alt="merlettes"/>
                            <span className="results-armes">merlettes</span>
                        </div>
                            
                    </div>
                </div>
            </div>
        }

        <form id='armes-form' className="book-form" onSubmit={(e) => SubmitFormBooks(e)}>
                
                <div id="armes-bottom-grid">
                <div id='armes-bottom-grid-left'>
                    <div>
                    <label htmlFor="pays" id='label1'>Pays</label>
                    <input type="text" name="pays" defaultValue={formState?.pays || ''}/>
                    </div>

                    <div>
                    <label htmlFor="region" id='label1'>Région</label>
                    <input type="text" name="region" defaultValue={formState?.region || ''}/>
                    </div>

                    <div>
                    <label htmlFor="commune" id='label1'>Commune</label>
                    <select name="commune" defaultValue={''}>
                            <option value=""> </option>                                            
                            {Select.Commune.map((commune, index) => <option key={index} value={commune}>{commune}</option>)}
                        </select>
                    </div>

                    <div>
                    <label htmlFor="edifice" id='label1'>Edifice de Conservation</label>
                    <input type="text" name="edifice" defaultValue={formState?.edifice || ''}/>
                    </div>

                    <div>
                    <label htmlFor="denomination" id='label1'>Dénomination</label>
                    <select name="denomination" defaultValue={formState?.denomination || ''}>
                            <option value=""> </option>                                            
                            {Select.Denomination.map((denomination, index) => <option key={index} value={denomination}>{denomination}</option>)}
                        </select>
                    </div>               

                    <div>
                        <label htmlFor="auteur" id='label1'>{"Auteur(s)"}</label>
                        <input type="text" name="auteur" defaultValue={formState?.auteur || ''}/>
                    </div>

                    <div>
                        <label htmlFor="titre" id='label1'>Titre</label>
                        <input type="text" name="titre" defaultValue={formState?.titre || ''}/>
                    </div>

                    <div>
                        <label htmlFor="lieuEdition" id='label1'>Lieu d'édition</label>
                        <select name="lieuEdition" defaultValue={formState?.lieuEdition || ''}>
                                <option value=""> </option>                                            
                                {Select.Lieu_Edition.map((lieu, index) => <option key={index} value={lieu}>{lieu}</option>)}
                            </select>                          
                    </div>

                    <div>
                        <label htmlFor="editeur" id='label1'>Editeur</label>
                        <input type="text" name="editeur" defaultValue={formState?.editeur || ''}/>
                    </div>

                    <div>
                        <label htmlFor="anneeEdition" id='label1'>Année d'édition</label>
                        <select name="anneeEdition" defaultValue={formState?.anneeEdition || ''}>
                                <option value=""> </option>                                            
                                {Select.Annee_Edition.map((annee, index) => <option key={index} value={annee}>{annee}</option>)}
                            </select>                          
                    </div>

                    <div>
                        <label htmlFor="reliure" id='label1'>Reliure</label>
                        <input type="text" name="reliure" defaultValue={formState?.reliure || ''}/>
                    </div>
                                           
                </div> 

                <div id='armes-bottom-grid-right'>

                    <div>
                        <label htmlFor="patronyme" id='label1'>Patronyme</label>
                        <input type="text" name="patronyme" defaultValue={formState?.patronyme || ''}/>
                    </div>

                    <div>
                        <label htmlFor="biographie" id='label1'>Biographie</label>
                        <input type="text" name="biographie" defaultValue={formState?.biographie || ''}/>
                    </div>

                    <div id='heraldique-input'>
                        <label id='label1'>Armes</label>
                        <img className='dictionnary-icon' src={dictionnary} alt="dictionnaire" onClick={(e) => setSelect(true)}/>
                        <input type="text" name="armes" defaultValue={formState?.armes || ""}/>
                        <img className='word-order-icon' src={echange} alt="echange" onClick={(e) => setWordOrder(!wordOrder)}/>
                        <p className='word-order-text'>Mots {wordOrder ? "ordonnés" : "désordonnés"}</p>
                    </div>

                    <div>
                        <label htmlFor="cimiers" id='label1'>Cimiers / Ornements extérieurs</label>
                        <input type="text" name="cimers" defaultValue={formState?.cimers || ''}/>
                    </div>

                    <div>
                        <label htmlFor="devise" id='label1'>Devise / Légende</label>
                        <input type="text" name="devise" defaultValue={formState?.devise || ''}/>
                    </div>

                    <div>
                        <label htmlFor="embleme" id='label1'>Emblème</label>
                        <input type="text" name="embleme" defaultValue={formState?.embleme || ''}/>
                    </div>

                    <div>
                        <label htmlFor="provenance" id='label1'>Provenance</label>
                        <input type="text" name="provenance" defaultValue={formState?.provenance || ''}/>
                    </div>

                    <div>
                        <label htmlFor="site" id='label1'>Site</label>
                        <input type="text" name="site" defaultValue={formState?.site || ''}/>
                    </div>

                    <div>
                        <label htmlFor="section" id='label1'>Section</label>
                        <input type="text" name="section" defaultValue={formState?.section || ''}/>
                    </div>

                    <div>
                        <label htmlFor="cote" id='label1'>Cote</label>
                        <input type="text" name="cote" defaultValue={formState?.cote || ''}/>
                    </div>

                    <div>
                        <label htmlFor="emplacement" id='label1'>Emplacement</label>
                        <input type="text" name="emplacement" defaultValue={formState?.emplacement || ''}/>
                    </div>

                    <div>
                        <label htmlFor="bibliographie" id='label1'>Bibliographie</label>
                        <input type="text" name="bibliographie" defaultValue={formState?.bibliographie || ''}/>
                    </div>
                                             
                    </div>
                </div>

                
                <button id="book-submit" type="submit">
                    RECHERCHER
                </button>
                <button type='reset' onClick={resetForm} className='armes-reset-button'>Réinitialiser la recherche</button>
        </form>

        {books === null ? null : <p className='answer-results'>Nombre de résultats : {books.totalCount}</p>}
        {loading ? <img src={LoadingImage} alt="Chargement..." className='loading-img-book loading-img'/>: null}
        
        {books === null ? 
          null :        
              <div className='answer-container'>
                    { !modal ? null :                 
                        <div className='img-modal-back'>
                            <div className='img-modal'>
                                <img className='img-modal-img' src={minioUrl + modalContent[modalIndex]} alt={modalContent[modalIndex]}/>
                                <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(0, false, [])}/>
                                {modalIndex === 0 ? null : <img src={left_arrow} alt="left-arrow" className="left-arrow" onClick={() => ActionModal(modalIndex-1, true, modalContent)}/>}
                                {modalIndex === modalContent.length-1 ? null : <img src={right_arrow} alt="right-arrow" className="right-arrow" onClick={() => ActionModal(modalIndex+1, true, modalContent)}/>}                       
                            </div>
                        </div>
                    }

                    {books.data.map((book, index) => {
                        return (
                            <div className='results-container' key={index}>
                                <ul>
                                    <li className='patronyme patronyme-book'><Link to={"./" + book.id} state={{form: formState, results: books}}><span id="label1">{book.patronyme}</span></Link>, {book.commune}</li>
                                </ul>
                                
                                <div>
                                {!book.refMinio ? null :
                                    book.refMinio.split(';').map((atachement, index, array) => {
                                        
                                        return (
                                            <img key={index} className='atachement-img' src={minioUrl + atachement} alt={atachement} onClick={() => ActionModal(index, true, array)}/>
                                        )
                                    })
                                }
                            </div>

                            </div>
                        )
                    })}

            </div>
        }
        
    </>
  )
}
