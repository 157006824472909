import axios from '../Axios';
import AdminInterface from '../models/Admin/Admin.model';

const AdminService = {

    login: async (payload: AdminInterface) => {
        try {
            const response = await axios.post('/auth/login', payload);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },
    
    logout: async () => {
        try {
            const response = await axios.get('/auth/logout', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            sessionStorage.removeItem('access-token');
            sessionStorage.removeItem('expiration');
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getInfo: async () => {
        try {
            const response = await axios.get('/user/me', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    forgot: async () => {
        try {
            const response = await axios.get('/user/forgot-password');
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    change: async (payload :any) => {
        try {
            const response = await axios.patch('/user', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getEquivalences: async () => {
        try {
            const response = await axios.get('/equivalences',  {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    deleteEquivalence: async (id: string) => {
        try {
            const response = await axios.delete(`/equivalences/${id}`,  {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    addEquivalence: async (payload: any) => {
        try {
            const response = await axios.post('/equivalences', payload,  {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getAreas: async () => {
        try {
            const response = await axios.get('/areas');
            return response.data
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    patchReference: async (id: any) => {
        try {
            const response = await axios.patch(`/references/${id}`,{} ,  {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    postArea : async (payload: any) => {
        try {
            const response = await axios.post('/areas', payload,  {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data
        } catch (error : any) {
            
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    postPays: async (payload: any) => {
        try {
            const response = await axios.post('/countries', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    postRegion: async (payload: any) => {
        try {
            const response = await axios.post('/regions', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    postReference: async (payload: any) => {
        try {
            const response = await axios.post('/references', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    deleteArea: async (id: string) => {
        try {
            const response = await axios.delete(`/areas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    deletePays: async (id: string) => {
        try {
            const response = await axios.delete(`/countries/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    deleteRegion: async (id: string) => {
        try {
            const response = await axios.delete(`/regions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    },

    deleteReference: async (id: string) => {
        try {
            const response = await axios.delete(`/references/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error: any) {
            ;
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            } else {
                throw error;
            }
        }
    }

}

export default AdminService;