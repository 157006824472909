import axios from '../Axios';

const LibraryService = {

    getLibrary: async () => {
        try {
            const response = await axios.get('/books');
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    createWork: async (data: any) => {
        try {
            const response = await axios.post('/books', data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    createVolume: async (data: any) => {
        try {
            const response = await axios.post('/volumes', data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    deleteWork: async (id: number) => {
        try {
            const response = await axios.delete(`/books/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    deleteVolume: async (id: number) => {
        try {
            const response = await axios.delete(`/volumes/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

};
export default LibraryService;