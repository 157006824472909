import axios from '../Axios';

const PrivateService = {

    IsCorrect: async (pass: string) => {
        try {
            const response = await axios.post(`/european-auth`, {password : pass});
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    }

};
export default PrivateService;