import '../assets/styles/Heraldique.css'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import heraldique from '../assets/images/heraldique.png'
import HeraldiqueForm from '../components/HeraldiqueForm'
import HeraldiqueSheet from '../components/HeraldiqueSheet'
import HeraldiqueAdmin from '../components/HeraldiqueAdmin'
import HeraldiqueType from '../types/Heraldique.enum'
import HeraldiquesResponseInterface from '../models/Heraldique/HeraldiqueResponse.model'
import HeraldiqueFormInterface from '../models/Heraldique/HeraldiqueForm.model'
import AdminService from '../services/Admin.service'

export default function Heraldique({type} : {type: HeraldiqueType}) {  

  const navigate = useNavigate();
  let expiration = sessionStorage.getItem('expiration');
  let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);

  const form : HeraldiqueFormInterface | null = useLocation()?.state?.form || null;
  const results: HeraldiquesResponseInterface | null = useLocation()?.state?.results || null;

  const { id } = useParams();
  if (type === HeraldiqueType.administration && !connect) {
    return <Navigate to='/administration/connexion' state={{ from: '/heraldique/administration' }} />;
  }
  
  return (
    <div id="heraldique-back">

        {connect ? <button className='deconnexion' onClick={() => {
          AdminService.logout().then(() => {navigate('/heraldique')})
          }}>Déconnexion</button> : null}      

        <img id="aube" src={heraldique} alt="heraldique" />

        <div className='heraldique-navbar'>
            <Link to="/accueil">
            <div className='heraldique-navbar-text'>
            Accueil
            </div>                
            </Link>
            <Link to="/heraldique">
              <div className='heraldique-navbar-text'>
              Recherche Héraldique
              </div>                
            </Link>
            {connect ? 
            <div className='heraldique-navbar-text' onClick={() => {
            navigate('/heraldique/administration')
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/heraldique/administration' }}>
              <div className='heraldique-navbar-text'>
                Administration
              </div>                
            </Link>
            }
        </div>  

        {type === HeraldiqueType.administration ? connect === null ? <Navigate to={'/administration/connexion'} state={{ from: '/heraldique' }}/> : <HeraldiqueAdmin/> : id ? <HeraldiqueSheet id={id} form={form} results={results}/> : <HeraldiqueForm form={form} results={results}/>}
                        
    </div>
  )
}
