import React from 'react'
import '../assets/styles/Home.css'
import accueil_logo_palisep from '../assets/images/accueil_logo-palisep.png'
import accueil_armorial from '../assets/images/accueil_armorial.jpg'
import accueil_livre from '../assets/images/accueil_livre.jpg'
import accueil_armes2 from '../assets/images/accueil_armes2.jpg'
import accueil_bibli2 from '../assets/images/accueil_bibli2.jpg'
import accueil_logo_aibl from '../assets/images/accueil_logo-aibl.png'
import accueil_logo_isep from '../assets/images/accueil_logo-isep.png'
import { Link } from 'react-router-dom'

export default function Home() {

  return (
    <div id='home-container'>

      <div id='home-middle'>
        <Link to="/accueil">
          <img src={accueil_logo_palisep} alt="accueil_logo-palisep" />
        </Link>    
            
        <a href="https://heraldica.palisep.fr/">
          <p>Cours d'héraldique</p>
        </a>        
      </div>

      <div className='home-back-img'>
        <img src={accueil_armorial} alt="accueil_armorial" />
        <Link to="/armorial">
          <div className='home-back-text'>          
            L'armorial historique et monumental européen publie les campagnes d'inventaire photographique héraldique réalisées grâce à des missions commandées par les collectivités territoriales et la DRAC de l'ex-région Champagne Ardenne ainsi que des relevés dans d'autres régions ou pays (env. 5000 entrées).			
          </div>
        </Link>        
      </div>

      <div className='home-back-img'>
        <img src={accueil_livre} alt="accueil_livre" />
        <Link to="/livre">
          <div className='home-back-text'>            
				    L'héraldique et le livre publie des notices sur les fers de reliure et les présences héraldiques ou emblématiques sur ou dans le livre manuscrit ou imprimé (env. 1300 notices).
          </div>
        </Link>        
      </div>

      <div className='home-back-img'>
        <img src={accueil_armes2} alt="accueil_armes2" />
        <Link to="/heraldique">
          <div className='home-back-text'>  
            L'héraldique recense des armoiries européennes sous forme de blasonnements, fruit de dépouillements multiples (env. 350 000 entrées).			 
          </div>
        </Link>        
      </div>

      <div className='home-back-img'>
        <img src={accueil_bibli2} alt="accueil_bibli2" />
        <Link to="/bibliotheques">
          <div className='home-back-text'>
            La Bibliographie permet avec un lien d'accéder aux versions numérisées et mises en ligne d'une documentation manuscrite ou imprimée emblématiques et héraldiques par des organismes internationaux (Archives, bibliothèques, etc…).			
          </div>
        </Link>        
      </div>

      <div id='home-footer'>
        <a href="http://www.aibl.fr">
          <img id='home-footer-img-aibl' src={accueil_logo_aibl} alt="accueil_logo-aibl" />
        </a>
        
        <a href="http://isep.fr">
          <img id='home-footer-img-isep' src={accueil_logo_isep} alt="accueil_logo-isep" /> 
        </a>
      </div>
    </div>
  )
}
