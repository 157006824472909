import axios from '../Axios';

const CounterService = {

    modifyCount: async (count: string = "0") => {
        try {
            const response = await axios.patch(`/visit-counter/${count}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getCount: async () => {
        try {
            const response = await axios.get(`/visit-counter`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

};
export default CounterService;