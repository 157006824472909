import { useState, useEffect, JSX } from "react";
import EquivalenceInterface from "../models/Admin/Equivalences.model";
import AdminService from "../services/Admin.service";
import CounterService from "../services/Counter.service";
import Help from "../assets/images/help.gif";
import cross from "../assets/images/supprimer.png";
import LoadingImage from "../assets/images/loading.gif";
import ArmorialService from "../services/Armorial.service";
import ArmeOneInterface from "../models/Armorial/Armes/ArmeOne.model";
import IconoOneInterface from "../models/Armorial/Iconographie/IconoOne.model";
import { Link } from "react-router-dom";


export default function ArmorialAdmin() {
    
    const minioUrl = process.env.REACT_APP_MINIO_ARMORIAL || ""

    const [connect, setConnect] = useState<any>(null);
    const [active, setActive] = useState<string>('admin');
    const [letterArme, setLetterArme] = useState<string>('A');
    const [letterIcono, setLetterIcono] = useState<string>('A');
    const [step, setStep] = useState<number>(1);
    const [correctPass, setcorrectPass] = useState<string | null>(null);
    const [correctMail, setcorrectMail] = useState<string | null>(null);
    const [correctCount, setCorrectCount] = useState<string | null>(null);
    const [correctEquivalence, setCorrectEquivalence] = useState<string | null>(null);
    const [correctArmes, setCorrectArmes] = useState<string | null>(null);
    const [armesIconos, setArmesIconos] = useState<{id: string, vedetteChandon: string}[]>([]);
    const [armesIconosID, setArmesIconosID] = useState<string>("");
    const [armesIconosMod, setArmesIconosMod] = useState<{id: string, vedetteChandon: string}[]>([]);
    const [armesIconosIDMod, setArmesIconosIDMod] = useState<string>("");
    const [armesModCorrect, setArmesModCorrect] = useState<string | null>(null);
    const [iconoModCorrect, setIconoModCorrect] = useState<string | null>(null);
    const [correctIconos, setCorrectIconos] = useState<string | null>(null);
    const [correctImportArmes, setCorrectImportArmes] = useState<string | null>(null);
    const [correctImportIconos, setCorrectImportIconos] = useState<string | null>(null);
    const [correctIDArme, setcorrectIDArme] = useState<JSX.Element | null>(null);
    const [correctIDIcono, setcorrectIDIcono] = useState<JSX.Element | null>(null);
    const [idArme, setIDArme] = useState<string | null>(null);
    const [idIcono, setIDIcono] = useState<string | null>(null);
    const [count, setCount] = useState<{"id" : 0,"count" : string, "resetDate" : string}>({id: 0, count: "", resetDate: ""});
    const [loadingArme, setLoadingArme] = useState<boolean>(false);
    const [loadingIcono, setLoadingIcono] = useState<boolean>(false);
    const [equivalences, setEquivalences] = useState<EquivalenceInterface[]>([]);
    const [ArmesLetter, setArmesLetter] = useState<ArmeOneInterface[]>([]);
    const [IconosLetter, setIconosLetter] = useState<IconoOneInterface[]>([]);
    const [newIconoImage, setNewIconoImage] = useState<File | null>(null);
    const [newIconoImageSet, setNewIconoImageSet] = useState<boolean>(false);

    const [IDArmeresponse, setIDArmeresponse] = useState<ArmeOneInterface>({
        id: null,
        patronyme: null,
        prenom: null,
        famille: null,
        titresDignites: null,
        origine: null,
        date: null,
        siecle: null,
        fief: null,
        pays: null,
        province: null,
        region: null,
        department: null,
        ville: null,
        alliances: null,
        armoiries: null,
        notes: null,
        observations: null,
        sources: null,
        leClert: null,
        armorialGenChampagne: null,
        notesArmoriaux: null,
        genealogie: null,
        document: null,
        timbre: null,
        cimier: null,
        deviseCri: null,
        tenantsSupport: null,
        decoration: null,
        ornementsExt: null,
        emblemes: null,
        imagesGeneal: null,
        imagesDoc: null,
        blasonnement_1: null,
        blasonnement_2: null,
        sceau: null,
        private: null,
        pictures: []
    });

    const [IDIconoresponse, setIDIconoresponse] = useState<IconoOneInterface>({
        id: null,
        vedetteChandon: null,
        auteurCliche: null,
        typeCliche: null,
        anneeCliche: null,
        pays: null,
        region: null,
        departement: null,
        commune: null,
        village: null,
        edificeConservation: null,
        emplacementDansEdifice: null,
        photo: null,
        communeInseeNumber: null,
        refIm: null,
        refPa: null,
        refIa: null,
        artificialNumberDecrock: null,
        famille: null,
        individu: null,
        qualite: null,
        date: null,
        denomination: null,
        titre: null,
        categorie: null,
        materiau: null,
        refReproductions: null,
        bibliographie: null,
        remarques: null,
        auteurs: null,
        lieuEdition: null,
        editeur: null,
        anneEdition: null,
        reliure: null,
        provenance: null,
        site: null,
        section: null,
        cote: null,
        folioEmplacement: null,
        cimiers: null,
        blasonnement_1: null,
        blasonnement_2: null,
        armorialId: null,
        refMinio: null,
        sceau: null,
    })

    const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

    useEffect(() => {

        AdminService.getInfo().then((response) => {
            setConnect(response);
        }).catch((error) => {
            throw error
        })

        CounterService.getCount().then((response) => {
            setCount(response);
        }).catch((error) => {
            throw error
        })

        AdminService.getEquivalences().then((response) => {
            setEquivalences(response);
        }).catch((error) => {
            throw error
        })

        ArmorialService.getArmesLetter('A').then((response: ArmeOneInterface[]) => {
            setArmesLetter(response)
        }).catch((error) => {
            throw error
        })

        ArmorialService.getIconosLetter('A').then((response: IconoOneInterface[]) => {
            setIconosLetter(response)
        }).catch((error) => {
            throw error
        })
    }, [])

    const resetCounter = async () => {
        const response = await CounterService.modifyCount()
        if (response) {
            setCorrectCount("Compteur réinitialisé");
            count.count = "0";
            let date = new Date();
            count.resetDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
    }
    
    const modifyMail = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const payload = {
            mail: data.get('new mail') as string
        }

        if (payload.mail !== "" && payload.mail !== connect?.mail) {
            const response = await AdminService.change(payload);

            if (response === false) {
                setcorrectMail("Une erreur est survenue");
                return;
            }
            else {
                setcorrectMail("Adresse mail modifiée");
                setConnect({...connect, mail: payload.mail});
                }
        }
        (document.getElementsByName("new mail")[0] as HTMLInputElement).value = "";
    }

    const modifyPassword = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (step === 1) {
            const payload = {
                username: data.get('login') as string,
                password: data.get('actual pass') as string
            }

            const response = await AdminService.login(payload);

            if (response === false) {
                setcorrectPass("Mauvais identifiants");
                return;
            }
            else {
                setStep(2);
            }
        }
        else if (step === 2) {
            const payload = {
                password: data.get('new pass') as string
            }

            if (payload.password !== "" && payload.password !== data.get('actual pass') && payload.password === data.get('confirm pass')) {
                const response = await AdminService.change(payload);
                if (response === false) {
                    setcorrectPass("Une erreur est survenue");
                    return;
                }
                else {
                    setcorrectPass("Mot de passe modifié");
                    setStep(1);
                }
            }
        }
    }

    const searchIDArme = async (givedId?: string) => {
        setLoadingArme(true);

        if ((idArme !== null && idArme !== "") || (givedId !== null && givedId !== "")) {
            setIDArmeresponse({
                id: null,
                patronyme: null,
                prenom: null,
                famille: null,
                titresDignites: null,
                origine: null,
                date: null,
                siecle: null,
                fief: null,
                pays: null,
                province: null,
                region: null,
                department: null,
                ville: null,
                alliances: null,
                armoiries: null,
                notes: null,
                observations: null,
                sources: null,
                leClert: null,
                armorialGenChampagne: null,
                notesArmoriaux: null,
                genealogie: null,
                document: null,
                timbre: null,
                cimier: null,
                deviseCri: null,
                tenantsSupport: null,
                decoration: null,
                ornementsExt: null,
                emblemes: null,
                imagesGeneal: null,
                imagesDoc: null,
                blasonnement_1: null,
                blasonnement_2: null,
                sceau: null,
                private: null,
                pictures: []
              });

              var response: ArmeOneInterface | null = null;

              if (givedId) {
                ArmorialService.getArmeOne(givedId as string).then((answer) => {
                    response = answer;
                    setArmesIconosMod([]);
                    answer.pictures.forEach((picture: any) => {
                        setArmesIconosMod([...armesIconosMod, {id: picture.id, vedetteChandon: picture.vedetteChandon}]);
                    })                    

                    if (!answer) {
                        setcorrectIDArme(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{idArme}</span>.</span>);
                    }
                    else {
                        setIDArmeresponse(answer);
                        setcorrectIDArme(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{idArme}</span> :</span>)
                    }
                })
              }
              else {
                ArmorialService.getArmeOne(idArme as string).then((answer) => {
                    response = answer;
                    setArmesIconosMod([]);
                    answer.pictures.forEach((picture: any) => {
                        setArmesIconosMod(prevState => [...prevState, {id: picture.id, vedetteChandon: picture.vedetteChandon}]);
                    })

                    if (!answer) {
                        setcorrectIDArme(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{idArme}</span>.</span>);
                    }
                    else {
                        setIDArmeresponse(answer);
                        setcorrectIDArme(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{idArme}</span> :</span>)
                    }
                })
              }

            if (!response) {
                setcorrectIDArme(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{idArme}</span>.</span>);
            }
            else {
                setIDArmeresponse(response);
                setcorrectIDArme(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{idArme}</span> :</span>)
            }
        }
        setLoadingArme(false);
    }

    const searchIDIcono = async (givedId?: string) => {
        setLoadingIcono(true);

        if ((idIcono !== null && idIcono !== "") || (givedId !== null && givedId !== "")) {
            setIDIconoresponse({
                id: null,
                vedetteChandon: null,
                auteurCliche: null,
                typeCliche: null,
                anneeCliche: null,
                pays: null,
                region: null,
                departement: null,
                commune: null,
                village: null,
                edificeConservation: null,
                emplacementDansEdifice: null,
                photo: null,
                communeInseeNumber: null,
                refIm: null,
                refPa: null,
                refIa: null,
                artificialNumberDecrock: null,
                famille: null,
                individu: null,
                qualite: null,
                date: null,
                denomination: null,
                titre: null,
                categorie: null,
                materiau: null,
                refReproductions: null,
                bibliographie: null,
                remarques: null,
                auteurs: null,
                lieuEdition: null,
                editeur: null,
                anneEdition: null,
                reliure: null,
                provenance: null,
                site: null,
                section: null,
                cote: null,
                folioEmplacement: null,
                cimiers: null,
                blasonnement_1: null,
                blasonnement_2: null,
                armorialId: null,
                refMinio: null,
                sceau: null,
              });

              let response: IconoOneInterface;

              if (givedId) {
                response = await ArmorialService.getIconoOne(givedId as string);
              }
              else {
                response = await ArmorialService.getIconoOne(idIcono as string);
              }            

            if (!response) {
                setcorrectIDIcono(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{idIcono}</span>.</span>);
            }
            else {
                setIDIconoresponse(response);
                setcorrectIDIcono(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{idIcono}</span> :</span>)
            }
        }
        setLoadingIcono(false);
    }

    const addEquivalence = async (e: any) => {

        e.preventDefault();
        const newEquivalence = new FormData(e.currentTarget);

        const response = await AdminService.addEquivalence(newEquivalence);

        if (response === false) {
            setCorrectEquivalence("Une erreur est survenue");
        }
        else {
            setEquivalences([...equivalences, response]);
            setCorrectEquivalence("Equivalence ajoutée");
        }            
        (document.getElementsByName("expression")[0] as HTMLInputElement).value = "";
        (document.getElementsByName("signification")[0] as HTMLInputElement).value = "";
    }

    const deleteEquivalence = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer cette équivalence ?\n\n- Expression 1 : ${equivalences[index].expression}\n\n- Expression 2 : ${equivalences[index].signification}`)) {
            const response = await AdminService.deleteEquivalence(id);

            if (response === false) {
                alert("Une erreur est survenue");
            }
            else {
                setEquivalences(equivalences.filter((equivalence) => equivalence.id !== id));
                alert("Equivalence supprimée");     
                setEquivalences(equivalences.filter((equivalence) => equivalence.id !== id));
            }
        }
        else {
            alert("Suppression annulée");
        }        
    }

    const searchArmes = async (letter: string) => {
        const response = await ArmorialService.getArmesLetter(letter);
        if (response) {
            setArmesLetter(response);
            setLetterArme(letter);
        }        
    }

    const searchIconos = async (letter: string) => {
        const response = await ArmorialService.getIconosLetter(letter);
        if (response) {
            setIconosLetter(response);
            setLetterIcono(letter);
        }        
    }

    const addArmes = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);

        if (formData.get('patronyme') === ""  || formData.get('patronyme') === null) {
            setCorrectArmes("Le patronyme est obligatoire");
            return;
        }

        const payload  = {
            patronyme: formData.get('patronyme') as string,
            prenom: formData.get('prenom') as string,
            famille: formData.get('famille') as string,
            titresDignites: formData.get('titresDignites') as string,
            origine: formData.get('origine') as string,
            date: formData.get('date') as string,
            siecle: formData.get('siecle') as string,
            fief: formData.get('fief') as string,
            pays: formData.get('pays') as string,
            province: formData.get('province') as string,
            region: formData.get('region') as string,
            department: formData.get('department') as string,
            ville: formData.get('ville') as string,
            alliances: formData.get('alliances') as string,
            armoiries: formData.get('armoiries') as string,
            notes: formData.get('notes') as string,
            observations: formData.get('observations') as string,
            sources: formData.get('sources') as string,
            leClert: formData.get('leClert') as string,
            armorialGenChampagne: formData.get('armorialGenChampagne') as string,
            notesArmoriaux: formData.get('notesArmoriaux') as string,
            genealogie: formData.get('genealogie') as string,
            document: formData.get('document') as string,
            timbre: formData.get('timbre') as string,
            cimier: formData.get('cimier') as string,
            deviseCri: formData.get('deviseCri') as string,
            tenantsSupport: formData.get('tenantsSupport') as string,
            decoration: formData.get('decoration') as string,
            ornementsExt: formData.get('ornementsExt') as string,
            emblemes: formData.get('emblemes') as string,
            imagesGeneal: formData.get('imagesGeneal') as string,
            imagesDoc: formData.get('imagesDoc') as string,
            blasonnement_1: formData.get('blasonnement_1') as string,
            blasonnement_2: formData.get('blasonnement_2') as string,
            sceau: formData.get('sceau') === 'on',
            private: formData.get('private') === 'on',
            picturesId: armesIconos.map(icono => icono.id).join(',')
        }

        const response = await ArmorialService.addArmes(payload);
        if (response) {
            setCorrectArmes("Arme ajoutée");
            if (formData?.get('patronyme')?.toString().startsWith(letterArme)) {
                setArmesLetter([...ArmesLetter, response]);
            }
            e.target.reset();
        }
        else {
            setCorrectArmes("Une erreur est survenue");
        }
    }

    const addArmesImages = async () => {
        if (armesIconosID !== null && armesIconosID !== "" && !armesIconos.find((item) => item.id.toString() === armesIconosID)) {
            const response = await ArmorialService.getIconoOne(armesIconosID);

            if (response) {
                setArmesIconos([...armesIconos, {id: response.id, vedetteChandon: response.vedetteChandon}]);
                setArmesIconosID("");
                }
        }
    }

    const modifyArmesImages = async () => {
        if (armesIconosIDMod !== null && armesIconosIDMod !== "" && !armesIconosMod.find((item) => item.id.toString() === armesIconosIDMod)) {
            const response = await ArmorialService.getIconoOne(armesIconosIDMod);

            if (response) {
                setArmesIconosMod([...armesIconosMod, {id: response.id, vedetteChandon: response.vedetteChandon}]);
                setArmesIconosIDMod("");
                }
        }
    }

    const addIconos = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);

        if (formData.get('vedetteChandon') === ""  || formData.get('vedetteChandon') === null) {
            setCorrectIconos("Le nom est obligatoire");
            return;
        } else if (formData.get('file') === null) {
            setCorrectIconos("L'image est obligatoire");
        }

        const response = await ArmorialService.addIconos(formData);
        if (response) {
            setCorrectIconos("Image ajoutée");
            if (formData?.get('nom image')?.toString().startsWith(letterIcono)) {
                setIconosLetter([...IconosLetter, response]);
            }
            e.target.reset();
        }
        else {
            setCorrectIconos("Une erreur est survenue");
        }
    }

    const importArmes = async (form: any) => {
        const formData = new FormData(form);
        const data = new FormData();
        data.append('file', formData.get('file arme') as File);

        if (data) {
            const response = await ArmorialService.importArmes(data);
            if (response) {
                searchArmes(letterArme);
                setCorrectImportArmes("Armes importées");
            }
            else {
                setCorrectImportArmes("Une erreur est survenue");
            }
        }
    }

    const importIconos = async (form: any) => {
        const formData = new FormData(form);
        const data = new FormData();
        data.append('file', formData.get('file icono') as File);

        if (data) {
            const response = await ArmorialService.importIconos(data);
            if (response) {
                searchIconos(letterIcono);
                setCorrectImportIconos("Iconographies importées");
            }
            else {
                setCorrectImportIconos("Une erreur est survenue");
            }
        }
    }

    const deleteArme = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer l'héraldique suivante ?\n\n- ID : ${ArmesLetter[index].id}\n- Patronyme / Nom : ${ArmesLetter[index].patronyme}\n\nAttention, cette action est irréversible !`)) {
            const response = await ArmorialService.deleteArme(id);
            if (response) {
                setArmesLetter(ArmesLetter.filter((Armes) => Armes?.id?.toString() !== id));
                alert("Arme supprimée");
            }
            else {
                setCorrectImportArmes("Une erreur est survenue, l'arme n'a pas été supprimée");
            }
        } 
        else {
            setCorrectImportArmes("Suppression annulée");
        }
        
    }

    const deleteIconos = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer l'iconographie suivante ?\n\n- ID : ${IconosLetter[index].id}\n- Vedette Chandon : ${IconosLetter[index].vedetteChandon}\n\nAttention, cette action est irréversible !`)) {
            const response = await ArmorialService.deleteIcono(id);
            if (response) {
                setIconosLetter(IconosLetter.filter((Icono) => Icono?.id?.toString() !== id));
                alert("Iconographie supprimée");
            }
            else {
                setCorrectImportIconos("Une erreur est survenue, l'iconographie n'a pas été supprimée");
            }
        } 
        else {
            setCorrectImportIconos("Suppression annulée");
        }
        
    }

    const modifyArme = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);
        formData.delete('pictures');
        formData.delete('id');

        const payload = {
            patronyme: formData.get('patronyme') as string,
            prenom: formData.get('prenom') as string,
            famille: formData.get('famille') as string,
            titresDignites: formData.get('titresDignites') as string,
            origine: formData.get('origine') as string,
            date: formData.get('date') as string,
            siecle: formData.get('siecle') as string,
            fief: formData.get('fief') as string,
            pays: formData.get('pays') as string,
            province: formData.get('province') as string,
            region: formData.get('region') as string,
            department: formData.get('department') as string,
            ville: formData.get('ville') as string,
            alliances: formData.get('alliances') as string,
            armoiries: formData.get('armoiries') as string,
            notes: formData.get('notes') as string,
            observations: formData.get('observations') as string,
            sources: formData.get('sources') as string,
            leClert: formData.get('leClert') as string,
            armorialGenChampagne: formData.get('armorialGenChampagne') as string,
            notesArmoriaux: formData.get('notesArmoriaux') as string,
            genealogie: formData.get('genealogie') as string,
            document: formData.get('document') as string,
            timbre: formData.get('timbre') as string,
            cimier: formData.get('cimier') as string,
            deviseCri: formData.get('deviseCri') as string,
            tenantsSupport: formData.get('tenantsSupport') as string,
            decoration: formData.get('decoration') as string,
            ornementsExt: formData.get('ornementsExt') as string,
            emblemes: formData.get('emblemes') as string,
            imagesGeneal: formData.get('imagesGeneal') as string,
            imagesDoc: formData.get('imagesDoc') as string,
            blasonnement_1: formData.get('blasonnement_1') as string,
            blasonnement_2: formData.get('blasonnement_2') as string,
            sceau: formData.get('sceau') === 'on',
            private: formData.get('private') === 'on',
            picturesId: armesIconos.map(icono => icono.id).join(',')
        }
        
        ArmorialService.modifyArme(idArme || "", payload).then((response) => {
            if (response) {
                setArmesModCorrect("Arme modifiée");
            }
            else {
                setArmesModCorrect("Une erreur est survenue");
            }
        }).catch((error) => {
            throw error
        })

    }

    const modifyIcono = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);    
        formData.delete('id');

        if(newIconoImage === null) {
            formData.delete('file');
        }

        ArmorialService.modifyIcono(idIcono || "", formData).then((response) => {
            if (response) {
                setIconoModCorrect("Iconographie modifiée");
            }
            else {
                setIconoModCorrect("Une erreur est survenue");
            }
        }).catch((error) => {
            throw error
        })

    }

    return (   
        <div id='armesfamilles-container' className="public-container">
    
            <h3 className="admin-title">Espace d'administration</h3>
    
            <div className="onglets">
                <span className={active === 'admin' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                    setActive('admin')
                    setCorrectCount("")
                    setcorrectPass("")
                    setcorrectMail("")
                    }}>Administrateur</span>
                <span className={active === 'equivalence' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('equivalence')}>Gestion des équivalences</span>
                <span className={active === 'ajout' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('ajout')}>Ajout de contenus</span>
                <span className={active === 'gestion-blason' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('gestion-blason')}>Gestion des blasons / familles</span>
                <span className={active === 'gestion-image' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('gestion-image')}>Gestion des images</span>
                <span className={active === 'recherche' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                    setActive('recherche')
                    setcorrectIDArme(null)
                    setcorrectIDIcono(null)
                    }}>Recherche ID</span>
            </div>
    
            <div className="admin-functions-container">
                {active === 'admin' && (
                    <div>
                        <form className='admin-form' onSubmit={(e) => modifyPassword(e)}>
                            <fieldset>
                            <legend className='admin-legend'>
                                Identifiants 
                                <img src={Help} alt="help" className="admin-help-img"/>
    
                                <div>
                                    Pour changer de mot de passe :
                                    <ul>
                                        <li>Saisissez le login et le mot de passe ACTUELS</li>
                                        <li>Saisissez 2 fois votre nouveau mot de passe</li>                                    
                                    </ul>
                                </div>                        
                            </legend>
    
                            {step === 1 && (
                                <div>
                                    <div>
                                        <label className='admin-label'>Pseudonyme :</label>
                                        <input type="text" name="login"/>
                                        <br/><br/>
                                    </div>
    
                                    <div>
                                        <label className='admin-label'>Mot de passe actuel : </label>
                                        <input type="password" name="actual pass"/>
                                        <br/><br/>
                                    </div>
                                </div>
                            )}
    
                            {step === 2 && (
                                <div>
                                    <div>
                                        <label className='admin-label'>Nouveau mot de passe :</label>
                                        <input type="text" name="new pass"/>
                                        <br/><br/>
                                    </div>
    
                                    <div>
                                        <label className='admin-label'>Saisissez de nouveau : </label>
                                        <input type="text" name="confirm pass"/>
                                        <br/><br/>
                                    </div>
                                </div>
                            )}
    
                            <button type='submit'>Valider</button>
                            {correctPass ? <span id='label10'>{correctPass}</span> : null}
    
                            </fieldset>
                        </form>
    
                        <form className='admin-form admin-form-space'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Compteur de visites 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                    Le nombre indique le nombre de visites effectuées sur le site la date indiquée<br/>
                                    En cliquant sur "Réinitialiser le compteur", le compte sera repris de zéro et <br/>la date remplacée par la date courante.
                                    </div> 
                                </legend>
    
                                <div>
                                    <span className='admin-label'>Le site a été visité  </span>                                
                                    <span className='actual-mail'>{count.count}</span>
                                    <span className='admin-label'> fois depuis le </span>
                                    <span className='actual-mail'>{parseInt(count.resetDate.split('-')[2]) + ' ' + month[parseInt(count.resetDate.split('-')[1])-1] + ' ' + count.resetDate.split('-')[0]}</span>
                                </div> 
    
                                <button type="button" id="reset-count-button" onClick={resetCounter}>Réinitialiser le compteur</button>
                                {correctCount ? <span id='label10'>{correctCount}</span> : null}
    
                            </fieldset>
                        </form>
    
                        <form className='admin-form admin-form-space' onSubmit={(e) => modifyMail(e)}>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Modification de l'adresse mail 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                    Entrez la nouvelle adresse mail puis cliquez sur "changer"
                                    </div> 
                                </legend>
    
                                <div>
                                    <span className='admin-label'>L'adresse mail administrateur configurée est : </span>
                                    <span className='actual-mail'>{connect?.mail}</span>
                                    <br/><br/>
                                </div>
    
                                <div>
                                    <label className='admin-label'>Nouvelle adresse mail : </label>
                                    <input type="email" name="new mail"/>
                                    <br/><br/>
                                </div>
    
                                <button type='submit'>Changer</button>
                                {correctMail ? <span id='label10'>{correctMail}</span> : null}
    
                            </fieldset>
                        </form>
                    </div>
                )}
                {active === 'equivalence' && (
                    <div>
                        <form className='admin-form admin-form-space admin-form-table'  onSubmit={(e) => { addEquivalence(e); }}>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Tableau des équivalences                                
                                </legend>
                                <div className="table-wrapper-equ">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Expression 1</th>
                                                <th>Expression 2</th>
                                                <th id="admin-form-delete-col">Supprimer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        equivalences?.map((equivalence, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{equivalence.expression}</td>
                                                        <td>{equivalence.signification}</td>
                                                        <td><img src={cross} alt="supprimer" onClick={() => deleteEquivalence(equivalence.id, index)}/></td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='admin-equ-add'>
                                    <div>
                                        <input type="text" name='expression' placeholder='Expression 1'/>
                                        <input type="text" name='signification' placeholder='Expression 2'/>
                                        <button type='submit'>Ajouter l'équivalence</button>
                                    </div>
                                    {correctEquivalence ? <span id='label10'>{correctEquivalence}</span> : null}
                                </div>
    
                            </fieldset>
                        </form>
                    </div>
                )}
                {active === 'ajout' && (
                    <div>
    
                    <form className='admin-form admin-form-space' id='admin-excel-form'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Mise à jour de la base par le fichier Excel 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                        Pour insérer des contenus via un fichier Excel:
                                        <ul>
                                            <li>Cliquez sur "Générer..." et enregistrez le fichier</li>
                                            <li>Ouvrez le fichier précédemment téléchargé et remplissez-le</li>
                                            <li>Cliquez sur "Ajouter un fichier"/"Parcourir" (en fonction du navigateur) et sélectionnez le fichier rempli</li>
                                            <li>Cliquez sur "Ajouter"</li>
                                        </ul>
                                    </div> 
                                </legend>
                                
                                <div className='admin-form-excel-container'>
                                    <span className='admin-form-excel'>Blasons / Familles : </span> 
                                    <input type="file" name="file arme" accept=".xls, .xlsx"/>
                                    <button type='button' onClick={() => importArmes(document.getElementById('admin-excel-form'))}>Ajouter</button>
                                    <Link to={process.env.REACT_APP_MINIO_EXCELS + "european_armorial.xlsx"} className='admin-form-excel-link'>Générer un ficher Excel type</Link>
                                </div>
                                {correctImportArmes ? <span id='label10'>{correctImportArmes}</span> : null}
                                <div className='admin-form-excel-container' id='admin-form-excel-bottom'>
                                    <span className='admin-form-excel'>Images : </span> 
                                    <input type="file" name="file icono" accept=".xls, .xlsx" multiple={false}/>
                                    <button type='button' onClick={() => importIconos(document.getElementById('admin-excel-form'))}>Ajouter</button>
                                    <Link to={process.env.REACT_APP_MINIO_EXCELS + "european_pictures.xlsx"} className='admin-form-excel-link'>Générer un ficher Excel type</Link>
                                </div> 
                                {correctImportIconos ? <span id='label10'>{correctImportIconos}</span> : null}
                            </fieldset>
                        </form>
    
                        <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addArmes(e)}>
                            <fieldset>
                                <legend className='admin-legend'>Ajout manuel d'un blason / famille</legend>
    
                                <div className='admin-search-results-container'>
    
                                    <div className="show show-armes">
                                    <div className='results-Armes-container-sheet'>
                                        <span className='results-armes'>Images : </span>
                                        <input type='text' className='results-data-armes' placeholder="L'ID de l'image à ajouter" onChange={(e) => {
                                            setArmesIconosID(e.target.value);
                                            }}/>
                                        <button onClick={addArmesImages} type="button">Ajouter l'image</button>
                                        <ul className='armes-list'>
                                            {
                                                armesIconos?.map((icono, index) => { 
                                                    return (
                                                        <li key={index}>{icono.id} : {icono.vedetteChandon} <img src={cross} alt="supprimer" onClick={() => {
                                                            setArmesIconos(armesIconos.filter((item) => item.id !== icono.id));
                                                        }} /></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><input type='text' className='results-data-armes' name='patronyme' required/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Prénom : </span><input type='text' className='results-data-armes' name='prenom'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Famille : </span><input type='text' className='results-data-armes' name='famille'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titres & dignités : </span><input type='text' className='results-data-armes' name='titresDignites'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Origine : </span><input type='text' className='results-data-armes' name='origine'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' name='date'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Siècle : </span><input type='text' className='results-data-armes' name='siecle'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Fief : </span><input type='text' className='results-data-armes' name='fief'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' className='results-data-armes' name='pays'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Province : </span><input type='text' className='results-data-armes' name='province'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' className='results-data-armes' name='region'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' className='results-data-armes' name='department'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Ville : </span><input type='text' className='results-data-armes' name='ville'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Alliances : </span><input type='text' className='results-data-armes' name='alliances'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armoiries : </span><input type='text' className='results-data-armes' name='armoiries'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes : </span><input type='text' className='results-data-armes' name='notes'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Observations : </span><input type='text' className='results-data-armes' name='observations'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Sources : </span><input type='text' className='results-data-armes' name='sources'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Le Clert : </span><input type='text' className='results-data-armes' name='leClert'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armorial Gen Champagne : </span><input type='text' className='results-data-armes' name='armorialGenChampagne'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes armoriaux : </span><input type='text' className='results-data-armes' name='notesArmoriaux'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Généalogie : </span><input type='text' className='results-data-armes' name='genealogie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Document : </span><input type='text' className='results-data-armes' name='document'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Timbre : </span><input type='text' className='results-data-armes' name='timbre'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimier : </span><input type='text' className='results-data-armes' name='cimier'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Devise / Cri : </span><input type='text' className='results-data-armes' name='deviseCri'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Tenants & Support : </span><input type='text' className='results-data-armes' name='tenantsSupport'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Décoration : </span><input type='text' className='results-data-armes' name='decoration'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Ornements Extérieurs : </span><input type='text' className='results-data-armes' name='ornementsExt'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emblèmes : </span><input type='text' className='results-data-armes' name='emblemes'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Images Généalogiques : </span><input type='text' className='results-data-armes' name='imagesGeneal'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Images Documentaires : </span><input type='text' className='results-data-armes' name='imagesDoc'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea className='results-data-armes' name='blasonnement_1'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea className='results-data-armes' name='blasonnement_2'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Sceau : </span><input type='checkbox' className='results-data-armes' name='sceau'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Privée : </span><input type='checkbox' className='results-data-armes' name='private'/></div>
                                    </div>
                                    <button type='submit' className='admin-modify-button'>Ajouter</button>
                                    {correctArmes ? <span id='label10'>{correctArmes}</span> : null}
                                </div>
    
                            </fieldset>
                        </form>

                        <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addIconos(e)}>
                            <fieldset>
                                <legend className='admin-legend'>Ajout manuel d'une image</legend>
    
                                <div className='admin-search-results-container'>
    
                                    <div className="show show-armes">
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Image : </span><input type='file' className='results-data-armes' name='file' accept='image/*' size={10 * 1024 * 1024} multiple={false} required/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Vedette Chandon : </span><input type='text' className='results-data-armes' name='vedetteChandon'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteur du cliché : </span><input type='text' className='results-data-armes' name='auteurCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Type de cliché : </span><input type='text' className='results-data-armes' name='typeCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année du cliché : </span><input type='text' className='results-data-armes' name='anneeCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' className='results-data-armes' name='pays'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' className='results-data-armes' name='region'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' className='results-data-armes' name='departement'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Commune : </span><input type='text' className='results-data-armes' name='commune'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Village : </span><input type='text' className='results-data-armes' name='village'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Edifice de conservation : </span><input type='text' className='results-data-armes' name='edificeConservation'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emplacement dans l'édifice : </span><input type='text' className='results-data-armes' name='emplacementDansEdifice'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Photo : </span><input type='text' className='results-data-armes' name='photo'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Numéro INSEE de la commune : </span><input type='text' className='results-data-armes' name='communeInseeNumber'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IM : </span><input type='text' className='results-data-armes' name='refIm'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence PA : </span><input type='text' className='results-data-armes' name='refPa'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IA : </span><input type='text' className='results-data-armes' name='refIa'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Numéro artificiel Decrock : </span><input type='text' className='results-data-armes' name='artificialNumberDecrock'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Famille : </span><input type='text' className='results-data-armes' name='famille'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Individu : </span><input type='text' className='results-data-armes' name='individu'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Qualité : </span><input type='text' className='results-data-armes' name='qualite'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' name='date'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Dénomination : </span><input type='text' className='results-data-armes' name='denomination'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titre : </span><input type='text' className='results-data-armes' name='titre'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Catégorie : </span><input type='text' className='results-data-armes' name='categorie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Matériau : </span><input type='text' className='results-data-armes' name='materiau'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence des reproductions : </span><input type='text' className='results-data-armes' name='refReproductions'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Bibliographie : </span><input type='text' className='results-data-armes' name='bibliographie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Remarques : </span><input type='text' className='results-data-armes' name='remarques'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteurs : </span><input type='text' className='results-data-armes' name='auteurs'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Lieu d'édition : </span><input type='text' className='results-data-armes' name='lieuEdition'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Editeur : </span><input type='text' className='results-data-armes' name='editeur'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année d'édition : </span><input type='text' className='results-data-armes' name='anneEdition'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Reliure : </span><input type='text' className='results-data-armes' name='reliure'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Provenance : </span><input type='text' className='results-data-armes' name='provenance'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Site : </span><input type='text' className='results-data-armes' name='site'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Section : </span><input type='text' className='results-data-armes' name='section'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cote : </span><input type='text' className='results-data-armes' name='cote'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Folio d'emplacement : </span><input type='text' className='results-data-armes' name='folioEmplacement'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>F41 : </span><input type='text' className='results-data-armes' name='f41'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' className='results-data-armes' name='cimiers'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea className='results-data-armes' name='blasonnement_1'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea className='results-data-armes' name='blasonnement_2'/></div>
                                    </div>
                                    <button type='submit' className='admin-modify-button'>Ajouter</button>
                                    {correctIconos ? <span id='label10'>{correctIconos}</span> : null}
                                </div>
    
                            </fieldset>
                        </form>
                    
                </div>
                )}
                {active === 'gestion-blason' && (
                <div>
    
                        <form className='admin-form admin-form-space admin-form-table'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Modification des contenus 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                        <ul>
                                            <li> Le tableau affiche les contenus de la base, organisés par ordre alphabétique.<br/>
                                            Pour atteindre la lettre souhaitée, cliquez sur l'une des lettres au-dessus du tableau.<br/>
                                            </li>
                                            <li>
                                            <img src={cross} alt="supprimer"/> (dans la colonne de gauche) permet de supprimer le contenu correpondant.
                                            </li>
                                            <li>
                                                Afin de modifier l'entrée il suffit de cliquer sur le patronyme / nom correspondant.<br/>
                                                Cela ouvrira la recherche par ID avec l'ID correspondant à l'entrée, vous pourrez alors modifier les informations.
                                            </li>
                                        </ul>
                                    </div>                               
                                </legend>
    
                                <div>
    
                                    {alphabet.map((letter, index) => {
                                        return (
                                            <>
                                            <span key={"A"+index} className='admin-search-letter' onClick={() => searchArmes(letter)}>{letter}</span>
                                            <span key={"B"+index} className='admin-search-letter-dash'>{alphabet.length !== index+1 ? " - " : null }</span>
                                            </>
                                        )
                                    })}
                                    
                                </div>
    
                                <div className="table-wrapper">                            
                                    <table>
                                        <thead>
                                            <tr>
                                                <th id="admin-form-delete-col">Supprimer</th>
                                                <th>Patronyme / Nom</th>
                                                <th>Prénom</th>
                                                <th>Famille</th>
                                                <th>Titres & dignités</th>
                                                <th>Origine</th>
                                                <th>Date</th>
                                                <th>Siècle</th>
                                                <th>Fief</th>
                                                <th>Pays</th>
                                                <th>Province</th>
                                                <th>Région</th>
                                                <th>Département</th>
                                                <th>Ville</th>
                                                <th>Alliances</th>
                                                <th>Armoiries</th>
                                                <th>Notes</th>
                                                <th>Observations</th>
                                                <th>Sources</th>
                                                <th>Le Clert</th>
                                                <th>Armorial Gen Champagne</th>
                                                <th>Notes armoriaux</th>
                                                <th>Généalogie</th>
                                                <th>Document</th>
                                                <th>Timbre</th>
                                                <th>Cimier</th>
                                                <th>Devise / Cri</th>
                                                <th>Tenants & Support</th>
                                                <th>Décoration</th>
                                                <th>Ornements Extérieurs</th>
                                                <th>Emblèmes</th>
                                                <th>Images Généalogiques</th>
                                                <th>Images Documentaires</th>
                                                <th>Blasonnement 1</th>
                                                <th>Blasonnement 2</th>
                                                <th>Sceau</th>
                                                <th>Privée</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        ArmesLetter?.map((Armes, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td key={"C"+index}><img src={cross} alt="supprimer" onClick={() => deleteArme(Armes?.id?.toString() || "", index)}/></td>
                                                        <td key={"B"+index} className='admin-patronyme' onClick={() => {
                                                            setIDArme(Armes.id?.toString() || "");
                                                            searchIDArme(Armes.id?.toString() || "");
                                                            setActive('recherche');
                                                        }}>{Armes.patronyme}</td>
                                                        <td key={"A"+index}>{Armes.prenom}</td>
                                                        <td key={"D"+index}>{Armes.famille}</td>
                                                        <td key={"E"+index}>{Armes.titresDignites}</td>
                                                        <td key={"F"+index}>{Armes.origine}</td>
                                                        <td key={"G"+index}>{Armes.date}</td>
                                                        <td key={"H"+index}>{Armes.siecle}</td>
                                                        <td key={"I"+index}>{Armes.fief}</td>
                                                        <td key={"J"+index}>{Armes.pays}</td>
                                                        <td key={"K"+index}>{Armes.province}</td>
                                                        <td key={"L"+index}>{Armes.region}</td>
                                                        <td key={"M"+index}>{Armes.department}</td>
                                                        <td key={"N"+index}>{Armes.ville}</td>
                                                        <td key={"O"+index}>{Armes.alliances}</td>
                                                        <td key={"P"+index}>{Armes.armoiries}</td>
                                                        <td key={"Q"+index}>{Armes.notes}</td>
                                                        <td key={"R"+index}>{Armes.observations}</td>
                                                        <td key={"S"+index}>{Armes.sources}</td>
                                                        <td key={"T"+index}>{Armes.leClert}</td>
                                                        <td key={"U"+index}>{Armes.armorialGenChampagne}</td>
                                                        <td key={"V"+index}>{Armes.notesArmoriaux}</td>
                                                        <td key={"W"+index}>{Armes.genealogie}</td>
                                                        <td key={"X"+index}>{Armes.document}</td>
                                                        <td key={"Y"+index}>{Armes.timbre}</td>
                                                        <td key={"Z"+index}>{Armes.cimier}</td>
                                                        <td key={"AA"+index}>{Armes.deviseCri}</td>
                                                        <td key={"AB"+index}>{Armes.tenantsSupport}</td>
                                                        <td key={"AC"+index}>{Armes.decoration}</td>
                                                        <td key={"AD"+index}>{Armes.ornementsExt}</td>
                                                        <td key={"AE"+index}>{Armes.emblemes}</td>
                                                        <td key={"AF"+index}>{Armes.imagesGeneal}</td>
                                                        <td key={"AG"+index}>{Armes.imagesDoc}</td>
                                                        <td key={"AH"+index}>{Armes.blasonnement_1}</td>
                                                        <td key={"AI"+index}>{Armes.blasonnement_2}</td>
                                                        <td key={"AJ"+index}>{Armes.sceau ? "Oui" : "Non"}</td>
                                                        <td key={"AK"+index}>{Armes.private ? "Privé" : "Publique"}</td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </form>                   
                    
                </div>
                )}
                {active === 'gestion-image' && (
                    <div>
    
                        <form className='admin-form admin-form-space admin-form-table'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Modification des contenus 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                        <ul>
                                            <li> Le tableau affiche les contenus de la base, organisés par ordre alphabétique.<br/>
                                            Pour atteindre la lettre souhaitée, cliquez sur l'une des lettres au-dessus du tableau.<br/>
                                            </li>
                                            <li>
                                            <img src={cross} alt="supprimer"/> (dans la colonne de gauche) permet de supprimer le contenu correpondant.
                                            </li>
                                            <li>
                                                Afin de modifier l'entrée il suffit de cliquer sur le patronyme / nom correspondant.<br/>
                                                Cela ouvrira la recherche par ID avec l'ID correspondant à l'entrée, vous pourrez alors modifier les informations.
                                            </li>
                                        </ul>
                                    </div>                               
                                </legend>

                                <div>

                                    {alphabet.map((letter, index) => {
                                        return (
                                            <>
                                            <span key={"A"+index} className='admin-search-letter' onClick={() => searchIconos(letter)}>{letter}</span>
                                            <span key={"B"+index} className='admin-search-letter-dash'>{alphabet.length !== index+1 ? " - " : null }</span>
                                            </>
                                        )
                                    })}
                                    
                                </div>

                                <div className="table-wrapper">                            
                                    <table>
                                        <thead>
                                            <tr>
                                                <th id="admin-form-delete-col">Supprimer</th>
                                                <th>Vedette Chandon</th>
                                                <th>Auteur du cliché</th>
                                                <th>Type de cliché</th>
                                                <th>Année du cliché</th>
                                                <th>Pays</th>
                                                <th>Région</th>
                                                <th>Département</th>
                                                <th>Commune</th>
                                                <th>Village</th>
                                                <th>Edifice de conservation</th>
                                                <th>Emplacement dans l'édifice</th>
                                                <th>Photo</th>
                                                <th>Numéro INSEE de la commune</th>
                                                <th>Référence IM</th>
                                                <th>Référence PA</th>
                                                <th>Référence IA</th>
                                                <th>Numéro artificiel Decrock</th>
                                                <th>Famille</th>
                                                <th>Individu</th>
                                                <th>Qualité</th>
                                                <th>Date</th>
                                                <th>Dénomination</th>
                                                <th>Titre</th>
                                                <th>Catégorie</th>
                                                <th>Matériau</th>
                                                <th>Référence des reproductions</th>
                                                <th>Bibliographie</th>
                                                <th>Remarques</th>
                                                <th>Auteurs</th>
                                                <th>Lieu d'édition</th>
                                                <th>Editeur</th>
                                                <th>Année d'édition</th>
                                                <th>Reliure</th>
                                                <th>Provenance</th>
                                                <th>Site</th>
                                                <th>Section</th>
                                                <th>Cote</th>
                                                <th>Folio d'emplacement</th>
                                                <th>Cimiers</th>
                                                <th>Blasonnement 1</th>
                                                <th>Blasonnement 2</th>
                                                <th>Référence Minio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        IconosLetter?.map((Icono, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td key={"C"+index}><img src={cross} alt="supprimer" onClick={() => deleteIconos(Icono?.id?.toString() || "", index)}/></td>
                                                        <td key={"B"+index} className='admin-patronyme' onClick={() => {
                                                            setIDIcono(Icono.id?.toString() || "");
                                                            searchIDIcono(Icono.id?.toString() || "");
                                                            setActive('recherche');
                                                        }}>{Icono.vedetteChandon}</td>
                                                        <td key={"D"+index}>{Icono.auteurCliche}</td>
                                                        <td key={"E"+index}>{Icono.typeCliche}</td>
                                                        <td key={"F"+index}>{Icono.anneeCliche}</td>
                                                        <td key={"G"+index}>{Icono.pays}</td>
                                                        <td key={"H"+index}>{Icono.region}</td>
                                                        <td key={"I"+index}>{Icono.departement}</td>
                                                        <td key={"J"+index}>{Icono.commune}</td>
                                                        <td key={"K"+index}>{Icono.village}</td>
                                                        <td key={"L"+index}>{Icono.edificeConservation}</td>
                                                        <td key={"M"+index}>{Icono.emplacementDansEdifice}</td>
                                                        <td key={"N"+index}>{Icono.photo}</td>
                                                        <td key={"O"+index}>{Icono.communeInseeNumber}</td>
                                                        <td key={"P"+index}>{Icono.refIm}</td>
                                                        <td key={"Q"+index}>{Icono.refPa}</td>
                                                        <td key={"R"+index}>{Icono.refIa}</td>
                                                        <td key={"S"+index}>{Icono.artificialNumberDecrock}</td>
                                                        <td key={"T"+index}>{Icono.famille}</td>
                                                        <td key={"U"+index}>{Icono.individu}</td>
                                                        <td key={"V"+index}>{Icono.qualite}</td>
                                                        <td key={"W"+index}>{Icono.date}</td>
                                                        <td key={"X"+index}>{Icono.denomination}</td>
                                                        <td key={"Y"+index}>{Icono.titre}</td>
                                                        <td key={"Z"+index}>{Icono.categorie}</td>
                                                        <td key={"AA"+index}>{Icono.materiau}</td>
                                                        <td key={"AB"+index}>{Icono.refReproductions}</td>
                                                        <td key={"AC"+index}>{Icono.bibliographie}</td>
                                                        <td key={"AD"+index}>{Icono.remarques}</td>
                                                        <td key={"AF"+index}>{Icono.auteurs}</td>
                                                        <td key={"AG"+index}>{Icono.lieuEdition}</td>
                                                        <td key={"AH"+index}>{Icono.editeur}</td>
                                                        <td key={"AI"+index}>{Icono.anneEdition}</td>
                                                        <td key={"AJ"+index}>{Icono.reliure}</td>
                                                        <td key={"AK"+index}>{Icono.provenance}</td>
                                                        <td key={"AL"+index}>{Icono.site}</td>
                                                        <td key={"AM"+index}>{Icono.section}</td>
                                                        <td key={"AN"+index}>{Icono.cote}</td>
                                                        <td key={"AO"+index}>{Icono.folioEmplacement}</td>
                                                        <td key={"AQ"+index}>{Icono.cimiers}</td>
                                                        <td key={"AR"+index}>{Icono.blasonnement_1}</td>
                                                        <td key={"AS"+index}>{Icono.blasonnement_2}</td>
                                                        <td key={"AT"+index}>{Icono.refMinio}</td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </form>                   
                
                    </div>
                )}
                {active === 'recherche' && (
                <div>
                    <form className='admin-form' onSubmit={(e) => modifyArme(e)}>
                        <fieldset>
                            <legend className='admin-legend'>Recherche par ID d'un blason / famille</legend>
    
                            <div>
                                <label className='admin-label'>Saisir un ID : </label>
                                <input type="number" name="id" min={1} onChange={(e) => setIDArme(e.target.value)}/>
                                <br/><br/>
                            </div>
    
                            <button type='button' onClick={() => searchIDArme()}>Rechercher</button>
                            {correctIDArme ? correctIDArme  : null}
                            {loadingArme ? <img src={LoadingImage} alt="Chargement..."/>: null}
    
                            {IDArmeresponse.id !== null ? 
    
                                <div className='admin-search-results-container'> 
    
                                    <h3 className='admin-search-results-title'>{IDArmeresponse.patronyme}</h3>
    
                                    <div className="show show-armes">
                                        <div className='results-Armes-container-sheet'>
                                            <span className='results-armes'>Images : </span>
                                            <input type='text' className='results-data-armes' placeholder="L'ID de l'image à ajouter" onChange={(e) => {
                                                setArmesIconosIDMod(e.target.value);
                                                }}/>
                                            <button onClick={modifyArmesImages} type="button">Ajouter l'image</button>
                                            <ul className='armes-list'>
                                                {
                                                    armesIconosMod?.map((icono, index) => { 
                                                        return (
                                                            <li key={index}>{icono.id} : {icono.vedetteChandon} <img src={cross} alt="supprimer" onClick={() => {
                                                                setArmesIconos(armesIconos.filter((item) => item.id !== icono.id));
                                                            }} /></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.patronyme || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, patronyme: e.target.value})} required/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Prénom : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.prenom || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, prenom: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Famille : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.famille || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, famille: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titres & dignités : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.titresDignites || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, titresDignites: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Origine : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.origine || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, origine: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.date || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, date: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Siècle : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.siecle || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, siecle: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Fief : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.fief || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, fief: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.pays || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, pays: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Province : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.province || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, province: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.region || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, region: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.department || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, department: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Ville : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.ville || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, ville: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Alliances : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.alliances || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, alliances: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armoiries : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.armoiries || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, armoiries: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.notes || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, notes: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Observations : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.observations || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, observations: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Sources : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.sources || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, sources: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Le Clert : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.leClert || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, leClert: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armorial Gen Champagne : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.armorialGenChampagne || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, armorialGenChampagne: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes armoriaux : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.notesArmoriaux || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, notesArmoriaux: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Généalogie : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.genealogie || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, genealogie: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Document : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.document || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, document: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Timbre : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.timbre || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, timbre: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimier : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.cimier || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, cimier: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Devise / Cri : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.deviseCri || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, deviseCri: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Tenants & Support : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.tenantsSupport || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, tenantsSupport: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Décoration : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.decoration || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, decoration: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Ornements Extérieurs : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.ornementsExt || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, ornementsExt: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emblèmes : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.emblemes || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, emblemes: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Images Généalogiques : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.imagesGeneal || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, imagesGeneal: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Images Documentaires : </span><input type='text' className='results-data-armes' defaultValue={IDArmeresponse.imagesDoc || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, imagesDoc: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea className='results-data-armes' defaultValue={IDArmeresponse.blasonnement_1 || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, blasonnement_1: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea className='results-data-armes' defaultValue={IDArmeresponse.blasonnement_2 || ""} onChange={(e) => setIDArmeresponse({...IDArmeresponse, blasonnement_2: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Sceau : </span><input type='checkbox' className='results-data-armes' defaultChecked={IDArmeresponse.sceau || false} onChange={(e) => setIDArmeresponse({...IDArmeresponse, sceau: e.target.checked})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Privée : </span><input type='checkbox' className='results-data-armes' defaultChecked={IDArmeresponse.private || false} onChange={(e) => setIDArmeresponse({...IDArmeresponse, private: e.target.checked})}/></div>
                                    </div>
                                    {armesModCorrect ? armesModCorrect  : null}
                                    <button type='submit' className='admin-modify-button' >Modifier</button>
                                </div>
                            : null}
    
                        </fieldset>
                    </form>

                    <form className='admin-form' onSubmit={(e) => modifyIcono(e)}>
                        <fieldset>
                            <legend className='admin-legend'>Recherche par ID d'une image</legend>
    
                            <div>
                                <label className='admin-label'>Saisir un ID : </label>
                                <input type="number" name="id" min={1} onChange={(e) => setIDIcono(e.target.value)}/>    
                                <br/><br/>
                            </div>
    
                            <button type='button' onClick={() => searchIDIcono()}>Rechercher</button>
                            {correctIDIcono ? correctIDIcono  : null}
                            {loadingIcono ? <img src={LoadingImage} alt="Chargement..."/>: null}
    
                            {IDIconoresponse.id !== null ? 
    
                                <div className='admin-search-results-container'> 
    
                                    <h3 className='admin-search-results-title'>{IDIconoresponse.vedetteChandon}</h3>

                                    <div className="admin-img-container">
                                        
                                        {!newIconoImageSet && IDIconoresponse?.refMinio ? (
                                            <>
                                                <div className="admin-img" onClick={() => setNewIconoImageSet(true)}>
                                                    <img src={cross} alt="supprimer"/>
                                                </div>
                                                <img className='atachement-img' src={minioUrl + IDIconoresponse.refMinio} alt={IDIconoresponse.refMinio || ""}/>
                                            </>
                                        ) : (
                                            <>
                                                {newIconoImage && <img className='admin-icono-new-image atachement-img' src={URL.createObjectURL(newIconoImage)} alt={newIconoImage?.name || ""}/>}
                                                <input type='file' className='results-data-armes' name='file' accept='image/*' size={10 * 1024 * 1024} multiple={false} required onChange={(e) => {
                                                    setNewIconoImage(e.target.files?.[0] ||null);
                                                }}/>
                                                {newIconoImage && <button onClick={() => {
                                                    setNewIconoImageSet(false)
                                                    setNewIconoImage(null)
                                                }}>Annuler</button>}
                                            </>
                                        )}
                                        
                                        
                                    
                                    </div>
    
                                    <div className="show show-armes">
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Vedette Chandon : </span><input type='text' className='results-data-armes' name='vedetteChandon' defaultValue={IDIconoresponse.vedetteChandon || ""} required/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteur du cliché : </span><input type='text' className='results-data-armes' name='auteurCliche' defaultValue={IDIconoresponse.auteurCliche || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Type de cliché : </span><input type='text' className='results-data-armes' name='typeCliche' defaultValue={IDIconoresponse.typeCliche || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année du cliché : </span><input type='text' className='results-data-armes' name='anneeCliche' defaultValue={IDIconoresponse.anneeCliche || 0}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' className='results-data-armes' name='pays' defaultValue={IDIconoresponse.pays || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' className='results-data-armes' name='region' defaultValue={IDIconoresponse.region || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' className='results-data-armes' name='departement' defaultValue={IDIconoresponse.departement || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Commune : </span><input type='text' className='results-data-armes' name='commune' defaultValue={IDIconoresponse.commune || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Village : </span><input type='text' className='results-data-armes' name='village' defaultValue={IDIconoresponse.village || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Edifice de conservation : </span><input type='text' className='results-data-armes' name='edificeConservation' defaultValue={IDIconoresponse.edificeConservation || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emplacement dans l'édifice : </span><input type='text' className='results-data-armes' name='emplacementDansEdifice' defaultValue={IDIconoresponse.emplacementDansEdifice || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Photo : </span><input type='text' className='results-data-armes' name='photo' defaultValue={IDIconoresponse.photo || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Numéro INSEE de la commune : </span><input type='text' className='results-data-armes' name='communeInseeNumber' defaultValue={IDIconoresponse.communeInseeNumber || 0}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IM : </span><input type='text' className='results-data-armes' name='refIm' defaultValue={IDIconoresponse.refIm || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence PA : </span><input type='text' className='results-data-armes' name='refPa' defaultValue={IDIconoresponse.refPa || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IA : </span><input type='text' className='results-data-armes' name='refIa' defaultValue={IDIconoresponse.refIa || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Numéro artificiel Decrock : </span><input type='text' className='results-data-armes' name='artificialNumberDecrock' defaultValue={IDIconoresponse.artificialNumberDecrock || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Famille : </span><input type='text' className='results-data-armes' name='famille' defaultValue={IDIconoresponse.famille || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Individu : </span><input type='text' className='results-data-armes' name='individu' defaultValue={IDIconoresponse.individu || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Qualité : </span><input type='text' className='results-data-armes' name='qualite' defaultValue={IDIconoresponse.qualite || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' name='date' defaultValue={IDIconoresponse.date || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Dénomination : </span><input type='text' className='results-data-armes' name='denomination' defaultValue={IDIconoresponse.denomination || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titre : </span><input type='text' className='results-data-armes' name='titre' defaultValue={IDIconoresponse.titre || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Catégorie : </span><input type='text' className='results-data-armes' name='categorie' defaultValue={IDIconoresponse.categorie || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Matériau : </span><input type='text' className='results-data-armes' name='materiau' defaultValue={IDIconoresponse.materiau || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence des reproductions : </span><input type='text' className='results-data-armes' name='refReproductions' defaultValue={IDIconoresponse.refReproductions || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Bibliographie : </span><input type='text' className='results-data-armes' name='bibliographie' defaultValue={IDIconoresponse.bibliographie || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Remarques : </span><input type='text' className='results-data-armes' name='remarques' defaultValue={IDIconoresponse.remarques || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteurs : </span><input type='text' className='results-data-armes' name='auteurs' defaultValue={IDIconoresponse.auteurs || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Lieu d'édition : </span><input type='text' className='results-data-armes' name='lieuEdition' defaultValue={IDIconoresponse.lieuEdition || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Editeur : </span><input type='text' className='results-data-armes' name='editeur' defaultValue={IDIconoresponse.editeur || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année d'édition : </span><input type='text' className='results-data-armes' name='anneEdition' defaultValue={IDIconoresponse.anneEdition || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Reliure : </span><input type='text' className='results-data-armes' name='reliure' defaultValue={IDIconoresponse.reliure || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Provenance : </span><input type='text' className='results-data-armes' name='provenance' defaultValue={IDIconoresponse.provenance || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Site : </span><input type='text' className='results-data-armes' name='site' defaultValue={IDIconoresponse.site || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Section : </span><input type='text' className='results-data-armes' name='section' defaultValue={IDIconoresponse.section || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cote : </span><input type='text' className='results-data-armes' name='cote' defaultValue={IDIconoresponse.cote || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Folio d'emplacement : </span><input type='text' className='results-data-armes' name='folioEmplacement' defaultValue={IDIconoresponse.folioEmplacement || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' className='results-data-armes' name='cimiers' defaultValue={IDIconoresponse.cimiers || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea className='results-data-armes' name='blasonnement_1' defaultValue={IDIconoresponse.blasonnement_1 || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea className='results-data-armes' name='blasonnement_2' defaultValue={IDIconoresponse.blasonnement_2 || ""}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Sceau : </span><input type='checkbox' className='results-data-armes' name='sceau' defaultChecked={IDIconoresponse.sceau || false}/></div>
                                    </div>
                                    {iconoModCorrect ? iconoModCorrect  : null}
                                    <button type='submit' className='admin-modify-button'>Modifier</button>
                                </div>
                            : null}
    
                        </fieldset>
                    </form>
                </div>
                )}
            </div>
        </div>
      )
}