import { SyntheticEvent, useEffect, useState } from 'react'
import echange from '../assets/images/echange.jpg'
import cross from '../assets/images/supprimer.png'
import left_arrow from '../assets/images/left-arrow.svg'
import right_arrow from '../assets/images/right-arrow.svg'
import '../assets/styles/Iconographie.css'
import Select from '../assets/Select.json'
import ArmorialService from '../services/Armorial.service'
import { Link } from 'react-router-dom'
import IconoFormInterface from '../models/Armorial/Iconographie/IconoForm.model'
import IconoResponseInterface from '../models/Armorial/Iconographie/IconoResponse.model'
import LoadingImage from '../assets/images/loading.gif'

export default function Iconographie({results, form}: {results: IconoResponseInterface | null, form: IconoFormInterface | null}) {

  const minioUrl = process.env.REACT_APP_MINIO_ARMORIAL || ""

  const [wordOrder, setWordOrder] = useState(true)
  const [iconos, setIcono] = useState<IconoResponseInterface | null>(null)
  const [modal, setModal] = useState(false)
  const [sceau, setSceau] = useState(false)
  const [modalContent, setModalContent] = useState([''])
  const [modalIndex, setModalIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState<IconoFormInterface | null>(form)

  useEffect(() => {
          if(results) {
              setIcono(results)
          }
      }, [setIcono, results])  

  const ActionModal = (index: number, modalState: boolean, list: string[]) => {
    setModalContent(list)
    setModalIndex(index)
    setModal(modalState)
  }
  const SubmitFormIcono = (e: SyntheticEvent<HTMLFormElement>) => {
    setLoading(true)

    e.preventDefault()
    let formData = new FormData(e.currentTarget)

    if (
      formData.get('armes') === null &&
      formData.get('patronyme') === null &&
      formData.get('village') === null &&
      formData.get('edifice') === null &&
      formData.get('sceau') === null &&
      formData.get('pays') === null &&
      formData.get('region') === null &&
      formData.get('commune') === null &&
      formData.get('date') === null &&
      formData.get('denomination') === null &&
      formData.get('categorie') === null &&
      formData.get('materiau') === null
    ) {
      return;
    }

    let tempPayload: IconoFormInterface = {
      armes: (formData.get('armes') as string).replace('\'', '\\\'') || null,
      patronyme: (formData.get('patronyme') as string).replace('\'', '\\\'') || null,
      village: (formData.get('village') as string).replace('\'', '\\\'') || null,
      edifice: (formData.get('edifice') as string).replace('\'', '\\\'') || null,
      sceau: sceau,
      pays: (formData.get('pays') as string).replace('\'', '\\\'') || null,
      region: (formData.get('region') as string).replace('\'', '\\\'') || null,
      commune: (formData.get('commune') as string).replace('\'', '\\\'') || null,
      date: (formData.get('date') as string).replace('\'', '\\\'') || null,
      denomination: (formData.get('denomination') as string).replace('\'', '\\\'') || null,
      categorie: (formData.get('categorie') as string).replace('\'', '\\\'') || null,
      materiau: (formData.get('materiau') as string).replace('\'', '\\\'') || null,
      ordonnee: wordOrder
    }    

    ArmorialService.getIcono(tempPayload).then((response: IconoResponseInterface) => {
        setIcono(response)
        setFormState(tempPayload)
        setLoading(false)
    })
  }

  const resetForm = () => {
      setFormState(null)
      setIcono(null)
  }

  return (
    <div id='armesfamilles-container' className='public-container'>

        <h3>Recherche d'Iconographies</h3>

        <form id='armes-form' onSubmit={SubmitFormIcono}>
            <fieldset>

              <div id="armes-bottom-grid">
          <div id='armes-bottom-grid-left'>
            <div>
                <label htmlFor="pays" id='label1'>Pays</label>							

                <select name="pays" defaultValue={formState?.pays || ""}>
              <option value=""></option>
              {Select.Pays.map((pays, index) => <option key={index} value={pays}>{pays}</option>)}
                </select>
            </div>

            <div>
                <label htmlFor="region" id='label1'>Région</label>

                <select name="region" defaultValue={formState?.region || ""}>                                            
              <option value=""> </option>
              {Select.Region.map((region, index) => <option key={index} value={region}>{region}</option>)}						
                </select>
            </div>

            <div>
                <label htmlFor="commune" id='label1'>Commune</label>

                <select name="commune" defaultValue={formState?.commune || ""}>
            <option value=""></option>
            {Select.Commune.map((commune, index) => <option key={index} value={commune}>{commune}</option>)}									
                </select>
            </div>

            <div>
                <label htmlFor="village" id='label1'>Village</label>
                <input type="text" name="village" defaultValue={formState?.village || ""} />
            </div>

            <div>
                <label htmlFor="edifice" id='label1'>Edifice <span>(de Conservation)</span></label>
                <input type="text" name="edifice" defaultValue={formState?.edifice || ""} />
            </div>

            <div>
                <label htmlFor="patronyme" id='label1'>Patronyme / Nom </label>
                <input type="text" name="patronyme" defaultValue={formState?.patronyme || ""} />
            </div>

            <div>
              <label htmlFor="date" id='label1'>Date</label>
              <select name="date" defaultValue={formState?.date || ""}>                                            
            <option value=""></option>
            {Select.Date.map((date, index) => (<option key={index} value={date}>{date}</option>))}						
              </select>
            </div>

          </div>
          <div id='armes-bottom-grid-right'>
            <div>
                <label htmlFor="denomination" id='label1'>Dénomination</label>
                <select name="denomination" defaultValue={formState?.denomination || ""}>                                            
              <option value=""></option>
              {Select.Denomination.map((denomination, index) => (<option key={index} value={denomination}>{denomination}</option>))}						
                </select>
              </div>

              <div>
                <label htmlFor="categorie" id='label1'>Catégorie</label>
                <select name="categorie" defaultValue={formState?.categorie || ""}>                                            
              <option value=""></option>
              {Select.Categorie.map((categorie, index) => (<option key={index} value={categorie}>{categorie}</option>))}						
                </select>
              </div> 

              <div>
                <label htmlFor="materiau" id='label1'>Matériau</label>
                <select name="materiau" defaultValue={formState?.materiau || ""}>                                            
              <option value=""></option>
              {Select.Materiau.map((materiau, index) => (<option key={index} value={materiau}>{materiau}</option>))}						
                </select>
              </div> 

            <div id='icono-armes-input'>
            <label htmlFor="fief ou commune" id='label1'>Armes</label>
            <input type="text" name="armes" defaultValue={formState?.armes || ""} />
            <img className='word-order-icon' src={echange} alt="echange" onClick={(e) => setWordOrder(!wordOrder)}/>
            <p className='word-order-text'>Mots {wordOrder ? "ordonnés" : "désordonnés"}</p>
            </div>

            <div>
              <label htmlFor="sceau" id='label1'>Sceau</label>
              <input type="checkbox" name="sceau" checked={sceau} onChange={() => setSceau(!sceau)}/>
            </div>

            <button type='submit'>Rechercher</button>
            <button type='reset' onClick={resetForm} className='armes-reset-button'>Réinitialiser la recherche</button>
          </div>
              </div>
            
            </fieldset>
        </form>

        {iconos === null ? null : <p className='answer-results'>Nombre de résultats : {iconos.totalCount}</p>}
        {loading ? <img src={LoadingImage} alt="Chargement..." className='loading-img'/>: null}

        {iconos === null ? 
          null :        
              <div className='answer-container'>
                  { !modal ? null :                 
                  <div className='img-modal-back'>
                      <div className='img-modal'>
                          <img className='img-modal-img' src={minioUrl + modalContent[modalIndex]} alt={modalContent[modalIndex]}/>
                          <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(0, false, [])}/>
                          {modalIndex === 0 ? null : <img src={left_arrow} alt="left-arrow" className="left-arrow" onClick={() => ActionModal(modalIndex-1, true, modalContent)}/>}
                          {modalIndex === modalContent.length-1 ? null : <img src={right_arrow} alt="right-arrow" className="right-arrow" onClick={() => ActionModal(modalIndex+1, true, modalContent)}/>}                       
                      </div>
                  </div>
                  }

                  {iconos.data.map((icono, index) => {
                      return (
                          <div className='results-container results-container-icono' key={index}>
                              <ul>
                                  <li className='patronyme patronyme-icono'><Link to={"./" + icono.id} state={{form: formState, results: iconos}}><span>{icono.famille}</span></Link>, {icono.commune}</li>
                                  <span className='results-iconos'>Détails : </span>
                                  <span className="results-iconos-details">{icono.denomination}</span>
                                  <span className="results-iconos-details">, {icono.date}</span>
                              </ul>
                              
                              <img key={index} className='atachement-img atachement-img-icono' src={minioUrl + icono.pictureUrl} alt={icono.pictureUrl} onClick={() => ActionModal(0, true, [icono.pictureUrl])}/>

                          </div>
                      )
                  })}

              </div>
        }
    </div>
  )
}
