import { useState, useEffect, JSX } from "react";
import EquivalenceInterface from "../models/Admin/Equivalences.model";
import AdminService from "../services/Admin.service";
import BookService from "../services/Book.service";
import CounterService from "../services/Counter.service";
import Help from "../assets/images/help.gif";
import cross from "../assets/images/supprimer.png";
import LoadingImage from "../assets/images/loading.gif";
import BookOneInterface from "../models/Book/BookOne.model";
import { Link } from "react-router-dom";

export default function BookAdmin() {

    const minioUrl = process.env.REACT_APP_MINIO_BOOK || ""

    const [connect, setConnect] = useState<any>(null);
    const [active, setActive] = useState<string>('admin');
    const [letterBook, setLetterBook] = useState<string>('A');
    const [step, setStep] = useState<number>(1);
    const [correctPass, setcorrectPass] = useState<string | null>(null);
    const [correctMail, setcorrectMail] = useState<string | null>(null);
    const [correctCount, setCorrectCount] = useState<string | null>(null);
    const [correctEquivalence, setCorrectEquivalence] = useState<string | null>(null);
    const [correctBooks, setCorrectBooks] = useState<string | null>(null);
    const [correctModifyBooks, setCorrectModifyBooks] = useState<string | null>(null);
    const [correctImportBooks, setCorrectImportBooks] = useState<string | null>(null);
    const [correctIDBook, setCorrectIDBook] = useState<JSX.Element | null>(null);
    const [idBook, setIDBook] = useState<string | null>(null);
    const [count, setCount] = useState<{"id" : 0,"count" : string, "resetDate" : string}>({id: 0, count: "", resetDate: ""});
    const [loadingBook, setLoadingBook] = useState<boolean>(false);
    const [equivalences, setEquivalences] = useState<EquivalenceInterface[]>([]);
    const [BooksLetter, setBooksLetter] = useState<BookOneInterface[]>([]);
    const [newBookImage, setNewBookImage] = useState<FileList | null>(null);
    const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);

    const [IDBookresponse, setIDBookresponse] = useState<BookOneInterface>({
        id: null,
        patronyme: null,
        auteurCliche: null,
        typeCliche: null,
        anneeCliche: null,
        pays: null,
        region: null,
        departement: null,
        commune: null,
        edificeConservation: null,
        photo: null,
        refIm: null,
        refPa: null,
        refIa: null,
        individu: null,
        biographie: null,
        parente: null,
        date: null,
        denomination: null,
        titre: null,
        categorie: null,
        materiau: null,
        refReproduction: null,
        bibliographie: null,
        embleme: null,
        auteurs: null,
        lieuEdition: null,
        editeur: null,
        anneeEdition: null,
        reliure: null,
        provenance: null,
        site: null,
        section: null,
        cote: null,
        folioEmplacement: null,
        cimiers: null,
        armes: null,
        notes: null,
        devise: null,
        refMinio: null
    })

    const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

    useEffect(() => {

        AdminService.getInfo().then((response) => {
            setConnect(response);
        }).catch((error) => {
            throw error
        })

        CounterService.getCount().then((response) => {
            setCount(response);
        }).catch((error) => {
            throw error
        })

        AdminService.getEquivalences().then((response) => {
            setEquivalences(response);
        }).catch((error) => {
            throw error
        })

        BookService.getBooksLetter('A').then((response: BookOneInterface[]) => {
            setBooksLetter(response)
        }).catch((error) => {
            throw error
        })
    }, [])

    const resetCounter = async () => {
        const response = await CounterService.modifyCount()
        if (response) {
            setCorrectCount("Compteur réinitialisé");
            count.count = "0";
            let date = new Date();
            count.resetDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
    }
    
    const modifyMail = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const payload = {
            mail: data.get('new mail') as string
        }

        if (payload.mail !== "" && payload.mail !== connect?.mail) {
            const response = await AdminService.change(payload);

            if (response === false) {
                setcorrectMail("Une erreur est survenue");
                return;
            }
            else {
                setcorrectMail("Adresse mail modifiée");
                setConnect({...connect, mail: payload.mail});
                }
        }
        (document.getElementsByName("new mail")[0] as HTMLInputElement).value = "";
    }

    const modifyPassword = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (step === 1) {
            const payload = {
                username: data.get('login') as string,
                password: data.get('actual pass') as string
            }

            const response = await AdminService.login(payload);

            if (response === false) {
                setcorrectPass("Mauvais identifiants");
                return;
            }
            else {
                setStep(2);
            }
        }
        else if (step === 2) {
            const payload = {
                password: data.get('new pass') as string
            }

            if (payload.password !== "" && payload.password !== data.get('actual pass') && payload.password === data.get('confirm pass')) {
                const response = await AdminService.change(payload);
                if (response === false) {
                    setcorrectPass("Une erreur est survenue");
                    return;
                }
                else {
                    setcorrectPass("Mot de passe modifié");
                    setStep(1);
                }
            }
        }
    }


    const searchIDBook = async (givedId?: string) => {
        setLoadingBook(true);
        setImagesToRemove([]);
        setNewBookImage(null);

        if ((idBook !== null && idBook !== "") || (givedId !== null && givedId !== "")) {
            setIDBookresponse({
                id: null,
                patronyme: null,
                auteurCliche: null,
                typeCliche: null,
                anneeCliche: null,
                pays: null,
                region: null,
                departement: null,
                commune: null,
                edificeConservation: null,
                photo: null,
                refIm: null,
                refPa: null,
                refIa: null,
                individu: null,
                biographie: null,
                parente: null,
                date: null,
                denomination: null,
                titre: null,
                categorie: null,
                materiau: null,
                refReproduction: null,
                bibliographie: null,
                embleme: null,
                auteurs: null,
                lieuEdition: null,
                editeur: null,
                anneeEdition: null,
                reliure: null,
                provenance: null,
                site: null,
                section: null,
                cote: null,
                folioEmplacement: null,
                cimiers: null,
                armes: null,
                notes: null,
                devise: null,
                refMinio: null,
              });

              let response: BookOneInterface;

              if (givedId) {
                response = await BookService.getBookOne(givedId as string);
              }
              else {
                response = await BookService.getBookOne(idBook as string);
              }            

            if (!response) {
                setCorrectIDBook(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{idBook}</span>.</span>);
            }
            else {
                setIDBookresponse(response);
                setCorrectIDBook(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{idBook}</span> :</span>)
            }
        }
        setLoadingBook(false);
    }

    const addEquivalence = async (e: any) => {

        e.preventDefault();
        const newEquivalence = new FormData(e.currentTarget);

        const response = await AdminService.addEquivalence(newEquivalence);

        if (response === false) {
            setCorrectEquivalence("Une erreur est survenue");
        }
        else {
            setEquivalences([...equivalences, response]);
            setCorrectEquivalence("Equivalence ajoutée");
        }            
        (document.getElementsByName("expression")[0] as HTMLInputElement).value = "";
        (document.getElementsByName("signification")[0] as HTMLInputElement).value = "";
    }

    const deleteEquivalence = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer cette équivalence ?\n\n- Expression 1 : ${equivalences[index].expression}\n\n- Expression 2 : ${equivalences[index].signification}`)) {
            const response = await AdminService.deleteEquivalence(id);

            if (response === false) {
                alert("Une erreur est survenue");
            }
            else {
                setEquivalences(equivalences.filter((equivalence) => equivalence.id !== id));
                alert("Equivalence supprimée");
            }
        }
        else {
            alert("Suppression annulée");
        }        
    }


    const searchBooks = async (letter: string) => {
        const response = await BookService.getBooksLetter(letter);
        if (response) {
            setLetterBook(letter);
            setBooksLetter(response);
        }        
    }



    const addBooks = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);

        if (formData.get('patronyme') === ""  || formData.get('patronyme') === null) {
            setCorrectBooks("Le patronyme est obligatoire");
            return;
        }

        const response = await BookService.addBook(formData);
        if (response) {
            if (formData?.get('nom image')?.toString().startsWith(letterBook)) {
                setBooksLetter([...BooksLetter, response]);
            }
            e.target.reset();
            setCorrectBooks("Image ajoutée");
        }
        else {
            setCorrectBooks("Une erreur est survenue");
        }
    }


    const importBooks = async (form: any) => {
        const formData = new FormData(form);
        const data = new FormData();
        data.append('file', formData.get('file book') as File);

        if (data) {
            const response = await BookService.importBooks(data);
            if (response) {
                searchBooks(letterBook);
                setCorrectImportBooks("Livre importés");
            }
            else {
                setCorrectImportBooks("Une erreur est survenue");
            }
        }
    }


    const deleteBooks = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer le livre suivante ?\n\n- ID : ${BooksLetter[index].id}\n- Patronyme du livre : ${BooksLetter[index].patronyme}\n\nAttention, cette action est irréversible !`)) {
            const response = await BookService.deleteBook(id);
            if (response) {
                setBooksLetter(BooksLetter.filter((Book) => Book?.id?.toString() !== id));
                alert("Livre supprimé");
            }
            else {
                setCorrectImportBooks("Une erreur est survenue, le livre n'a pas été supprimé.");
            }
        } 
        else {
            setCorrectImportBooks("Suppression annulée");
        }
        
    }

    const modifyBook = async (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.delete('id')
        formData.append('imagesToRemove', imagesToRemove.join(';'));

        const response = await BookService.modifyBook(idBook || "", formData);

        if (response) {
            setCorrectModifyBooks("Livre modifié");
            searchBooks(letterBook);
        }
        else {
            setCorrectModifyBooks("Une erreur est survenue");
        }
    }
    
  return (
    <>    
            <div className="onglets">
                <span className={active === 'admin' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                    setActive('admin')
                    setCorrectCount("")
                    setcorrectPass("")
                    setcorrectMail("")
                    }}>Administrateur</span>
                <span className={active === 'equivalence' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('equivalence')}>Gestion des équivalences</span>
                <span className={active === 'ajout' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('ajout')}>Ajout de contenus</span>
                <span className={active === 'gestion-image' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('gestion-image')}>Gestion des livres</span>
                <span className={active === 'recherche' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                    setActive('recherche')
                    setCorrectIDBook(null)
                    }}>Recherche ID</span>
            </div>
    
            <div className="admin-functions-container">
                {active === 'admin' && (
                    <div>
                        <form className='admin-form' onSubmit={(e) => modifyPassword(e)}>
                            <fieldset>
                            <legend className='admin-legend'>
                                Identifiants 
                                <img src={Help} alt="help" className="admin-help-img"/>
    
                                <div>
                                    Pour changer de mot de passe :
                                    <ul>
                                        <li>Saisissez le login et le mot de passe ACTUELS</li>
                                        <li>Saisissez 2 fois votre nouveau mot de passe</li>                                    
                                    </ul>
                                </div>                        
                            </legend>
    
                            {step === 1 && (
                                <div>
                                    <div>
                                        <label className='admin-label'>Pseudonyme :</label>
                                        <input type="text" name="login"/>
                                        <br/><br/>
                                    </div>
    
                                    <div>
                                        <label className='admin-label'>Mot de passe actuel : </label>
                                        <input type="password" name="actual pass"/>
                                        <br/><br/>
                                    </div>
                                </div>
                            )}
    
                            {step === 2 && (
                                <div>
                                    <div>
                                        <label className='admin-label'>Nouveau mot de passe :</label>
                                        <input type="text" name="new pass"/>
                                        <br/><br/>
                                    </div>
    
                                    <div>
                                        <label className='admin-label'>Saisissez de nouveau : </label>
                                        <input type="text" name="confirm pass"/>
                                        <br/><br/>
                                    </div>
                                </div>
                            )}
    
                            <button type='submit'>Valider</button>
                            {correctPass ? <span id='label10'>{correctPass}</span> : null}
    
                            </fieldset>
                        </form>
    
                        <form className='admin-form admin-form-space'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Compteur de visites 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                    Le nombre indique le nombre de visites effectuées sur le site la date indiquée<br/>
                                    En cliquant sur "Réinitialiser le compteur", le compte sera repris de zéro et <br/>la date remplacée par la date courante.
                                    </div> 
                                </legend>
    
                                <div>
                                    <span className='admin-label'>Le site a été visité  </span>                                
                                    <span className='actual-mail'>{count.count}</span>
                                    <span className='admin-label'> fois depuis le </span>
                                    <span className='actual-mail'>{parseInt(count.resetDate.split('-')[2]) + ' ' + month[parseInt(count.resetDate.split('-')[1])-1] + ' ' + count.resetDate.split('-')[0]}</span>
                                </div> 
    
                                <button type="button" id="reset-count-button" onClick={resetCounter}>Réinitialiser le compteur</button>
                                {correctCount ? <span id='label10'>{correctCount}</span> : null}
    
                            </fieldset>
                        </form>
    
                        <form className='admin-form admin-form-space' onSubmit={(e) => modifyMail(e)}>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Modification de l'adresse mail 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                    Entrez la nouvelle adresse mail puis cliquez sur "changer"
                                    </div> 
                                </legend>
    
                                <div>
                                    <span className='admin-label'>L'adresse mail administrateur configurée est : </span>
                                    <span className='actual-mail'>{connect?.mail}</span>
                                    <br/><br/>
                                </div>
    
                                <div>
                                    <label className='admin-label'>Nouvelle adresse mail : </label>
                                    <input type="email" name="new mail"/>
                                    <br/><br/>
                                </div>
    
                                <button type='submit'>Changer</button>
                                {correctMail ? <span id='label10'>{correctMail}</span> : null}
    
                            </fieldset>
                        </form>
                    </div>
                )}
                {active === 'equivalence' && (
                    <div>
                        <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addEquivalence(e)}>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Tableau des équivalences                                
                                </legend>
                                <div className="table-wrapper-equ">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Expression 1</th>
                                                <th>Expression 2</th>
                                                <th id="admin-form-delete-col">Supprimer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        equivalences?.map((equivalence, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{equivalence.expression}</td>
                                                        <td>{equivalence.signification}</td>
                                                        <td><img src={cross} alt="supprimer" onClick={() => deleteEquivalence(equivalence.id, index)}/></td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='admin-equ-add'>
                                    <div>
                                        <input type="text" name='expression' placeholder='Expression 1'/>
                                        <input type="text" name='signification' placeholder='Expression 2'/>
                                        <button type='submit'>Ajouter l'équivalence</button>
                                    </div>
                                    {correctEquivalence ? <span id='label10'>{correctEquivalence}</span> : null}
                                </div>
    
                            </fieldset>
                        </form>
                    </div>
                )}
                {active === 'ajout' && (
                    <div>
    
                    <form className='admin-form admin-form-space' id='admin-excel-form'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Mise à jour de la base par le fichier Excel 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                        Pour insérer des contenus via un fichier Excel:
                                        <ul>
                                            <li>Cliquez sur "Générer..." et enregistrez le fichier</li>
                                            <li>Ouvrez le fichier précédemment téléchargé et remplissez-le</li>
                                            <li>Cliquez sur "Ajouter un fichier"/"Parcourir" (en fonction du navigateur) et sélectionnez le fichier rempli</li>
                                            <li>Cliquez sur "Ajouter"</li>
                                        </ul>
                                    </div> 
                                </legend>
                                <div className='admin-form-excel-container' id='admin-form-excel-bottom'>
                                    <span className='admin-form-excel'>Livre : </span> 
                                    <input type="file" name="file book" accept=".xls, .xlsx" multiple={true}/>
                                    <button type='button' onClick={() => importBooks(document.getElementById('admin-excel-form'))}>Ajouter</button>
                                    <Link to={process.env.REACT_APP_MINIO_EXCELS + "books_armorial.xlsx"} className='admin-form-excel-link'>Générer un ficher Excel type</Link>
                                </div> 
                                {correctImportBooks ? <span id='label10'>{correctImportBooks}</span> : null}
                            </fieldset>
                        </form>

                        <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addBooks(e)}>
                            <fieldset>
                                <legend className='admin-legend'>Ajout manuel d'un livre</legend>
    
                                <div className='admin-search-results-container'>
    
                                    <div className="show show-armes">
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Image : </span><input type='file' className='results-data-armes' name='files' accept='image/*' size={10 * 1024 * 1024} multiple={true}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Patronyme : </span><input type='text' className='results-data-armes' name='patronyme'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteur du cliché : </span><input type='text' className='results-data-armes' name='auteurCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Type de cliché : </span><input type='text' className='results-data-armes' name='typeCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année du cliché : </span><input type='text' className='results-data-armes' name='anneeCliche'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' className='results-data-armes' name='pays'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' className='results-data-armes' name='region'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' className='results-data-armes' name='departement'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Commune : </span><input type='text' className='results-data-armes' name='commune'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Edifice de conservation : </span><input type='text' className='results-data-armes' name='edificeConservation'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Photo : </span><input type='text' className='results-data-armes' name='photo'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IM : </span><input type='text' className='results-data-armes' name='refIm'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence PA : </span><input type='text' className='results-data-armes' name='refPa'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IA : </span><input type='text' className='results-data-armes' name='refIa'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Individu : </span><input type='text' className='results-data-armes' name='individu'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Biographie : </span><input type='text' className='results-data-armes' name='biographie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Parenté : </span><input type='text' className='results-data-armes' name='parente'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' name='date'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Dénomination : </span><input type='text' className='results-data-armes' name='denomination'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titre : </span><input type='text' className='results-data-armes' name='titre'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Catégorie : </span><input type='text' className='results-data-armes' name='categorie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Matériau : </span><input type='text' className='results-data-armes' name='materiau'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence des reproductions : </span><input type='text' className='results-data-armes' name='refReproduction'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Bibliographie : </span><input type='text' className='results-data-armes' name='bibliographie'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emblème : </span><input type='text' className='results-data-armes' name='embleme'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteurs : </span><input type='text' className='results-data-armes' name='auteurs'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Lieu d'édition : </span><input type='text' className='results-data-armes' name='lieuEdition'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Editeur : </span><input type='text' className='results-data-armes' name='editeur'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année d'édition : </span><input type='text' className='results-data-armes' name='anneeEdition'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Reliure : </span><input type='text' className='results-data-armes' name='reliure'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Provenance : </span><input type='text' className='results-data-armes' name='provenance'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Site : </span><input type='text' className='results-data-armes' name='site'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Section : </span><input type='text' className='results-data-armes' name='section'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cote : </span><input type='text' className='results-data-armes' name='cote'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Folio d'emplacement : </span><input type='text' className='results-data-armes' name='folioEmplacement'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' className='results-data-armes' name='cimiers'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armes : </span><input type='text' className='results-data-armes' name='armes'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes : </span><input type='text' className='results-data-armes' name='notes'/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Devise : </span><input type='text' className='results-data-armes' name='devise'/></div>                                       
                                    </div>
                                    <button type='submit' className='admin-modify-button'>Ajouter</button>
                                    {correctBooks ? <span id='label10'>{correctBooks}</span> : null}
                                </div>
    
                            </fieldset>
                        </form>
                    
                </div>
                )}
                {active === 'gestion-image' && (
                    <div>
    
                        <form className='admin-form admin-form-space admin-form-table'>
                            <fieldset>
                                <legend className='admin-legend'>
                                    Modification des contenus 
                                    <img src={Help} alt="help" className="admin-help-img"/>
                                    
                                    <div>
                                        <ul>
                                            <li> Le tableau affiche les contenus de la base, organisés par ordre alphabétique.<br/>
                                            Pour atteindre la lettre souhaitée, cliquez sur l'une des lettres au-dessus du tableau.<br/>
                                            </li>
                                            <li>
                                            <img src={cross} alt="supprimer"/> (dans la colonne de gauche) permet de supprimer le contenu correpondant.
                                            </li>
                                            <li>
                                                Afin de modifier l'entrée il suffit de cliquer sur le patronyme / nom correspondant.<br/>
                                                Cela ouvrira la recherche par ID avec l'ID correspondant à l'entrée, vous pourrez alors modifier les informations.
                                            </li>
                                        </ul>
                                    </div>                               
                                </legend>

                                <div>

                                    {alphabet.map((letter, index) => {
                                        return (
                                            <>
                                            <span key={"A"+index} className='admin-search-letter' onClick={() => searchBooks(letter)}>{letter}</span>
                                            <span key={"B"+index} className='admin-search-letter-dash'>{alphabet.length !== index+1 ? " - " : null }</span>
                                            </>
                                        )
                                    })}
                                    
                                </div>

                                <div className="table-wrapper">                            
                                    <table>
                                        <thead>
                                            <tr>
                                                <th id="admin-form-delete-col">Supprimer</th>
                                                <th>Patronyme</th>
                                                <th>Auteur du cliché</th>
                                                <th>Type de cliché</th>
                                                <th>Année du cliché</th>
                                                <th>Pays</th>
                                                <th>Région</th>
                                                <th>Département</th>
                                                <th>Commune</th>
                                                <th>Edifice de conservation</th>
                                                <th>Photo</th>
                                                <th>Référence IM</th>
                                                <th>Référence PA</th>
                                                <th>Référence IA</th>
                                                <th>Individu</th>
                                                <th>Biographie</th>
                                                <th>Parenté</th>
                                                <th>Date</th>
                                                <th>Dénomination</th>
                                                <th>Titre</th>
                                                <th>Catégorie</th>
                                                <th>Matériau</th>
                                                <th>Référence des reproductions</th>
                                                <th>Bibliographie</th>
                                                <th>Emblème</th>
                                                <th>Auteurs</th>
                                                <th>Lieu d'édition</th>
                                                <th>Editeur</th>
                                                <th>Année d'édition</th>
                                                <th>Reliure</th>
                                                <th>Provenance</th>
                                                <th>Site</th>
                                                <th>Section</th>
                                                <th>Cote</th>
                                                <th>Folio d'emplacement</th>
                                                <th>Cimiers</th>
                                                <th>Armes</th>
                                                <th>Notes</th>
                                                <th>Devise</th>
                                                <th>Référence Minio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        BooksLetter?.map((Book, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td key={"C"+index}><img src={cross} alt="supprimer" onClick={() => deleteBooks(Book?.id?.toString() || "", index)}/></td>
                                                        <td key={"B"+index} className='admin-patronyme' onClick={() => {
                                                            setIDBook(Book.id?.toString() || "");
                                                            searchIDBook(Book.id?.toString() || "");
                                                            setActive('recherche');
                                                        }}>{Book.patronyme}</td>
                                                        <td key={"D"+index}>{Book.auteurCliche}</td>
                                                        <td key={"E"+index}>{Book.typeCliche}</td>
                                                        <td key={"F"+index}>{Book.anneeCliche}</td>
                                                        <td key={"G"+index}>{Book.pays}</td>
                                                        <td key={"H"+index}>{Book.region}</td>
                                                        <td key={"I"+index}>{Book.departement}</td>
                                                        <td key={"J"+index}>{Book.commune}</td>
                                                        <td key={"K"+index}>{Book.edificeConservation}</td>
                                                        <td key={"L"+index}>{Book.photo}</td>
                                                        <td key={"M"+index}>{Book.refIm}</td>
                                                        <td key={"N"+index}>{Book.refPa}</td>
                                                        <td key={"O"+index}>{Book.refIa}</td>
                                                        <td key={"P"+index}>{Book.individu}</td>
                                                        <td key={"Q"+index}>{Book.biographie}</td>
                                                        <td key={"R"+index}>{Book.parente}</td>
                                                        <td key={"S"+index}>{Book.date}</td>
                                                        <td key={"T"+index}>{Book.denomination}</td>
                                                        <td key={"U"+index}>{Book.titre}</td>
                                                        <td key={"V"+index}>{Book.categorie}</td>
                                                        <td key={"W"+index}>{Book.materiau}</td>
                                                        <td key={"X"+index}>{Book.refReproduction}</td>
                                                        <td key={"Y"+index}>{Book.bibliographie}</td>
                                                        <td key={"Z"+index}>{Book.embleme}</td>
                                                        <td key={"AA"+index}>{Book.auteurs}</td>
                                                        <td key={"AB"+index}>{Book.lieuEdition}</td>
                                                        <td key={"AC"+index}>{Book.editeur}</td>
                                                        <td key={"AD"+index}>{Book.anneeEdition}</td>
                                                        <td key={"AE"+index}>{Book.reliure}</td>
                                                        <td key={"AF"+index}>{Book.provenance}</td>
                                                        <td key={"AG"+index}>{Book.site}</td>
                                                        <td key={"AH"+index}>{Book.section}</td>
                                                        <td key={"AI"+index}>{Book.cote}</td>
                                                        <td key={"AJ"+index}>{Book.folioEmplacement}</td>
                                                        <td key={"AK"+index}>{Book.cimiers}</td>
                                                        <td key={"AL"+index}>{Book.armes}</td>
                                                        <td key={"AM"+index}>{Book.notes}</td>
                                                        <td key={"AN"+index}>{Book.devise}</td>
                                                        <td key={"AO"+index}>{Book.refMinio}</td>
                                                    </tr>
                                                )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </form>                   
                
                    </div>
                )}
                {active === 'recherche' && (
                <div>
                    <form className='admin-form' onSubmit={(e) => modifyBook(e)}>
                        <fieldset>
                            <legend className='admin-legend'>Recherche par ID d'un livre</legend>
    
                            <div>
                                <label className='admin-label'>Saisir un ID : </label>
                                <input type="number" name="id" min={1} onChange={(e) => setIDBook(e.target.value)}/>    
                                <br/><br/>
                            </div>
    
                            <button type='button' onClick={() => searchIDBook()}>Rechercher</button>
                            {correctIDBook ? correctIDBook  : null}
                            {loadingBook ? <img src={LoadingImage} alt="Chargement..."/>: null}
    
                            {IDBookresponse.id !== null ? 
    
                                <div className='admin-search-results-container'> 
    
                                    <h3 className='admin-search-results-title'>{IDBookresponse.patronyme}</h3>
                                    
                                    <div className="admin-book-img-container">
                                        { IDBookresponse.refMinio && IDBookresponse.refMinio !== "" ?
                                                (IDBookresponse.refMinio?.split(';').map((image) => {
                                                    if(imagesToRemove.includes(image)) {
                                                        return null
                                                    }
                                                    return (
                                                        <div className="admin-img-container">
                                                            <div className="admin-img" onClick={() => {
                                                                setImagesToRemove([...imagesToRemove, image]);
                                                            }}>
                                                                <img src={cross} alt="supprimer"/>
                                                            </div>
                                                            <img className='atachement-img' src={minioUrl + image} alt={image || ""}/>
                                                        </div>
                                                    )
                                                }))
                                        : null}
                                    
                                        <div className="admin-book-new-img-container">
                                            <div className="admin-imgs-container">
                                                {newBookImage && Array.from(newBookImage).map((file, index) => {
                                                    return (
                                                        <div className="admin-img-container">
                                                            <img className='atachement-img' src={URL.createObjectURL(file)} alt={file.name || ""}/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <input type='file' className='results-data-armes' name='files' accept='image/*' size={10 * 1024 * 1024} multiple={true} onChange={(e) => {setNewBookImage(e.target.files)}}/>
                                            {newBookImage && <button type="button" className='admin-book-cancel-button admin-modify-button' onClick={(e) => {
                                                setNewBookImage(null);
                                                setImagesToRemove([]);
                                                (document.getElementsByName('files')[0] as HTMLInputElement).value = "";
                                            }}>Annuler</button>}
                                        </div>
                                    </div>
    
                                    <div className="show show-armes">
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Patronyme : </span><input type='text' name='patronyme' className='results-data-armes' defaultValue={IDBookresponse.patronyme || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, patronyme: e.target.value})} required/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteur du cliché : </span><input type='text' name='auteurCliche' className='results-data-armes' defaultValue={IDBookresponse.auteurCliche || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, auteurCliche: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Type de cliché : </span><input type='text' name='typeCliche' className='results-data-armes' defaultValue={IDBookresponse.typeCliche || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, typeCliche: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année du cliché : </span><input type='text' name='anneeCliche' className='results-data-armes' defaultValue={IDBookresponse.anneeCliche || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, anneeCliche: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Pays : </span><input type='text' name='pays' className='results-data-armes' defaultValue={IDBookresponse.pays || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, pays: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Région : </span><input type='text' name='region' className='results-data-armes' defaultValue={IDBookresponse.region || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, region: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Département : </span><input type='text' name='departement' className='results-data-armes' defaultValue={IDBookresponse.departement || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, departement: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Commune : </span><input type='text' name='commune' className='results-data-armes' defaultValue={IDBookresponse.commune || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, commune: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Edifice de conservation : </span><input type='text' name='edificeConservation' className='results-data-armes' defaultValue={IDBookresponse.edificeConservation || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, edificeConservation: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Photo : </span><input type='text' name='photo' className='results-data-armes' defaultValue={IDBookresponse.photo || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, photo: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IM : </span><input type='text' name='refIm' className='results-data-armes' defaultValue={IDBookresponse.refIm || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, refIm: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence PA : </span><input type='text' name='refPa' className='results-data-armes' defaultValue={IDBookresponse.refPa || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, refPa: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence IA : </span><input type='text' name='refIa' className='results-data-armes' defaultValue={IDBookresponse.refIa || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, refIa: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Individu : </span><input type='text' name='individu' className='results-data-armes' defaultValue={IDBookresponse.individu || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, individu: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Biographie : </span><input type='text' name='biographie' className='results-data-armes' defaultValue={IDBookresponse.biographie || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, biographie: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Parenté : </span><input type='text' name='parente' className='results-data-armes' defaultValue={IDBookresponse.parente || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, parente: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Date : </span><input type='text' name='date' className='results-data-armes' defaultValue={IDBookresponse.date || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, date: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Dénomination : </span><input type='text' name='denomination' className='results-data-armes' defaultValue={IDBookresponse.denomination || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, denomination: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Titre : </span><input type='text' name='titre' className='results-data-armes' defaultValue={IDBookresponse.titre || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, titre: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Catégorie : </span><input type='text' name='categorie' className='results-data-armes' defaultValue={IDBookresponse.categorie || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, categorie: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Matériau : </span><input type='text' name='materiau' className='results-data-armes' defaultValue={IDBookresponse.materiau || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, materiau: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Référence des reproductions : </span><input type='text' name='refReproduction' className='results-data-armes' defaultValue={IDBookresponse.refReproduction || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, refReproduction: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Bibliographie : </span><input type='text' name='bibliographie' className='results-data-armes' defaultValue={IDBookresponse.bibliographie || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, bibliographie: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Emblème : </span><input type='text' name='embleme' className='results-data-armes' defaultValue={IDBookresponse.embleme || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, embleme: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Auteurs : </span><input type='text' name='auteurs' className='results-data-armes' defaultValue={IDBookresponse.auteurs || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, auteurs: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Lieu d'édition : </span><input type='text' name='lieuEdition' className='results-data-armes' defaultValue={IDBookresponse.lieuEdition || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, lieuEdition: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Editeur : </span><input type='text' name='editeur' className='results-data-armes' defaultValue={IDBookresponse.editeur || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, editeur: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Année d'édition : </span><input type='text' name='anneeEdition' className='results-data-armes' defaultValue={IDBookresponse.anneeEdition || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, anneeEdition: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Reliure : </span><input type='text' name='reliure' className='results-data-armes' defaultValue={IDBookresponse.reliure || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, reliure: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Provenance : </span><input type='text' name='provenance' className='results-data-armes' defaultValue={IDBookresponse.provenance || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, provenance: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Site : </span><input type='text' name='site' className='results-data-armes' defaultValue={IDBookresponse.site || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, site: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Section : </span><input type='text' name='section' className='results-data-armes' defaultValue={IDBookresponse.section || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, section: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cote : </span><input type='text' name='cote' className='results-data-armes' defaultValue={IDBookresponse.cote || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, cote: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Folio d'emplacement : </span><input type='text' name='folioEmplacement' className='results-data-armes' defaultValue={IDBookresponse.folioEmplacement || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, folioEmplacement: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' name='cimiers' className='results-data-armes' defaultValue={IDBookresponse.cimiers || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, cimiers: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Armes : </span><input type='text' name='armes' className='results-data-armes' defaultValue={IDBookresponse.armes || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, armes: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Notes : </span><input type='text' name='notes' className='results-data-armes' defaultValue={IDBookresponse.notes || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, notes: e.target.value})}/></div>
                                        <div className='results-Armes-container-sheet'><span className='results-armes'>Devise : </span><input type='text' name='devise' className='results-data-armes' defaultValue={IDBookresponse.devise || ""} onChange={(e) => setIDBookresponse({...IDBookresponse, devise: e.target.value})}/></div>
                                    </div>
                                    {correctModifyBooks ? <span id='label10'>{correctModifyBooks}</span> : null}
                                    <button type='submit' className='admin-modify-button'>Modifier</button>
                                </div>
                            : null}
    
                        </fieldset>
                    </form>
                </div>
                )}
            </div>
          </>
  )
}