import { useEffect, useState } from 'react'
import ArmorialService from '../services/Armorial.service'
import ArmorialSheetProps from '../models/Armorial/ArmorialSheetProps.model'
import ArmeOneInterface from '../models/Armorial/Armes/ArmeOne.model'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../assets/styles/ArmorialSheet.css'
import IconoOneInterface from '../models/Armorial/Iconographie/IconoOne.model'
import ArmorialSheetType from '../types/ArmorialSheet.enum'
import EmblemOne from '../models/Armorial/Armes/EmblemOne.model'
import cross from '../assets/images/supprimer.png'
import left_arrow from '../assets/images/left-arrow.svg'
import right_arrow from '../assets/images/right-arrow.svg'


export default function ArmorialSheet({id, type}: ArmorialSheetProps) {
    const location = useLocation()
    const navigate = useNavigate()
    const minioUrl = process.env.REACT_APP_MINIO_ARMORIAL || ""
    const minioBlason = process.env.REACT_APP_MINIO_BLASON || ""

    const [modal, setModal] = useState(false)
    const [modalContent, setModalContent] = useState([''])    
    const [modalIndex, setModalIndex] = useState(0)


    const [arme, setArme] = useState<ArmeOneInterface>({
        id: null,
        patronyme: null,
        prenom: null,
        famille: null,
        titresDignites: null,
        origine: null,
        date: null,
        siecle: null,
        fief: null,
        pays: null,
        province: null,
        region: null,
        department: null,
        ville: null,
        alliances: null,
        armoiries: null,
        notes: null,
        observations: null,
        sources: null,
        leClert: null,
        armorialGenChampagne: null,
        notesArmoriaux: null,
        genealogie: null,
        document: null,
        timbre: null,
        cimier: null,
        deviseCri: null,
        tenantsSupport: null,
        decoration: null,
        ornementsExt: null,
        emblemes: null,
        imagesGeneal: null,
        imagesDoc: null,
        blasonnement_1: null,
        blasonnement_2: null,
        sceau: null,
        private: null,
        pictures: [
            {
                id: null,
                vedetteChandon: null,
                refMinio: null
            }
        ]
    })

    const [emblem, setEmblem] = useState<EmblemOne>({
        id: null,
        refBlason: null,    
        patronyme: null,    
        ornements: null,    
        blasonnement: null,    
        legende: null,    
        alliance: null,    
        datation: null,    
        bibliographie: null,    
        refMinio: null,        
        dateLoc: null,
        emplacement: null,
    })

    const [icono, setIcono] = useState<IconoOneInterface>({
        id: null,
        vedetteChandon: null,
        auteurCliche: null,
        typeCliche: null,
        anneeCliche: null,
        pays: null,
        region: null,
        departement: null,
        commune: null,
        village: null,
        edificeConservation: null,
        emplacementDansEdifice: null,
        photo: null,
        communeInseeNumber: null,
        refIm: null,
        refPa: null,
        refIa: null,
        artificialNumberDecrock: null,
        famille: null,
        individu: null,
        qualite: null,
        date: null,
        denomination: null,
        titre: null,
        categorie: null,
        materiau: null,
        refReproductions: null,
        bibliographie: null,
        remarques: null,
        auteurs: null,
        lieuEdition: null,
        editeur: null,
        anneEdition: null,
        reliure: null,
        provenance: null,
        site: null,
        section: null,
        cote: null,
        folioEmplacement: null,
        cimiers: null,
        blasonnement_1: null,
        blasonnement_2: null,
        refMinio: null,
        armorialId: null,
        sceau: null,
    })

    useEffect(() => {

        if(type === ArmorialSheetType.iconographie) {
            ArmorialService.getIconoOne(id)
            .then((response) => {
                setIcono(response)
            })
            .catch((error) => {})
        }
        else {
            if(location.state?.form.privee) {
                ArmorialService.getEmblem(id)
                .then((response) => {
                    setEmblem(response)
                })
                .catch((error) => {})
            } else {
                ArmorialService.getArmeOne(id)
                .then((response) => {
                    setArme(response)
                })
                .catch((error) => {})
            }

            
        }        
    }, [id, type, location])

    const ActionModal = (index: number, modalState: boolean, list: string[]) => {
        setModalContent(list)
        setModalIndex(index)
        setModal(modalState)
    }

  return (
    <div id='armesfamilles-container' className='public-container'>
        { !modal ? null :                 
                <div className='img-modal-back'>
                    <div className='img-modal'>
                        <img className='img-modal-img' src={modalContent[modalIndex]} alt={`Modal content ${modalIndex + 1}`} />
                        <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(0, false, [])}/>
                        {modalIndex === 0 ? null : <img src={left_arrow} alt="left-arrow" className="left-arrow" onClick={() => ActionModal(modalIndex-1, true, modalContent)}/>}
                        {modalIndex === modalContent.length-1 ? null : <img src={right_arrow} alt="right-arrow" className="right-arrow" onClick={() => ActionModal(modalIndex+1, true, modalContent)}/>}                       
                    </div>
                </div>
                }

        <Link to={type === ArmorialSheetType.armoiries ? "../armorial/armes" : "../armorial/iconographie"} state={{results: location.state?.results, form: location.state?.form}} className='return'><h4>Retour à la liste des résultats d'{type === ArmorialSheetType.armoiries ? "armes et de familles" : "iconographie"}</h4></Link>

        {type === ArmorialSheetType.armoiries ? location.state?.form.privee ? <h3>{emblem.patronyme}</h3> : <h3>{arme.patronyme}</h3> : <h3>{icono.vedetteChandon}</h3>}        

        {location.state?.form.privee ? 
            <div className='sheet-images-container'>
                <img className='atachement-img' key={'icono'} src={minioBlason + emblem?.refMinio || ""} alt={emblem?.refMinio || ""} onClick={() => ActionModal(0, true, [minioBlason + emblem?.refMinio])}/>
            </div>
      : (type === ArmorialSheetType.iconographie ? (
            <div className='sheet-images-container'>
                <img className='atachement-img' key={'icono'} src={minioUrl + icono?.refMinio || ""} alt={icono.refMinio || ""} onClick={() => ActionModal(0, true, [minioUrl + icono?.refMinio])}/>
            </div>
        ) : arme.pictures && arme.pictures.length > 0 ? (
            <div className='sheet-images-container'>
                {
                arme.pictures.map((image, index) => {
                    return (                        
                        <img className='atachement-img' src={minioUrl + image?.refMinio || ""} alt={image.refMinio || ""} onClick={() => navigate(`/armorial/iconographie/${image.id}`)}/>
                    )
                })
            }
            </div>
        ) : null)}

        {type === ArmorialSheetType.armoiries ? null : 
        <Link to={`/armorial/armes/${icono.armorialId}`}  className='return' id='go-to-arme'>Retour à la fiche armes et famille</Link>}

        {location.state?.form.privee ? 
            <div className="show show-armes">

                <div className='results-heraldique-container-sheet'><span className='results-armes'>Référence reproduction : </span><span className='results-data-armes'>{emblem.refBlason}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Patronyme : </span><span className='results-data-armes'>{emblem.patronyme}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Ornements extérieurs : </span><span className='results-data-armes'>{emblem.ornements}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Blasonnement : </span><span className='results-data-armes'>{emblem.blasonnement}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Légende : </span><span className='results-data-armes'>{emblem.legende}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Alliance : </span><span className='results-data-armes'>{emblem.alliance}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Datation : </span><span className='results-data-armes'>{emblem.datation}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Bibliographie : </span><span className='results-data-armes'>{emblem.bibliographie}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Emplacement dans édifice : </span><span className='results-data-armes'>{emblem.emplacement}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Édifice de conservation : </span><span className='results-data-armes'>{emblem.dateLoc}</span></div>
            </div>            
        : (type === ArmorialSheetType.armoiries ?
            <div className="show show-armes">

                <div className='results-heraldique-container-sheet'><span className='results-armes'>ID : </span><span className='results-data-armes'>{arme.id}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><span className='results-data-armes'>{arme.patronyme}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Prénom : </span><span className='results-data-armes'>{arme.prenom}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Famille : </span><span className='results-data-armes'>{arme.famille}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Titres & dignités : </span><span className='results-data-armes'>{arme.titresDignites}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Origine : </span><span className='results-data-armes'>{arme.origine}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Alliances : </span><span className='results-data-armes'>{arme.alliances}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Date : </span><span className='results-data-armes'>{arme.date}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Siècle d'extinction : </span><span className='results-data-armes'>{arme.observations}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Fief : </span><span className='results-data-armes'>{arme.fief}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Pays : </span><span className='results-data-armes'>{arme.pays}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Province : </span><span className='results-data-armes'>{arme.province}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Région : </span><span className='results-data-armes'>{arme.region}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Département : </span><span className='results-data-armes'>{arme.department}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Ville : </span><span className='results-data-armes'>{arme.ville}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes & observations : </span><span className='results-data-armes'>{arme.notes}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Sources : </span><span className='results-data-armes'>{arme.sources}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Document : </span><span className='results-data-armes'>{arme.document}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes armoriaux : </span><span className='results-data-armes'>{arme.notesArmoriaux}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Timbre : </span><span className='results-data-armes'>{arme.timbre}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Cimier : </span><span className='results-data-armes'>{arme.cimier}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Devise, cri : </span><span className='results-data-armes'>{arme.deviseCri}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Tenants, support : </span><span className='results-data-armes'>{arme.tenantsSupport}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Décoration : </span><span className='results-data-armes'>{arme.decoration}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Ornements extérieurs : </span><span className='results-data-armes'>{arme.ornementsExt}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Emblèmes : </span><span className='results-data-armes'>{arme.emblemes}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes : </span><span className='results-data-armes'>{arme.notes}</span></div>
            <div>
                <span className='results-armes'>Blasonnements : </span>
                <ul>
                    {arme.blasonnement_1 ? <li className='results-data-armes'>{arme.blasonnement_1}</li> : null}
                    {arme.blasonnement_2 ? <li className='results-data-armes'>{arme.blasonnement_2}</li> : null}
                </ul>
            </div>

            </div>
            :
            <div className="show show-armes">
                <div className='results-heraldique-container-sheet'><span className='results-armes'>ID : </span><span className='results-data-armes'>{icono.id}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Pays : </span><span className='results-data-armes'>{icono.pays}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Région : </span><span className='results-data-armes'>{icono.region}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Département : </span><span className='results-data-armes'>{icono.departement}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Commune : </span><span className='results-data-armes'>{icono.commune}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Village : </span><span className='results-data-armes'>{icono.village}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Edifice de conservation : </span><span className='results-data-armes'>{icono.edificeConservation}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Emplacement dans l'édifice : </span><span className='results-data-armes'>{icono.emplacementDansEdifice}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Numéro artificiel Decrock : </span><span className='results-data-armes'>{icono.artificialNumberDecrock}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Numéro INSEE de la commune : </span><span className='results-data-armes'>{icono.communeInseeNumber}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Famille / Institution : </span><span className='results-data-armes'>{icono.famille}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Individu : </span><span className='results-data-armes'>{icono.individu}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Qualité : </span><span className='results-data-armes'>{icono.qualite}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Date : </span><span className='results-data-armes'>{icono.date}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Dénomination : </span><span className='results-data-armes'>{icono.denomination}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Catégorie : </span><span className='results-data-armes'>{icono.categorie}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Matériau : </span><span className='results-data-armes'>{icono.materiau}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Bibliographie : </span><span className='results-data-armes'>{icono.bibliographie}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Référence de la reproduction : </span><span className='results-data-armes'>{icono.refReproductions}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Auteur du cliché : </span><span className='results-data-armes'>{icono.auteurCliche}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Type du cliché : </span><span className='results-data-armes'>{icono.typeCliche}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Année du cliché : </span><span className='results-data-armes'>{icono.anneeCliche}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Type de photo : </span><span className='results-data-armes'>{icono.photo}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Référence IM : </span><span className='results-data-armes'>{icono.refIm}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Référence PA : </span><span className='results-data-armes'>{icono.refPa}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Référence IA : </span><span className='results-data-armes'>{icono.refIa}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>{"Auteur(s)"} : </span><span className='results-data-armes'>{icono.auteurs}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Titre : </span><span className='results-data-armes'>{icono.titre}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Lieu d'édition : </span><span className='results-data-armes'>{icono.lieuEdition}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Editeur : </span><span className='results-data-armes'>{icono.editeur}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Année d'édition : </span><span className='results-data-armes'>{icono.anneEdition}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Reliure : </span><span className='results-data-armes'>{icono.reliure}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Provenance : </span><span className='results-data-armes'>{icono.provenance}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Site : </span><span className='results-data-armes'>{icono.site}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Section : </span><span className='results-data-armes'>{icono.section}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Cote : </span><span className='results-data-armes'>{icono.cote}</span></div>
                <div className='results-heraldique-container-sheet'><span className='results-armes'>Folio ou emplacement : </span><span className='results-data-armes'>{icono.folioEmplacement}</span></div>
            </div>
        )}
        
    </div>
  )
}
