import { useEffect, useState } from 'react'
import '../assets/styles/Library.css'
import chevalier from '../assets/images/chevalier.jpg'
import LibraryServices from '../services/Library.service'
import WorkInterface from '../models/Library/Work.model'
import { Link, useNavigate } from 'react-router-dom'
import AdminService from '../services/Admin.service'

export default function Work() {

    const [books, setBooks] = useState<WorkInterface[]>([])

    
    let expiration = sessionStorage.getItem('expiration');
    let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);
    const navigate = useNavigate();

    useEffect(() => {
        LibraryServices.getLibrary()
        .then((response: WorkInterface[]) => {
            setBooks(response)
        })
        .catch((error) => {})
    }, [])

  
  return (
    <div id='bibli-back'>
      {connect ? <button className='deconnexion' onClick={() => {
        AdminService.logout().then(() => {navigate('/bibliotheques')})
      }}>Déconnexion</button> : null}
      <h1 className='bibli-title'>Oeuvres</h1>

      <div className='armorial-navbar navbar-biblio-container'>
            <Link to="/accueil">
              <div className='armorial-navbar-text navbar-biblio'>
                Accueil
              </div>                
            </Link>
            <Link to="/bibliotheques">
              <div className='armorial-navbar-text navbar-biblio'>
                Menu
              </div>                
            </Link>
            {connect ? 
            <div className='armorial-navbar-text navbar-biblio' onClick={() => {
            navigate('/bibliotheques/administration')
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/bibliotheques/administration' }}>
              <div className='armorial-navbar-text navbar-biblio'>
                Administration
              </div>                
            </Link>
            }
        </div>        

      {
        books.map((o: WorkInterface) => (
          <Link to="/bibliotheques/oeuvres/tomes" state={{ book: o}} key={o.id}>
            <h3 className='bibli-title'>{o.titre}</h3>
          </Link>
        ))
      }
      <img src={chevalier} alt="chevalier" />
    </div>
  )
}
