import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/palisep.png';
import '../assets/styles/AdminConnexion.css';
import AdminService from '../services/Admin.service';
import { SyntheticEvent, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export default function AdminConnexion() {

    const from = useLocation().state?.from;
    const navigate = useNavigate();
    
    const [correct, setCorrect] = useState<string | null>(null);

    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const payload = {
            username: data.get('login') as string,
            password: data.get('pass') as string
        }

        if (payload.username === ""|| payload.password === "") {
            return;
        }
        const response = await AdminService.login(payload);
        
        if (response === false) {
            setCorrect("Mauvais identifiants");
            return;
        }
        else {
            const expiration = jwtDecode(response?.accessToken).exp;
            if (expiration) {
                sessionStorage.setItem('expiration', expiration.toString());
                sessionStorage.setItem('access-token', response.accessToken);
            }
            
            navigate(from);
        }
        
    }

    const ForgotSubmit = async () => {
        const response = await AdminService.forgot();

        if (response === false) {
            setCorrect("Une erreur est survenue");
            return;
        }
        else {
            setCorrect("Un mail vous a été envoyé.");
        }
    }
    
  return (
    <div id='heraldique-back'>

        <div id="connexion-container">
            <Link to="/accueil">
                <img id="connexion-logo" src={logo} alt="Palisep" />
            </Link>


            <fieldset>
                <legend id="connexion-legend">IDENTIFICATION</legend>
                <br/>

                <form onSubmit={(e) => handleSubmit(e)} id="connexion-form">
                    <div>
                        <label className='connexion-label'>Pseudonyme</label>
                        <input type="text" name="login"/>
                        <br/><br/>
                    </div>

                    <div>
                        <label className='connexion-label'>Mot de passe</label> 
                        <input type="password" name="pass"/>
                        <br/><br/>
                    </div>

                    {correct ? <span id='label10'>{correct}</span> : null}
                                        
                    <button type="submit">Connexion</button>
                </form>
                <br/>
            </fieldset>
            <p id="lost-password" onClick={ForgotSubmit}>Mot de passe oublié ?</p>
										
        </div>

    </div>
  )
}
