import '../assets/styles/Armorial.css'
import ArmesFamilles from '../components/ArmesFamilles'
import Iconographie from '../components/Iconographie'
import ArmorialSheet from '../components/ArmorialSheet'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import ArmorialType from '../types/Armorial.enum'
import ArmorialSheetType from '../types/ArmorialSheet.enum'
import ArmorialAdmin from '../components/ArmorialAdmin'
import AdminService from '../services/Admin.service'

export default function Armorial({type}: {type: ArmorialType}) {

  
  let expiration = sessionStorage.getItem('expiration');
  let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);

  const results = useLocation()?.state?.results || null;
  const form = useLocation()?.state?.form || null;
  
  var id = useParams().id || null;
  const navigate = useNavigate()

  if (type === ArmorialType.administration && connect === null) {
    return <Navigate to='/administration/connexion' state={{ from: '/armorial/administration' }} />;
  }


  return (
    <div id='armorial-back'>

      {connect ? <button className='deconnexion' onClick={() => {
          AdminService.logout().then(() => navigate('/armorial'));          
          }}>Déconnexion</button> : null}

      <h2 id='armorial-title'>Armorial historique & monumental européen</h2>
      
      <div className='armorial-navbar'>
        <Link to="/accueil">
          <div className='armorial-navbar-text'>
          Accueil
          </div>                
        </Link>
        <div className='armorial-navbar-text' onClick={() => {
          navigate('/armorial/armes')
          }}>
          Recherche d'Armes & de Familles 
          </div> 
          <div className='armorial-navbar-text' onClick={() => {
            navigate('/armorial/iconographie')
            }}>
          Recherche d'iconographies
          </div>
          {connect ? 
            <div className='armorial-navbar-text' onClick={() => {
            navigate('/armorial/administration')
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/armorial/administration'}}>
              <div className='armorial-navbar-text'>
              Administration
              </div>                
            </Link>
            }
        
      </div>
      {
      type === ArmorialType.administration ? connect ? <ArmorialAdmin /> : <Navigate to={'/administration/connexion'} state={{ from: '/armorial/administration' }}/> : id ?  <ArmorialSheet id={id} type={type === ArmorialType.iconographie ? ArmorialSheetType.iconographie : ArmorialSheetType.armoiries}/> : (type === ArmorialType.iconographie ? <Iconographie results={results} form={form} /> : <ArmesFamilles results={results} form={form}/>)
      }
    </div>
  )
}
