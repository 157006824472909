import axios from "axios";
import { NavigateFunction } from "react-router-dom";

const instance = axios.create({
  baseURL: process.env.REACT_APP_DATABASE,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

let navigate: NavigateFunction | null = null;

export const setNavigate = (nav: NavigateFunction) => {
  navigate = nav;
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("access-token");
      sessionStorage.removeItem("expiration");

      if (navigate) {
        navigate("/administration/connexion", { state: { from: '/'+window.location.pathname.split('/')[1]+'/administration' } });
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default instance;