import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import CounterService from './services/Counter.service';

CounterService.getCount().then((response) => {
  const newCount = parseInt(response.count) + 1;
  CounterService.modifyCount(newCount.toString());
}).catch((error) => {
  throw error;
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

reportWebVitals();
