import { Link, useNavigate } from 'react-router-dom'
import Help from '../assets/images/help.gif'
import cross from '../assets/images/supprimer.png'
import add from '../assets/images/ajouter.png'
import { Fragment, useEffect, useState } from 'react';
import CounterService from '../services/Counter.service';
import AdminService from '../services/Admin.service';
import '../assets/styles/Admin.css'
import WorkInterface from '../models/Library/Work.model';
import LibraryService from '../services/Library.service';
import WorkFormInterface from '../models/Library/WorkForm.model';

export default function LibraryAdmin() {

    let expiration = sessionStorage.getItem('expiration');
    let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);
    const navigate = useNavigate();

    const [user, setUser] = useState<any>(null);
    const [active, setActive] = useState<string>('admin');
    const [step, setStep] = useState<number>(1);
    const [correctPass, setcorrectPass] = useState<string | null>(null);
    const [correctMail, setcorrectMail] = useState<string | null>(null);
    const [correctCount, setCorrectCount] = useState<string | null>(null);
    const [modal, setModal] = useState<boolean>(false);
    const [modalId, setModalId] = useState<string>("");
    const [modalIndex, setModalIndex] = useState<number>(0);
    const [count, setCount] = useState<{"id" : 0,"count" : string, "resetDate" : string}>({id: 0, count: "", resetDate: ""});
    const [correctWork, setCorrectWork] = useState<string | null>(null);
    const [biblioLetter, setBiblioLetter] = useState<WorkInterface[]>([]);
    const [addWork, setAddWork] = useState<WorkFormInterface>({
        "titre": "",
        "commentaire": "",
        "volumes": [
            {
                "nomTome": "",
                "description": "",
                "file": null
            }
        ]
    });

    const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    useEffect(() => {

        AdminService.getInfo().then((response) => {
            setUser(response);
        }).catch((error) => {
            throw error
        })

        CounterService.getCount().then((response) => {
            setCount(response);
        }).catch((error) => {
            throw error
        })

        LibraryService.getLibrary().then((response: WorkInterface[]) => {
            setBiblioLetter(response);
        }).catch((error) => {
            throw error;
        })

    }, [])

    const resetCounter = async () => {
        const response = await CounterService.modifyCount()
        if (response) {
            setCorrectCount("Compteur réinitialisé");
            count.count = "0";
            let date = new Date();
            count.resetDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
    }
    
        const modifyMail = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const payload = {
            mail: data.get('new mail') as string
        }

        if (payload.mail !== "" && payload.mail !== user?.mail) {
            const response = await AdminService.change(payload);

            if (response === false) {
                setcorrectMail("Une erreur est survenue");
                return;
            }
            else {
                setcorrectMail("Adresse mail modifiée");
                setUser({...user, mail: payload.mail});
                }
        }
        (document.getElementsByName("new mail")[0] as HTMLInputElement).value = "";
    }

    const modifyPassword = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (step === 1) {
            const payload = {
                username: data.get('login') as string,
                password: data.get('actual pass') as string
            }

            const response = await AdminService.login(payload);

            if (response === false) {
                setcorrectPass("Mauvais identifiants");
                return;
            }
            else {
                setStep(2);
            }
        }
        else if (step === 2) {
            const payload = {
                password: data.get('new pass') as string
            }

            if (payload.password !== "" && payload.password !== data.get('actual pass') && payload.password === data.get('confirm pass')) {
                const response = await AdminService.change(payload);
                if (response === false) {
                    setcorrectPass("Une erreur est survenue");
                    return;
                }
                else {
                    setcorrectPass("Mot de passe modifié");
                    setStep(1);
                }
            }
        }
    }

    const addTomes = () => {
        if (addWork.volumes[addWork.volumes.length-1].nomTome !== "" && addWork.volumes[addWork.volumes.length-1].description !== "" && addWork.volumes[addWork.volumes.length-1].file !== null) {
            const newVolumes = [...addWork.volumes, {
                "nomTome": "",
                "description": "",
                "file": null
            }];
            setAddWork({ ...addWork, volumes: newVolumes });
        }
    }

    const ActionModal = (modalState: boolean, id: string, index: number) => {
        setModalId(id)
        setModal(modalState)
        setModalIndex(index)
    }

    const createTome = (e: any, id: string | number, index: number) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        data.append('bookId', id.toString());

        LibraryService.createVolume(data).then((response) => {
            if (response === false) {
                alert("Une erreur est survenue");
                return
            }
            else {
                alert("Tome ajouté");
                setModal(false);

                const updatedBiblioLetter = biblioLetter.map((work, i) => {
                    if (i === index) {
                        return {
                            ...work,
                            volumes: [...work.volumes, response]
                        };
                    }
                    return work;
                });
                setBiblioLetter(updatedBiblioLetter);
            }
        }).catch((error) => {
            throw error;
        })
    }

    const createWork = async () => {

        const {volumes, ...work} = addWork;
        const response = await LibraryService.createWork(work);
        if (response === false) {
            setCorrectWork("Une erreur est survenue");
            return
        }
        else {
            volumes.forEach(async (volume) => {
                const newVolume = new FormData()
                newVolume.append('nomTome', volume.nomTome)
                newVolume.append('description', volume.description || "")
                newVolume.append('bookId', response.id.toString())
                newVolume.append('file', volume.file as Blob)
                
                const responseVolume = await LibraryService.createVolume(newVolume)

                if (responseVolume === false) {
                    setCorrectWork("Une erreur est survenue");
                    LibraryService.deleteWork(response.id);
                    return
                }

                setCorrectWork("Oeuvre ajoutée");
                setBiblioLetter((prevBiblioLetter) => [
                    ...prevBiblioLetter,
                    {
                        ...response,
                        volumes: [responseVolume]
                    }
                ]);
            })
        }

    }

    const deleteWork = (id: number, index: number) => async () => {

        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Voulez-vous vraiment supprimer cette oeuvre ?\n\n- titre : ${biblioLetter[index].titre}\n- Commentaires : ${biblioLetter[index].commentaire}\n\nAttention, cette action est irréversible !`)) {
            const response = await LibraryService.deleteWork(id);
            if (response === false) {
                alert("Une erreur est survenue\n\nL'oeuvre n'a pas été supprimée");
                return
            }
            else {
                alert("Oeuvre supprimée");
                const newWorks = biblioLetter.filter((work, i) => i !== index);
                setBiblioLetter(newWorks);
            }
        }
        else {
            alert("Suppression annulée");
        }
    }

    const deleteVolume = async (id: number, indexVolume: number, indexWork:  number) => {
        
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Voulez-vous vraiment supprimer ce tome ?\n\n- titre : ${biblioLetter[indexWork].volumes[indexVolume].nomTome}\n- Description : ${biblioLetter[indexWork].volumes[indexVolume].description}\n\nAttention, cette action est irréversible !`)) {
            const response = await LibraryService.deleteVolume(id);
            if (response === false) {
                alert("Une erreur est survenue\n\nLe tome n'a pas été supprimé");
                return
            }
            else {
                alert("Tome supprimé");
                const newVolumes = biblioLetter[indexWork].volumes.filter((volume, i) => i !== indexVolume);
                biblioLetter[indexWork].volumes = newVolumes;
                setBiblioLetter([...biblioLetter]);
            }
        }
        else {
            alert("Suppression annulée");
        }
    }
    
  return (

    <div id='bibli-back'>
      {connect ? <button className='deconnexion' onClick={() => {
        AdminService.logout().then(() => {navigate('/bibliotheques')})
      }}>Déconnexion</button> : null}
      <h1 className='bibli-title'>Bibliothèques</h1>

      <div className='armorial-navbar navbar-biblio-container'>
            <Link to="/accueil">
              <div className='armorial-navbar-text navbar-biblio'>
                Accueil
              </div>                
            </Link>
            <Link to="/bibliotheques">
              <div className='armorial-navbar-text navbar-biblio'>
                Menu
              </div>                
            </Link>
            {connect ? 
            <div className='armorial-navbar-text navbar-biblio' onClick={() => {
            navigate('/bibliotheques/administration')
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/bibliotheques' }}>
              <div className='armorial-navbar-text navbar-biblio'>
                Administration
              </div>                
            </Link>
            }
        </div>

        { !modal ? null :                 
                <div className='img-modal-back'>
                    <div className='img-modal'>
                        <form onSubmit={(e) => createTome(e, modalId, modalIndex)}>
                            <label className='admin-label'>Nom du tome : </label>
                            <input type="text" name="nomTome"/>
                            <br/><br/>
                            <label className='admin-label'>Description : </label>
                            <input type="text" name="description"/>
                            <br/><br/>
                            <label className='admin-label'>Fichier : </label>
                            <input type="file" name="file" accept='.pdf'/>
                            <br/><br/>
                            <button type='submit'>Ajouter</button>
                        </form>
                        <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(false, "", 0)}/>                  
                    </div>
                </div>
                }

        <div className="admin-container">

        <h3 className="admin-title">Espace d'administration</h3>

        <div className="onglets">
            <span className={active === 'admin' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                setActive('admin')
                setCorrectCount("")
                setcorrectPass("")
                setcorrectMail("")
                }}>Administrateur</span>
            <span className={active === 'gestion' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('gestion')}>Gestion des contenus</span>
        </div>

        <div className="admin-functions-container">
            {active === 'admin' && (
                <div>
                    <form className='admin-form' onSubmit={(e) => modifyPassword(e)}>
                        <fieldset>
                        <legend className='admin-legend'>
                            Identifiants 
                            <img src={Help} alt="help" className="admin-help-img"/>

                            <div>
                                Pour changer de mot de passe :
                                <ul>
                                    <li>Saisissez le login et le mot de passe ACTUELS</li>
                                    <li>Saisissez 2 fois votre nouveau mot de passe</li>                                    
                                </ul>
                            </div>                        
                        </legend>

                        {step === 1 && (
                            <div>
                                <div>
                                    <label className='admin-label'>Pseudonyme :</label>
                                    <input type="text" name="login"/>
                                    <br/><br/>
                                </div>

                                <div>
                                    <label className='admin-label'>Mot de passe actuel : </label>
                                    <input type="password" name="actual pass"/>
                                    <br/><br/>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div>
                                <div>
                                    <label className='admin-label'>Nouveau mot de passe :</label>
                                    <input type="text" name="new pass"/>
                                    <br/><br/>
                                </div>

                                <div>
                                    <label className='admin-label'>Saisissez de nouveau : </label>
                                    <input type="text" name="confirm pass"/>
                                    <br/><br/>
                                </div>
                            </div>
                        )}

                        <button type='submit'>Valider</button>
                        {correctPass ? <span id='label10'>{correctPass}</span> : null}

                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Compteur de visites 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                Le nombre indique le nombre de visites effectuées sur le site la date indiquée<br/>
			                    En cliquant sur "Réinitialiser le compteur", le compte sera repris de zéro et <br/>la date remplacée par la date courante.
                                </div> 
                            </legend>

                            <div>
                                <span className='admin-label'>Le site a été visité  </span>                                
                                <span className='actual-mail'>{count.count}</span>
                                <span className='admin-label'> fois depuis le </span>
                                <span className='actual-mail'>{parseInt(count.resetDate.split('-')[2]) + ' ' + month[parseInt(count.resetDate.split('-')[1])-1] + ' ' + count.resetDate.split('-')[0]}</span>
                            </div> 

                            <button type="button" id="reset-count-button" onClick={resetCounter}>Réinitialiser le compteur</button>
                            {correctCount ? <span id='label10'>{correctCount}</span> : null}

                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space' onSubmit={(e) => modifyMail(e)}>
                        <fieldset>
                            <legend className='admin-legend'>
                                Modification de l'adresse mail 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                Entrez la nouvelle adresse mail puis cliquez sur "changer"
                                </div> 
                            </legend>

                            <div>
                                <span className='admin-label'>L'adresse mail administrateur configurée est : </span>
                                <span className='actual-mail'>{user?.mail}</span>
                                <br/><br/>
                            </div>

                            <div>
                                <label className='admin-label'>Nouvelle adresse mail : </label>
                                <input type="email" name="new mail"/>
                                <br/><br/>
                            </div>

                            <button type='submit'>Changer</button>
                            {correctMail ? <span id='label10'>{correctMail}</span> : null}

                        </fieldset>
                    </form>
                </div>
            )}
            {active === 'gestion' && (
            <div>

                    <form className='admin-form admin-form-space admin-form-table'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Modification des contenus 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                    <ul>
                                        <li> Le tableau affiche les contenus de la base, organisés par ordre alphabétique.<br/>
                                        Pour atteindre la lettre souhaitée, cliquez sur l'une des lettres au-dessus du tableau.<br/>
                                        </li>
                                        <li>
                                        <img src={cross} alt="supprimer"/> (dans la colonne de gauche) permet de supprimer le contenu correpondant.
                                        </li>
                                        <li>
                                            Afin de modifier l'entrée il suffit de cliquer sur le patronyme correspondant.<br/>
                                            Cela ouvrira la recherche par ID avec l'ID correspondant à l'entrée, vous pourrez alors modifier les informations.
                                        </li>
                                    </ul>
                                </div>                                
                            </legend>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Supprimer l'oeuvre</th>
                                        <th>Ajouter un tome</th>
                                        <th>Nom de l'oeuvre</th>
                                        <th>Commentaires</th>
                                        <th>Supprimer le tome</th> 
                                        <th>Tomes</th>
                                        <th>Description du tome</th>
                                        <th>Nom du fichier</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {biblioLetter.map((work, indexWork) => (
                                        <Fragment key={work.id}>
                                            <tr key={`work-${work.id}`}>
                                                <td key={`work-A${work.id}`} rowSpan={work.volumes.length + 1}><img key={`work-img${work.id}`} src={cross} alt="supprimer" onClick={deleteWork(work.id, indexWork)}/></td>
                                                <td key={`work-B${work.id}`} rowSpan={work.volumes.length  + 1}><img key={`work-img${work.id}`} src={add} alt="ajouter" onClick={() => ActionModal(true, work.id.toString(), indexWork)}/></td>
                                                <td key={`work-C${work.id}`} rowSpan={work.volumes.length + 1}>{work.titre || ""}</td>
                                                <td key={`work-D${work.id}`} rowSpan={work.volumes.length + 1}>{work.commentaire || ""}</td>
                                            </tr>

                                            {work.volumes.map((volume, indexVolume) => (
                                                <tr key={`volume-${volume.id}`}>
                                                    <td key={`volume-A${volume.id}`}><img src={cross} alt="supprimer" onClick={() => deleteVolume(volume.id, indexVolume, indexWork)}/></td>
                                                    <td key={`volume-B${volume.id}`}>{volume.nomTome || ""}</td>
                                                    <td key={`volume-C${volume.id}`}>{volume.description || ""}</td>
                                                    <td key={`volume-D${volume.id}`}>{volume.refMinio || ""}</td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                            
                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space admin-form-table'>
                        <fieldset>
                            <legend className='admin-legend'>Ajout manuel</legend>

                                    <table id="admin-bibli-table">
                                        <tbody>
                                        <tr>
                                            <td rowSpan={addWork.volumes.length+2}>
                                                <div>
                                                    <label className='admin-label-bibli'>Nom de l'oeuvre : </label>
                                                    <input type="text" name="titre" onChange={(e) => {
                                                        addWork.titre = e.target.value;
                                                        setAddWork(addWork);
                                                    }}/>
                                                </div>
                                                <div>
                                                    <label className='admin-label-bibli'>Commentaires : </label>                                                    
                                                    <input type="text" name="commentaire" onChange={(e) => {
                                                        addWork.commentaire = e.target.value;
                                                        setAddWork(addWork);
                                                    }}/>
                                                </div>
                                            </td>
                                        </tr>
                                        {addWork.volumes.map((volume, index) => {
                                            return (
                                                <tr key={`volume-${index}`}>
                                                    <td>
                                                        <div>
                                                            <label key={`volume-label1-${index}`} className='admin-label-bibli'>Nom du tome : </label>
                                                            <input key={`volume-input1-${index}`} type="text" name="nom tome" value={addWork.volumes[index].nomTome} onChange={(e) => {
                                                                let newVolumes = [...addWork.volumes];
                                                                newVolumes[index].nomTome = e.target.value;
                                                                setAddWork({ ...addWork, volumes: newVolumes });
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label key={`volume-label2-${index}`} className='admin-label-bibli'>Description : </label>
                                                            <input key={`volume-input2-${index}`} type="text" name="description" value={addWork.volumes[index].description || ""}  onChange={(e) => {
                                                                let newVolumes = [...addWork.volumes];
                                                                newVolumes[index].description = e.target.value;
                                                                setAddWork({ ...addWork, volumes: newVolumes });
                                                            }}/>
                                                        </div>
                                                        <div id="admin-bibli-table-file">
                                                            <label key={`volume-label3-${index}`} className='admin-label-bibli'>Fichier : </label>
                                                            <input key={`volume-input3-${index}`} type="file" name="file" accept='.pdf'  className='input-fil-transparent' onChange={(e) => {
                                                                let newVolumes = [...addWork.volumes];
                                                                newVolumes[index].file = e.target.files ? e.target.files[0] : null;
                                                                setAddWork({ ...addWork, volumes: newVolumes });
                                                                e.target.value = "";
                                                            }}/>
                                                            <p key={`volume-input3-file-${index}`} className='p-input-file'>Selectionné : {addWork.volumes[index].file?.name || "Aucun"}</p>
                                                        </div>
                                                    </td>    
                                                    <td>
                                                        <img src={cross} alt="supprimer" onClick={() => {
                                                            if (addWork.volumes.length !== 1) {                                                          
                                                                const newVolumes = addWork.volumes.filter((volume, i) => i !== index);
                                                                setAddWork({ ...addWork, volumes: newVolumes });
                                                            }
                                                            else if (addWork.volumes.length === 1) {
                                                                let newVolumes = [...addWork.volumes];
                                                                newVolumes[index].nomTome = "";
                                                                newVolumes[index].description = "";
                                                                newVolumes[index].file = null;
                                                                setAddWork({ ...addWork, volumes: newVolumes });
                                                            }
                                                        }}/>
                                                    </td>                                                
                                                </tr>
                                            )
                                        })}

                                        <tr>
                                            <td id="admin-bibli-table-button" colSpan={2}>
                                                <button type='button' onClick={addTomes}>Ajouter un tome</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <button type='button' className="admin-bibli-button" onClick={createWork}>Ajouter l'oeuvre</button>
                                    {correctWork ? <span id='label10'>{correctWork}</span> : null}

                        </fieldset>
                    </form>
                
            </div>
            )}
        </div>
    </div>



    </div>
  )
}