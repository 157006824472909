import {useState, SyntheticEvent, useEffect} from 'react'
import '../assets/styles/ArmesFamilles.css'
import echange from '../assets/images/echange.jpg'
import cross from '../assets/images/supprimer.png'
import left_arrow from '../assets/images/left-arrow.svg'
import right_arrow from '../assets/images/right-arrow.svg'
import PrivateService from '../services/Private.service'
import Select from '../assets/Select.json'
import ArmorialService from '../services/Armorial.service'
import ArmesFormInterface from '../models/Armorial/Armes/ArmesForm.model'
import { Link } from 'react-router-dom'
import ArmesResponseInterface from '../models/Armorial/Armes/ArmesResponse.model'
import LoadingImage from '../assets/images/loading.gif'
import {PrivateArmesResponseInterface} from '../models/Armorial/Armes/ArmesResponse.model';

export default function ArmesFamilles({results, form} : { results: ArmesResponseInterface | null, form: ArmesFormInterface | null}) {

    const minioUrl = process.env.REACT_APP_MINIO_ARMORIAL || ""
    const minioBlason = process.env.REACT_APP_MINIO_BLASON || ""
    const [wordOrder, setWordOrder] = useState<boolean>(form?.ordonnee || true)
    const [privateRequest, setPrivateRequest] = useState(false)
    const [isPrivate, setIsPrivate] = useState<string | null>(form?.privee || null)
    const [passwordState, setPasswordState] = useState('')
    const [armes, setArmes] = useState<ArmesResponseInterface | null>(null)
    const [blasons, setBlason] = useState<PrivateArmesResponseInterface | null>(null)
    const [modal, setModal] = useState(false)
    const [sceau, setSceau] = useState(false)
    const [modalContent, setModalContent] = useState([''])
    const [modalIndex, setModalIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState<Partial<ArmesFormInterface> | null>(form)
    const [lastResults, setLastResults] = useState<ArmesResponseInterface | PrivateArmesResponseInterface | null>(results)

   

    useEffect(() => {
            if(lastResults) {
                if (isPrivate != null) {
                    console.log("blason");
                    setBlason(lastResults as PrivateArmesResponseInterface);
                } else if (isPrivate == null) {
                    console.log("armes");
                    setArmes(lastResults as ArmesResponseInterface);
                }
            }
        }, [lastResults, isPrivate])

    const ActionModal = (index: number, modalState: boolean, list: string[]) => {
        setModalContent(list)
        setModalIndex(index)
        setModal(modalState)
    }
    
    const SubmitFormArmes = (e: SyntheticEvent<HTMLFormElement>) => {
        setLoading(true)

        e.preventDefault()
        let formData = new FormData(e.currentTarget)
        
        if (
            formData.get('armes') === "" &&
            formData.get('patronyme') === "" &&
            formData.get('fief_communes') === "" &&
            formData.get('siecle') === "" &&
            formData.get('pays') === "" &&
            formData.get('departement') === "" &&
            formData.get('region') === "" &&
            formData.get('denomination') === ""
        ) {
            return;
        }

        
        let tempPayload: Partial<ArmesFormInterface> = isPrivate 
        ? { 
            patronyme: formData.get('patronyme') as string || null,
            armes: formData.get('armes') as string || null,
            emplacement: formData.get('fief_communes') as string || null,
            ordonnee: wordOrder,
            privee: isPrivate,
         }
        : {
            armes: formData.get('armes') as string || null,
            ordonnee: wordOrder,
            patronyme: formData.get('patronyme') as string || null,
            fief: formData.get('fief_communes') as string || null,
            siecle: formData.get('siecle') as string || null,
            sceau: sceau,
            pays: formData.get('pays') as string || null,
            departement: formData.get('departement') as string || null,
            region: formData.get('region') as string || null,
            denomination: formData.get('denomination') as string || null
        };

        if (isPrivate == null) {
            ArmorialService.getArmes(tempPayload as ArmesFormInterface).then((response: ArmesResponseInterface) => {
                setArmes(response);  
                setFormState(tempPayload as ArmesFormInterface);
                setLoading(false);
            }).catch((error) => {
                throw error;
            });
        }

        if (isPrivate != null) {
            ArmorialService.searchEmblem(tempPayload).then((response) => {
                if (response !== false) {
                    setBlason(response as PrivateArmesResponseInterface);
                    setFormState(tempPayload as ArmesFormInterface);
                    setLoading(false);
                }
            }).catch((error) => {
                throw error;
            });
        }
    }

    const SubmitFormPrivate = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        const payload = Object.fromEntries(new FormData(e.currentTarget))

        if (await PrivateService.IsCorrect(payload.password.toString())) {
            setIsPrivate(payload.password.toString())
            setArmes(null);
            setFormState(null);            
            ActionModal(0, false, [])
            document.getElementById('private-pwd-state')!.innerHTML = ''
            setPasswordState('Mot de passe correct')
            setLastResults(null)
        }
        else {
            setPasswordState('Mot de passe incorrect')
        }
    }

    const resetForm = () => {
        setFormState(null)
        setArmes(null)
    }

const DisconnectPrivate = () => {
    setBlason(null);
    setFormState(null);
    setSceau(false);
    setModalContent([]);
    setModalIndex(0);
    setModal(false);
    setPasswordState('');
    setIsPrivate(null);
    setLastResults(null);
}

    

return (
    <div id='armesfamilles-container' className={isPrivate ? 'private-container' : 'public-container'}>

        <h3>Recherche d'Armes & de Familles</h3>

        <form id='armes-form' onSubmit={SubmitFormArmes}>
            <fieldset>

                <div id='armes-armes-input'>
                <label id='label1'>Armes</label>
                <input type="text" name="armes" defaultValue={formState?.armes || ''}/>
                <img className='word-order-icon' src={echange} alt="echange" onClick={(e) => setWordOrder(!wordOrder)}/>
                <p className='word-order-text'>Mots {wordOrder ? "ordonnés" : "désordonnés"}</p>
                </div>
                
                <div id="armes-bottom-grid">
                <div id='armes-bottom-grid-left'>
                    <div>
                    <label htmlFor="patronyme" id='label0'>Patronyme / Nom</label>
                    <input type="text" name="patronyme" defaultValue={formState?.patronyme || ''}/>
                    </div>

                    <div>
                    <label htmlFor="fief ou commune" id='label0'>{!isPrivate ? 'Fief / Commune' : 'Emplacement dans édifice'}</label>
                    <input type="text" name="fief_communes" defaultValue={formState?.fief || ''}/>
                    </div>

                    <div>
                    <label htmlFor="siecle" id='label0'>Siècle</label>
                    <select name="siecle" disabled={isPrivate == null ? false : true} defaultValue={formState?.siecle || ''}>
                            <option value=""> </option>                                            
                            {Select.Siecle.map((siecle, index) => <option key={index} value={siecle}>{siecle}</option>)}
                        </select>
                    </div>

                    <div>
                    <label htmlFor="sceau" id='label0'>Sceau</label>
                    <input type="checkbox" name="sceau" disabled={isPrivate == null ? false : true} onChange={() => setSceau(!sceau)} checked={formState?.sceau || false}/>
                    </div>                            
                </div>

                <div id='armes-bottom-grid-right'>
                    <div>
                    <label htmlFor="pays" id='label0'>Pays</label>							
                
                    <select name="pays" disabled={isPrivate == null ? false : true} defaultValue={formState?.pays || ''}>
                        <option value=""></option>
                        {Select.Pays.map((pays, index) => <option key={index} value={pays}>{pays}</option>)}
                    </select>
                    </div>

                    <div>
                    <label htmlFor="departement" id='label0'>Département</label>

                    <select name="departement" disabled={isPrivate == null ? false : true} defaultValue={formState?.departement || ''}>                                            
                        <option value=""> </option>
                        {Select.Departement.map((departement, index) => <option key={index} value={departement}>{departement}</option>)}					
                    </select>
                    </div>

                    <div>
                    <label htmlFor="region" id='label0'>Région</label>

                    <select name="region" disabled={isPrivate == null ? false : true} defaultValue={formState?.region || ''}>                                            
                        <option value=""> </option>
                        {Select.Region.map((region, index) => <option key={index} value={region}>{region}</option>)}					
                    </select>
                    </div>

                    <div>
                    <label htmlFor="denomination" id='label0'>Dénomination</label>
                    <select name="denomination" disabled={isPrivate == null ? false : true} defaultValue={formState?.denomination || ''}>                                            
                        <option value=""></option>
                        {Select.Denomination.map((denomination, index) => <option key={index} value={denomination}>{denomination}</option>)}					
                    </select>
                    </div>                            
                </div>
                </div>


                <button type='submit'>Rechercher</button>
                <button type='reset' onClick={resetForm} className='armes-reset-button'>Réinitialiser la recherche</button>
            </fieldset>
        </form>

        <button id='private-button' onClick={(e) => setPrivateRequest(!privateRequest)}>Privé</button>
        {privateRequest ? 
        <form id='private-request' onSubmit={SubmitFormPrivate}>
            <input name='password' type='password'/>
            {!isPrivate ? <button type='submit'>Connexion</button> : null }
            {isPrivate ? <button onClick={DisconnectPrivate}>Déconnexion</button> : null}

            <p id='private-pwd-state'>{passwordState}</p> 

                      
        </form> 
        : null}

        {armes === null ? null : <p className='answer-results'>Nombre de résultats : {armes.totalCount}</p>}
        {blasons === null ? null : <p className='answer-results'>Nombre de résultats : {blasons.totalCount}</p>}
        
        {loading ? <img src={LoadingImage} alt="Chargement..." className='loading-img'/>: null}
        
               
        <div className='answer-container'>
                { !modal ? null :                 
                <div className='img-modal-back'>
                    <div className='img-modal'>
                        <img className='img-modal-img' src={modalContent[modalIndex]} alt={`Modal content ${modalIndex + 1}`} />
                        <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(0, false, [])}/>
                        {modalIndex === 0 ? null : <img src={left_arrow} alt="left-arrow" className="left-arrow" onClick={() => ActionModal(modalIndex-1, true, modalContent)}/>}
                        {modalIndex === modalContent.length-1 ? null : <img src={right_arrow} alt="right-arrow" className="right-arrow" onClick={() => ActionModal(modalIndex+1, true, modalContent)}/>}                       
                    </div>
                </div>
                }
        
        { blasons && isPrivate ? 
            blasons.data.map((blason, index) => (
                <div key={index} className='results-container'>
                    <div>
                        <ul>
                            <li className='patronyme'><Link to={"./" + blason.id} state={{form: formState, results: blasons}}>{blason.patronyme + ","}</Link></li>
                        </ul>
                        <div className='results-heraldique-container'>
                            <span className='results-armes'>Blasonnement : </span>
                            <p className='blason-list armes-list'>{blason.blasonnement}</p>
                        </div> 
                        <div>
                            <img className='atachement-img' src={minioBlason + blason.refMinio} alt={blason.refMinio || ""}  onClick={() => ActionModal(0, true, [minioBlason + blason.refMinio])}/>
                        </div>
                    </div>
                </div>
            ))
            :
            armes?.data?.map((arme, index) => {
                return (
                    <div className='results-container' key={index}>
                        <ul>
                            <li className='patronyme'><Link to={"./" + arme.id} state={{form: formState, results: armes}}><span>{arme.patronyme}</span></Link>, {arme.fief}</li>
                        </ul>
                        <span className='results-armes'>Armes : </span>
                        <ul className='armes-list'>                                
                            {arme.blasonnement_1 ? <li>{arme.blasonnement_1}</li> : null}
                            {arme.blasonnement_2 ? <li>{arme.blasonnement_2}</li> : null}
                        </ul>
                        <div>
                            {
                                arme.attachments.map((atachement, index) => {
                                    return (
                                        <img key={index} className='atachement-img' src={minioUrl + atachement} alt={atachement} onClick={() => ActionModal(arme.attachments.indexOf(atachement), true, arme.attachments.map(suffixe => minioUrl + suffixe))}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })           
        }

        </div>  
    </div>
    
       
              
    
)

}

