import axios from '../Axios';
import BookFormInterface from '../models/Book/BookForm.model';

const BookService = {

    getBooks: async (payload: BookFormInterface) => {
        try {
            const response = await axios.post('/books-armorial/search', payload);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getBookOne: async (id: any) => {
        try {
            const response = await axios.get(`/books-armorial/${id}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    modifyBook: async (id: string, payload: FormData) => {
        try {
            const response = await axios.put(`/books-armorial/${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getBooksLetter: async (letter: string) => {
        try {
            const response = await axios.get(`/books-armorial/by-letter/${letter}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    addBook: async (payload: FormData) => {
        
        try {
            const response = await axios.post('/books-armorial', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    importBooks: async (payload: any) => {
        try {
            const response = await axios.post('/books-armorial/import', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else if (error.response.status === 201) {
                return true;

            }
            else {
                throw error;
            }
        }
    },

    deleteBook: async (id: string) => {
        try {
            const response = await axios.delete(`/books-armorial/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    }

}

export default BookService;