import axios from '../Axios';
import HeraldiqueFormInterface from '../models/Heraldique/HeraldiqueForm.model';
import HeraldiqueOneInterface from '../models/Heraldique/HeraldiqueOne.model';

const HeraldiqueService = {

    getHeraldique: async (payload: HeraldiqueFormInterface) => {
        try {
            const response = await axios.post('/heraldry-armorial/search', payload);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getHeraldiqueOne: async (id: string) => {
        try {
            const response = await axios.get(`/heraldry-armorial/${id}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    modifyHeraldique: async (payload: HeraldiqueOneInterface) => {
        try {
            const { id, ...rest } = payload;
            const response = await axios.put(`/heraldry-armorial/${id}`, rest, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getHeraldiquesLetter: async (letter: string) => {
        try {
            const response = await axios.get(`/heraldry-armorial/by-letter/${letter}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    addHeraldique: async (payload: HeraldiqueOneInterface) => {
        try {
            
            const { id, ...rest } = payload;
            const response = await axios.post('/heraldry-armorial', rest, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    importHeraldique: async (payload: any) => {
        try {
            const response = await axios.post('/heraldry-armorial/import', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else if (error.response.status === 201) {
                return true;

            }
            else {
                throw error;
            }
        }
    },

    deleteHeraldique: async (id: string) => {
        try {
            const response = await axios.delete(`/heraldry-armorial/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    }
}
export default HeraldiqueService;