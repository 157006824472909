import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../assets/styles/Library.css'
import chevalier from '../assets/images/chevalier.jpg'
import VolumeInterface from '../models/Library/Volume.model'
import { useEffect } from 'react'
import AdminService from '../services/Admin.service'

export default function Volumes() {

  const location = useLocation();
  const navigate = useNavigate();
  const minioUrl = process.env.REACT_APP_MINIO_LIBRARY || ""
  
  let expiration = sessionStorage.getItem('expiration');
  let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);

  useEffect(() => {

    if (!location.state || !location.state.book) {
      navigate('/bibliotheque/oeuvres');
    }
  }, [location, navigate]);

  if (!location.state || !location.state.book) {
    return null;
  }

  const {book} = location.state;

  return (
    <div id='bibli-back'>
    {connect ? <button className='deconnexion' onClick={() => {
      AdminService.logout().then(() => {navigate('/bibliotheques')})
    }}>Déconnexion</button> : null}


        <div className='armorial-navbar navbar-biblio-container'>
            <Link to="/accueil">
              <div className='armorial-navbar-text navbar-biblio'>
                Accueil
              </div>                
            </Link>
            <Link to="/bibliotheques">
              <div className='armorial-navbar-text navbar-biblio'>
                Menu
              </div>                
            </Link>
            {connect ? 
            <div className='armorial-navbar-text navbar-biblio' onClick={() => {
            navigate('/bibliotheques/administration', {state: {user: connect}})
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/bibliotheques/administration' }}>
              <div className='armorial-navbar-text navbar-biblio'>
                Administration
              </div>                
            </Link>
            }
        </div>   

    <h1 className='bibli-title'>{book.titre}</h1>
    {book.commentaire !== book.titre ? <h2>{book.commentaire}</h2> : null}

    {
      book.volumes.map((o: VolumeInterface) => (
        <div key={o.id}>
          {o.description !== o.nomTome ? <h2 className='bibli-desc'>{o.description}</h2> : null}
          <h3 className='bibli-title-tomes' onClick={() =>     window.open(minioUrl + o.refMinio)}>{o.nomTome}</h3>
        </div>
      ))
    }

    <Link to="/bibliotheques/oeuvres" state={{user: connect}}>
      <h3>Retour à la liste des œuvres</h3>
    </Link>
    <img src={chevalier} alt="chevalier" />
  </div>
  )
}
