import { useLocation, useParams, useNavigate, Navigate, Link} from 'react-router-dom';
import '../assets/styles/Book.css';
import BookType from '../types/Book.enum';
import BookForm from '../components/BookForm';
import BookSheet from '../components/BookSheet';
import BookAdmin from '../components/BookAdmin';
import title from '../assets/images/book.png'
import academie from '../assets/images/accueil_logo-aibl.png'
import AdminService from '../services/Admin.service';

export default function Book({type}: {type: BookType}) {

  let expiration = sessionStorage.getItem('expiration');
  let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);
  const results = useLocation()?.state?.results || null;
  const form = useLocation()?.state?.form || null;
  
  var id = useParams().id || null;
  const navigate = useNavigate()

  if (type === BookType.administration && connect === null) {
    return <Navigate to='/administration/connexion' state={{ from: '/livre/administration' }} />;
  }

  return (
    <div id='book-back'>

      {connect ? <button className='deconnexion' onClick={() => AdminService.logout().then(() => navigate('/livre'))}>Déconnexion</button> : null}  

      <img src={title} alt="L'Héraldique et le Livre" id='book-title' />

      <div className={type === BookType.administration ? 'book-container-admin bookform-container' : 'bookform-container'}>

        <div id="book-header">
          <div>
          <Link to="/accueil">
            <div id="label1">
              Accueil
            </div>                
          </Link>
            
            {id ? <Link to="/livre" state={{results: results, form: form}} id='label1'>{" | Retour"}</Link> : null}
            </div>
          <Link to={type === BookType.books ? '/livre/administration' : '/livre'} id="label1">{type === BookType.books ? 'Administration' : 'Recherche'}</Link>
        </div>
      
      {
      type === BookType.administration ? connect ? <BookAdmin /> : <Navigate to={'/administration/connexion'} state={{ from: '/livre' }}/> : id ?  <BookSheet id={id}/> : <BookForm results={results} form={form}/>
      }

      </div>

      <div id="book-footer">
      <Link to="/accueil" className="book-navbar-link">
            Accueil              
        </Link>
        <Link to="http://www.archives.aube.fr/" className="book-navbar-link">
            <img src={academie} alt="Académie des inscriptions et belles lettres"/>          
        </Link>
        <Link to="http://www.mediatheque.grand-troyes.fr/" className="book-navbar-link">
          Médiathèque grand troyes         
        </Link>
      </div>
    </div>
  )
}
