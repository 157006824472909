import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Work from './pages/Work'
import Armorial from './pages/Armorial'
import Volumes from './pages/Volumes'
import Heraldique from './pages/Heraldique'
import Book from './pages/Book'
import AdminConnexion from './pages/AdminConnexion'
import Library from './pages/Library'
import ArmorialType from './types/Armorial.enum'
import HeraldiqueType from './types/Heraldique.enum'
import LibraryAdmin from './pages/LibraryAdmin'
import BookType from './types/Book.enum'
import NavigationSetter from './NavigationSetter'

export default function Router() {

  return (
    <>
      <BrowserRouter>
      <NavigationSetter/>
        <Routes>
            <Route path="*" element={<Navigate to="/accueil" />} />
            <Route path="/accueil" element={<Home />} />
            <Route path="/bibliotheques" element={<Library />} />
            <Route path="/bibliotheques/administration" element={<LibraryAdmin />} />
            <Route path="/bibliotheques/oeuvres" element={<Work />} />
            <Route path="/bibliotheques/oeuvres/tomes" element={<Volumes />} />
            <Route path="/armorial/" element={<Navigate to="/armorial/armes" />} />
            <Route path="/armorial/armes" element={<Armorial type={ArmorialType.armoiries} />} />
            <Route path="/armorial/iconographie" element={<Armorial type={ArmorialType.iconographie} />} />
            <Route path="/armorial/armes/:id" element={<Armorial type={ArmorialType.armoiries}/>} />
            <Route path="/armorial/iconographie/:id" element={<Armorial type={ArmorialType.iconographie}/>} />
            <Route path="/armorial/administration" element={<Armorial type={ArmorialType.administration}/>} />
            <Route path="/heraldique" element={<Heraldique type={HeraldiqueType.heraldique}/>}/>
            <Route path="/heraldique/:id" element={<Heraldique  type={HeraldiqueType.heraldique}/>}/>
            <Route path="/heraldique/administration" element={<Heraldique type={HeraldiqueType.administration}/>} />
            <Route path="/livre" element={<Book type={BookType.books}/>}/>
            <Route path="/livre/:id" element={<Book type={BookType.books}/>}/>
            <Route path="/livre/administration" element={<Book type={BookType.administration}/>}/>
            <Route path="/administration/connexion" element={<AdminConnexion/>} />
            <Route path="/administration" element={<Navigate to="/administration/connexion" />} />  
            </Routes>
      </BrowserRouter>
    </>
  )
}
