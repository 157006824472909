import React, { SyntheticEvent, useEffect, useState } from 'react'
import echange from '../assets/images/echange.jpg'
import Select from '../assets/Select.json'
import HeraldiqueFormInterface from '../models/Heraldique/HeraldiqueForm.model'
import HeraldiqueService from '../services/Heraldique.service'
import { Link } from 'react-router-dom'
import PrivateService from '../services/Private.service'
import HeraldiquesResponseInterface from '../models/Heraldique/HeraldiqueResponse.model'
import LoadingImage from '../assets/images/loading.gif'
import HeraldiqueFormPropsInterface from '../models/Heraldique/HeraldiqueFormProps.model'
import AdminService from '../services/Admin.service'
import AreasInterface from '../models/Admin/Areas.model'
import AreaRequestInterface from '../models/Admin/AreaRequest.model'

export default function HeraldiqueForm({results, form}: HeraldiqueFormPropsInterface) {

    const [wordOrder, setWordOrder] = useState<boolean>(form?.ordonnee || true)
    const [privateRequest, setPrivateRequest] = useState(false)
    const [isPrivate, setIsPrivate] = useState(form?.privee || false)
    const [passwordState, setPasswordState] = useState('')
    const [heraldiques, setHeraldiques] = useState<HeraldiquesResponseInterface | null>(null)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState<HeraldiqueFormInterface | null>(form)
    const [areas, setAreas] = useState<AreasInterface[]>([])
    const [areaRequest, setAreaRequest] = useState<AreaRequestInterface>({area: form?.aires ||[], pays: form?.pays ||[], region: form?.regions ||[], ref: form?.references ||[]})

    useEffect(() => {
        if(results) {
            setHeraldiques(results)
        }
        AdminService.getAreas().then((response) => {
            setAreas(response)
        }).catch((error) => {
            throw error
        })
    }, [setHeraldiques, results, setAreas])    
    
    const SubmitFormHeraldique = (e: SyntheticEvent<HTMLFormElement>) => {
        
        setLoading(true)
        e.preventDefault()
        let formData = new FormData(e.currentTarget)
        
        if (
            formData.get('armes') === "" &&
            formData.get('patronyme') === "" &&
            formData.get('fief') === "" &&
            formData.get('siecle') === "" &&
            formData.get('pays') === "" &&
            formData.get('departement') === "" &&
            formData.get('region') === "" &&
            formData.get('cimiers') === "" &&
            formData.get('prenom') === "" &&
            formData.get('titres') === "" &&
            formData.get('origine') === "" &&
            formData.get('reference') === "" &&
            formData.get('devise') === "" &&
            formData.get('embleme') === "" &&
            formData.get('aireGeo') === "" &&
            formData.get('observations') === ""
        ) {
            return;
        }

        let tempPayload: HeraldiqueFormInterface = {
            armes: (formData.get('armes') as string).replace('\'', '\\\'') || null,
            ordonnee: wordOrder,
            patronyme: (formData.get('patronyme') as string).replace('\'', '\\\'') || null,
            siecle: (formData.get('siecle') as string).replace('\'', '\\\'') || null,
            cimiers: (formData.get('cimiers') as string).replace('\'', '\\\'') || null,
            prenom: (formData.get('prenom') as string).replace('\'', '\\\'') || null,
            titres: (formData.get('titres') as string).replace('\'', '\\\'') || null,
            origine: (formData.get('origine') as string).replace('\'', '\\\'') || null,
            devise: (formData.get('devise') as string).replace('\'', '\\\'') || null,
            embleme: (formData.get('embleme') as string).replace('\'', '\\\'') || null,
            observations: (formData.get('observations') as string).replace('\'', '\\\'') || null,
            privee: isPrivate,
        }

        if (areaRequest.ref.length > 0) {
            tempPayload.references = areaRequest.ref
        } else if (areaRequest.region.length > 0) {
            tempPayload.regions = areaRequest.region
        } else if (areaRequest.pays.length > 0) {
            tempPayload.pays = areaRequest.pays
        } else if (areaRequest.area.length > 0) {
            tempPayload.aires = areaRequest.area
        }

        HeraldiqueService.getHeraldique(tempPayload).then((response: HeraldiquesResponseInterface) => {
            setHeraldiques(response)
            setFormState(tempPayload)
            setLoading(false)
        })
      }

      const SubmitFormPrivate = async () => {
        const passwordInput = document.getElementById('heraldique-private-password') as HTMLInputElement | null;
        if (passwordInput) {
            if (await PrivateService.IsCorrect(passwordInput.value)) {
                setIsPrivate(true)
                setPasswordState('Mot de passe correct')
            }
            else {
                setPasswordState('Mot de passe incorrect')
            }
        }      
    }

    const resetForm = () => {
        setFormState(null)
        setHeraldiques(null)
        const elements = document.getElementsByClassName('area-show');
        Array.from(elements).forEach((element) => {
            element.classList.remove('area-show');
            element.classList.add('area-hidden');
        });
    }

  return (

    <form className={isPrivate ? 'private-heraldique' : 'public-heraldique'} onSubmit={SubmitFormHeraldique}>
                <fieldset>                        
                    <div id="heraldique-bottom-grid">
                        <div id='heraldique-bottom-grid-left'>

                            <div id='heraldique-input'>
                                <label id='label10'>Armes</label>
                                <input type="text" name="armes" defaultValue={formState?.armes || ""}/>
                                <img className='word-order-icon' src={echange} alt="echange" onClick={(e) => setWordOrder(!wordOrder)}/>
                                <p className='word-order-text'>Mots {wordOrder ? "ordonnés" : "désordonnés"}</p>
                            </div>

                            <div>
                                <label htmlFor="Cimiers" id='label10'>Cimiers</label>
                                <input type="text" name="cimiers" defaultValue={formState?.cimiers || ""}/>
                            </div>
                            
                            <div>
                                <label htmlFor="patronyme" id='label0'>Patronyme / Nom</label>
                                <input type="text" name="patronyme" defaultValue={formState?.patronyme || ""}/>
                            </div>
                            
                            <div>
                                <label htmlFor="prenom" id='label0'>Prénom</label>
                                <input type="text" name="prenom" defaultValue={formState?.prenom || ""} />
                            </div>

                            <div>
                                <label htmlFor="titres dignités" id='label0'>Titres / Dignités</label>
                                <input type="text" name="titres" defaultValue={formState?.titres || ""} />
                            </div>

                            <div>
                                <label htmlFor="origines" id='label0'>Origine</label>
                                <input type="text" name="origine" defaultValue={formState?.origine || ""} />
                            </div>

                            <div>
                                <label htmlFor="siecle" id='label0'>Siècle</label>
                                <select name="siecle">
                                            <option defaultValue="" value=""> </option>                                            
                                            {Select.Siecle.map((siecle, index) => <option key={index} value={siecle} selected={formState?.siecle === siecle ? true : false}>{siecle}</option>)}
                                    </select>
                            </div>                         
                        </div>

                        <div id='heraldique-bottom-grid-right'>

                            <div>
                                <label htmlFor="Devise Legende" id='label10'>Devise / Legende</label>
                                <input type="text" name="devise" defaultValue={formState?.devise || ""} />
                            </div>

                            <div>
                                <label htmlFor="Embleme" id='label10'>Emblème</label>
                                <input type="text" name="embleme" defaultValue={formState?.embleme || ""} />
                            </div>  

                            <div>
                                <label htmlFor="observations" id='label0'>Observations</label>
                                <input type="text" name="observations" defaultValue={formState?.observations || ""} />
                            </div>  

                                {areas.length > 0 ? (
                                    <ul className='area-form'>
                                        {areas.map((area, Areaindex) => (
                                            <li key={Areaindex}>
                                                <input  
                                                    className="area-check"
                                                    type="checkbox" 
                                                    checked={areaRequest.area.includes(area.name.toString())}
                                                    onChange={(e) => {
                                                        const paysInput = document.getElementById(`pays-cont-${Areaindex}`) as HTMLInputElement;
                                                        if (paysInput) {
                                                            paysInput.className = e.target.checked ? 'area-show' : 'area-hidden';
                                                            if (e.target.checked) {
                                                                setAreaRequest({...areaRequest, area: [...areaRequest.area, area.name.toString()]});
                                                            } else {
                                                                const updatedArea = areaRequest.area.filter((name) => name !== area.name.toString());
                                                                setAreaRequest({
                                                                    ...areaRequest,
                                                                    area: updatedArea,
                                                                    pays: areaRequest.pays.filter((name) => !area.countries.some((country) => country.name.toString() === name)),
                                                                    region: areaRequest.region.filter((name) => !area.countries.some((country) => country.regions.some((region) => region.name.toString() === name))),
                                                                    ref: areaRequest.ref.filter((name) => !area.countries.some((country) => country.regions.some((region) => region.references.some((ref) => ref.name.toString() === name))))
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={`area-${Areaindex}`} id='label0'>{area.name}</label>

                                                <ul id={`pays-cont-${Areaindex}`} className='area-hidden'>
                                                    {area.countries.map((pays, Paysindex) => (
                                                        <li key={Paysindex}>
                                                            <input 
                                                                className="pays-check"
                                                                type="checkbox"
                                                                checked={areaRequest.pays.includes(pays.name.toString())}
                                                                onChange={(e) => {
                                                                    const regionInput = document.getElementById(`region-cont-${Areaindex}${Paysindex}`) as HTMLInputElement;
                                                                    if (regionInput) {
                                                                        regionInput.className = e.target.checked ? 'area-show' : 'area-hidden';
                                                                        if (e.target.checked) {
                                                                            setAreaRequest({...areaRequest, pays: [...areaRequest.pays, pays.name.toString()]});
                                                                        } else {
                                                                            const updatedPays = areaRequest.pays.filter((name) => name !== pays.name.toString());
                                                                            setAreaRequest({
                                                                                ...areaRequest, 
                                                                                pays: updatedPays,
                                                                                region: areaRequest.region.filter((name) => !pays.regions.some((region) => region.name.toString() === name)),
                                                                                ref: areaRequest.ref.filter((name) => !pays.regions.some((region) => region.references.some((ref) => ref.name.toString() === name)))
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label htmlFor={`pays-${Paysindex}`} id='label0'>{pays.name}</label>

                                                            <ul id={`region-cont-${Areaindex}${Paysindex}`} className='area-hidden'>
                                                                {pays.regions.map((region, Regionindex) => (
                                                                    <li key={Regionindex}>
                                                                    <input 
                                                                        className="region-check"
                                                                        type="checkbox"
                                                                        defaultChecked={formState?.regions?.includes(region.name.toString()) || false}
                                                                        onChange={(e) => {
                                                                            const refInput = document.getElementById(`ref-cont-${Areaindex}${Paysindex}${Regionindex}`) as HTMLInputElement;
                                                                            if (refInput) {
                                                                                refInput.className = e.target.checked ? 'area-show' : 'area-hidden';
                                                                                if (e.target.checked) {
                                                                                    setAreaRequest({...areaRequest, region: [...areaRequest.region, region.name.toString()]});
                                                                                } else {
                                                                                    const updatedRegions = areaRequest.region.filter((name) => name !== region.name.toString());
                                                                                    setAreaRequest({
                                                                                        ...areaRequest, 
                                                                                        region: updatedRegions,
                                                                                        ref: areaRequest.ref.filter((name) => !region.references.some((ref) => ref.name.toString() === name))
                                                                                    });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`region-${Areaindex}${Paysindex}${Regionindex}`} id='label0'>{region.name}</label>

                                                                    <ul id={`ref-cont-${Areaindex}${Paysindex}${Regionindex}`} className='area-hidden'>
                                                                        {region.references.map((ref, Refindex) => (
                                                                            <li key={Refindex} className='ref-list'>
                                                                            <input 
                                                                                className="ref-check"
                                                                                type="checkbox"
                                                                                defaultChecked={formState?.references?.includes(ref.name.toString()) || false}
                                                                                onChange={(e) => {                                                                                    
                                                                                    e.target.checked ? setAreaRequest({...areaRequest, ref: [...areaRequest.ref, ref.name.toString()]}) : setAreaRequest({...areaRequest, ref: areaRequest.ref.filter((name) => name !== ref.name.toString())});
                                                                                }}
                                                                            />
                                                                            <label htmlFor={`ref-${Areaindex}${Paysindex}${Regionindex}${Refindex}`} id='label0'>{ref.name}</label>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}

                                                  
                        </div>
                    </div>


                    <button id="heraldique-button" type='submit'>Rechercher</button>
                    <button type='reset' onClick={resetForm}>Réinitialiser la recherche</button>

                    <button type='button' id='heraldique-private-button' onClick={(e) => setPrivateRequest(!privateRequest)}>Privé</button>
                    {privateRequest ? 
                    <div id='private-request-heraldique'>
                        <input id="heraldique-private-password" name='password' type='password'/>
                        <button type='button' onClick={SubmitFormPrivate}>Connexion</button> 

                        <p id='private-pwd-state'>{passwordState}</p> 

                        {isPrivate ? <button type='button' onClick={() => setIsPrivate(false)}>Déconnexion</button> : null}          
                    </div> 
                    : null}

                    {heraldiques === null ? null : <p className='answer-results-heraldique'>Nombre de résultats : {heraldiques.totalCount}</p>}

                    {loading ? <img src={LoadingImage} alt="Chargement..."/>: null}
                  
                  {heraldiques === null ? 
                  null :        
                      <div className='answer-container'>

                          {heraldiques.data.map((heraldique, index) => {
                              return (
                                  <div className='results-container' key={index}>
                                      <ul>
                                          <li className='patronyme'><Link to={"./" + heraldique.id} state={{form: formState, results: heraldiques}}><span>{heraldique.patronyme}</span></Link> - {heraldique.refBiblio?.substring(0, 100)}</li>
                                      </ul>
                                      <span className='results-armes'>Armes : </span>
                                      <ul className='armes-list'>                                
                                          {heraldique.blasonnement_1 ? <li>{heraldique.blasonnement_1}</li> : null}
                                          {heraldique.blasonnement_2 ? <li>{heraldique.blasonnement_2}</li> : null}
                                      </ul>
                                      {heraldique.cimiers ? 
                                      <div className='results-heraldique-container'>
                                        <span className='results-armes'>Cimiers/Ornements ext. : </span>
                                        <p className='results-heraldique'>{heraldique.cimiers}</p>
                                      </div> 
                                      : null}
                                      {heraldique.devise ? 
                                      <div className='results-heraldique-container'>
                                        <span className='results-armes'>Devise : </span>
                                        <span className='results-heraldique'>{heraldique.devise}</span>
                                      </div>
                                      : null}
                                  </div>
                              )
                          })}

                      </div>
                  }

                </fieldset>                
            </form>

  )
}