import { useEffect, useState } from "react";
import BookService from "../services/Book.service";
import BookOneInterface from "../models/Book/BookOne.model";
import cross from '../assets/images/supprimer.png'
import left_arrow from '../assets/images/left-arrow.svg'
import right_arrow from '../assets/images/right-arrow.svg'

export default function BookSheet({ id }: any) {
  const minioUrl = process.env.REACT_APP_MINIO_BOOK || "";
  const [book, setBook] = useState<BookOneInterface>();
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState([''])    
  const [modalIndex, setModalIndex] = useState(0)

  useEffect(() => {
    BookService.getBookOne(id)
      .then((data: BookOneInterface) => {
        setBook(data);
      })
      .catch((error: any) => {
        throw error;
      });
  }, [id, setBook]);

  const ActionModal = (index: number, modalState: boolean, list: string[]) => {
    setModalContent(list)
    setModalIndex(index)
    setModal(modalState)
  }

  return (
    <>
      {book && (
        <>

          { !modal ? null :                 
            <div className='img-modal-back'>
                <div className='img-modal'>
                    <img className='img-modal-img' src={modalContent[modalIndex]} alt={`Modal content ${modalIndex + 1}`} />
                    <img className='cross-icon' src={cross} alt="cross" onClick={() => ActionModal(0, false, [])}/>
                    {modalIndex === 0 ? null : <img src={left_arrow} alt="left-arrow" className="left-arrow" onClick={() => ActionModal(modalIndex-1, true, modalContent)}/>}
                    {modalIndex === modalContent.length-1 ? null : <img src={right_arrow} alt="right-arrow" className="right-arrow" onClick={() => ActionModal(modalIndex+1, true, modalContent)}/>}                       
                </div>
            </div>
          }
          <h2 id="book-patronyme">{book?.patronyme}</h2>

          {book?.refMinio && (
            <div className="sheet-images-container">
              {book.refMinio.split(";").map((image, index) => (
                <img
                  className="atachement-img"
                  src={minioUrl + image}
                  alt={image}
                  key={image}
                  onClick={() => ActionModal(index, true, book.refMinio!.split(";").map(suffixe => minioUrl + suffixe))}
                />
              ))}
            </div>
          )}

          <div className="show-book show show-armes">
            <div>
              {book?.pays && (
                <div>
                  <span id="label1">Pays : </span>
                  <span className="results-data-books">{book.pays}</span>
                </div>
              )}
              {book?.region && (
                <div>
                  <span id="label1">Région : </span>
                  <span className="results-data-books">{book.region}</span>
                </div>
              )}
              {book?.departement && (
                <div>
                  <span id="label1">Département : </span>
                  <span className="results-data-books">{book.departement}</span>
                </div>
              )}
              {book?.commune && (
                <div>
                  <span id="label1">Commune : </span>
                  <span className="results-data-books">{book.commune}</span>
                </div>
              )}
              {book?.edificeConservation && (
                <div>
                  <span id="label1">Edifice de conservation : </span>
                  <span className="results-data-books">{book.edificeConservation}</span>
                </div>
              )}
              {book?.denomination && (
                <div>
                  <span id="label1">Dénomination : </span>
                  <span className="results-data-books">{book.denomination}</span>
                </div>
              )}
              {book?.categorie && (
                <div>
                  <span id="label1">Catégorie : </span>
                  <span className="results-data-books">{book.categorie}</span>
                </div>
              )}
              {book?.materiau && (
                <div>
                  <span id="label1">Matériau : </span>
                  <span className="results-data-books">{book.materiau}</span>
                </div>
              )}
            </div>
            <div>
              {book?.patronyme && (
                <div>
                  <span id="label1">Famille / Institution : </span>
                  <span className="results-data-books">{book.patronyme}</span>
                </div>
              )}
              {book?.individu && (
                <div>
                  <span id="label1">Individu : </span>
                  <span className="results-data-books">{book.individu}</span>
                </div>
              )}
              {book?.biographie && (
                <div>
                  <span id="label1">Biographie : </span>
                  <span className="results-data-books">{book.biographie}</span>
                </div>
              )}
              {book?.parente && (
                <div>
                  <span id="label1">Parenté : </span>
                  <span className="results-data-books">{book.parente}</span>
                </div>
              )}
              {book?.armes && (
                <div>
                  <span id="label1">Armes : </span>
                  <span className="results-data-books">{book.armes}</span>
                </div>
              )}
              {book?.cimiers && (
                <div>
                  <span id="label1">Ornements extérieurs : </span>
                  <span className="results-data-books">{book.cimiers}</span>
                </div>
              )}
              {book?.embleme && (
                <div>
                  <span id="label1">Emblème : </span>
                  <span className="results-data-books">{book.embleme}</span>
                </div>
              )}
              {book?.devise && (
                <div>
                  <span id="label1">Devise : </span>
                  <span className="results-data-books">{book.devise}</span>
                </div>
              )}
              {book?.notes && (
                <div>
                  <span id="label1">Notes : </span>
                  <span className="results-data-books">{book.notes}</span>
                </div>
              )}
              {book?.bibliographie && (
                <div>
                  <span id="label1">Bibliographie : </span>
                  <span className="results-data-books">{book.bibliographie}</span>
                </div>
              )}
            </div>
            <div>
              {book?.auteurs && (
                <div>
                  <span id="label1">{"Auteur(s)"} : </span>
                  <span className="results-data-books">{book.auteurs}</span>
                </div>
              )}
              {book?.titre && (
                <div>
                  <span id="label1">Titre : </span>
                  <span className="results-data-books">{book.titre}</span>
                </div>
              )}
              {book?.lieuEdition && (
                <div>
                  <span id="label1">Lieu d'édition : </span>
                  <span className="results-data-books">{book.lieuEdition}</span>
                </div>
              )}
              {book?.editeur && (
                <div>
                  <span id="label1">Editeur : </span>
                  <span className="results-data-books">{book.editeur}</span>
                </div>
              )}
              {book?.anneeEdition && (
                <div>
                  <span id="label1">Année d'édition : </span>
                  <span className="results-data-books">{book.anneeEdition}</span>
                </div>
              )}
              {book?.reliure && (
                <div>
                  <span id="label1">Reliure : </span>
                  <span className="results-data-books">{book.reliure}</span>
                </div>
              )}
              {book?.provenance && (
                <div>
                  <span id="label1">Provenance : </span>
                  <span className="results-data-books">{book.provenance}</span>
                </div>
              )}
              {book?.site && (
                <div>
                  <span id="label1">Site : </span>
                  <span className="results-data-books">{book.site}</span>
                </div>
              )}
              {book?.section && (
                <div>
                  <span id="label1">Section : </span>
                  <span className="results-data-books">{book.section}</span>
                </div>
              )}
              {book?.cote && (
                <div>
                  <span id="label1">Cote : </span>
                  <span className="results-data-books">{book.cote}</span>
                </div>
              )}
              {book?.folioEmplacement && (
                <div>
                  <span id="label1">Folio / emplacement : </span>
                  <span className="results-data-books">{book.folioEmplacement}</span>
                </div>
              )}
              {book?.id && (
                <div>
                  <span id="label1">ID : </span>
                  <span className="results-data-books">{book.id}</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}