import axios from '../Axios';
import ArmesFormInterface from '../models/Armorial/Armes/ArmesForm.model';
import IconoFormInterface from '../models/Armorial/Iconographie/IconoForm.model';

const ArmorialService = {

    getArmes: async (payload: ArmesFormInterface) => {
        try {
            const response = await axios.post('/european-armorial/search', payload);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getArmeOne: async (id: string) => {
        try {
            const response = await axios.get(`/european-armorial/${id}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getArmesLetter: async (letter: string) => {
        try {
            const response = await axios.get(`/european-armorial/by-letter/${letter}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    addArmes: async (payload: any) => {
        try {
            const response = await axios.post('/european-armorial', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
        });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },
    
    importArmes: async (payload: any) => {
        try {
            const response = await axios.post('/european-armorial/import', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else if (error.response.status === 201) {
                return true;

            }
            else {
                throw error;
            }
        }
    },
    
    deleteArme: async (id: string) => {
        try {
            const response = await axios.delete(`/european-armorial/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    modifyArme: async (id: string, payload: any) => {
        try {
            const response = await axios.put(`/european-armorial/${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getIcono: async (payload: IconoFormInterface) => {
        try {
            const response = await axios.post('/european-pictures/search', payload);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getIconoOne: async (id: string) => {
        try {
            const response = await axios.get(`/european-pictures/${id}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getIconosLetter: async (letter: string) => {
        try {
            const response = await axios.get(`/european-pictures/by-letter/${letter}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    addIconos: async (payload: FormData) => {
        try {
            
            const response = await axios.post('/european-pictures', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
        });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },
    
    importIconos: async (payload: any) => {
        try {
            const response = await axios.post('/european-pictures/import', payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else if (error.response.status === 201) {
                return true;

            }
            else {
                throw error;
            }
        }
    },
    
    deleteIcono: async (id: string) => {
        try {
            const response = await axios.delete(`/european-pictures/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    modifyIcono: async (id: string, payload: FormData) => {
        try {
            const response = await axios.put(`/european-pictures/${id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access-token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error : any) {
            if (error.response.status === 401 || error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    getEmblem: async (id: string) => {
        try {
            const response = await axios.get(`/european-emblem/${id}`);
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    },

    searchEmblem: async (payload: any) => {
        
        const {privee, ...rest} = payload;
        
        try {
            const response = await axios.post('/european-emblem/search', rest, { 
                headers: {
                    "x-access-password": privee
            }});
            return response.data;
        } catch (error : any) {
            if (error.response.status === 500) {
                return false;
            }
            else {
                throw error;
            }
        }
    }

};
export default ArmorialService;