/* eslint-disable no-restricted-globals */
import { Fragment, JSX, useEffect, useState } from 'react';
import '../assets/styles/Admin.css'
import AdminService from '../services/Admin.service';
import Help from '../assets/images/help.gif';
import LoadingImage from '../assets/images/loading.gif';
import cross from '../assets/images/supprimer.png';
import HeraldiqueService from '../services/Heraldique.service';
import CounterService from '../services/Counter.service';
import HeraldiqueOneInterface from '../models/Heraldique/HeraldiqueOne.model';
import EquivalenceInterface from '../models/Admin/Equivalences.model';
import AreasInterface from '../models/Admin/Areas.model';
import { Link } from 'react-router-dom';

export default function HeraldiqueAdmin() {

    const [connect, setConnect] = useState<any>(null);
    const [active, setActive] = useState<string>('admin');
    const [letter, setLetter] = useState<string>('A');
    const [step, setStep] = useState<number>(1);
    const [correctPass, setcorrectPass] = useState<string | null>(null);
    const [correctMail, setcorrectMail] = useState<string | null>(null);
    const [correctCount, setCorrectCount] = useState<string | null>(null);
    const [correctEquivalence, setCorrectEquivalence] = useState<string | null>(null);
    const [correctHeraldique, setCorrectHeraldique] = useState<string | null>(null);
    const [correctModifyHeraldique, setCorrectModifyHeraldique] = useState<string | null>(null);
    const [correctImportHeraldique, setCorrectImportHeraldique] = useState<string | null>(null);
    const [supprCheck, setSupprCheck] = useState<{area: boolean, pays: boolean, region: boolean, ref: boolean}>({area: false, pays: false, region: false, ref: false});
    const [correctID, setcorrectID] = useState<JSX.Element | null>(null);
    const [areaValue, setAreaValue] = useState<string>("null-no-select");
    const [newAreaValue, setNewAreaValue] = useState<string>("");
    const [paysValue, setPaysValue] = useState<string>("null-no-select");
    const [newPaysValue, setNewPaysValue] = useState<string>("");
    const [regionValue, setRegionValue] = useState<string>("null-no-select");
    const [newRegionValue, setNewRegionValue] = useState<string>("");
    const [refValue, setRefValue] = useState<string>("null-no-select");
    const [newRefValue, setNewRefValue] = useState<string>("");
    const [correctArea, setCorrectArea] = useState<string | null>(null);
    const [id, setID] = useState<string | null>(null);
    const [count, setCount] = useState<{"id" : 0,"count" : string, "resetDate" : string}>({id: 0, count: "", resetDate: ""});
    const [loading, setLoading] = useState<boolean>(false);
    const [areas, setAreas] = useState<AreasInterface[]>([]);
    const [equivalences, setEquivalences] = useState<EquivalenceInterface[]>([]);
    const [heraldiqueLetter, setHeraldiqueLetter] = useState<HeraldiqueOneInterface[]>([]);
    const [IDresponse, setIDresponse] = useState<HeraldiqueOneInterface>({
        id: null,
        patronyme: null,
        prenom: null,
        titresDignites: null,
        origine: null,
        date: null,
        siecle: null,
        fief: null,
        aire: null,
        pays: null,
        region: null,
        alliances: null,
        armoiries: null,
        notes: null,
        leClert: null,
        armorialGenChampagne: null,
        notesArmoriaux: null,
        cimiers: null,
        devise: null,
        embleme: null,
        lambrequins: null,
        support: null,
        observations: null,
        refBiblio: null,
        blasonnement_1: null,
        blasonnement_2: null,
        private: null
      });

    const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

    useEffect(() => {

        AdminService.getInfo().then((response) => {
            setConnect(response);
        }).catch((error) => {
            throw error
        })

        CounterService.getCount().then((response) => {
            setCount(response);
        }).catch((error) => {
            throw error
        })

        AdminService.getEquivalences().then((response) => {
            setEquivalences(response);
        }).catch((error) => {
            throw error
        })

        HeraldiqueService.getHeraldiquesLetter('A').then((response) => {
            setHeraldiqueLetter(response)
        }).catch((error) => {
            throw error
        })

        AdminService.getAreas().then((response) => {
            setAreas(response);
        }).catch((error) => {
            throw error
        })
    }, [])

    const resetCounter = async () => {
        const response = await CounterService.modifyCount()
        if (response) {
            setCorrectCount("Compteur réinitialisé");
            count.count = "0";
            let date = new Date();
            count.resetDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }
    }
    
    const modifyMail = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const payload = {
            mail: data.get('new mail') as string
        }

        if (payload.mail !== "" && payload.mail !== connect?.mail) {
            const response = await AdminService.change(payload);

            if (response === false) {
                setcorrectMail("Une erreur est survenue");
                return;
            }
            else {
                setcorrectMail("Adresse mail modifiée");
                setConnect({...connect, mail: payload.mail});
                }
        }
        (document.getElementsByName("new mail")[0] as HTMLInputElement).value = "";
    }

    const modifyPassword = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (step === 1) {
            const payload = {
                username: data.get('login') as string,
                password: data.get('actual pass') as string
            }

            const response = await AdminService.login(payload);

            if (response === false) {
                setcorrectPass("Mauvais identifiants");
                return;
            }
            else {
                setStep(2);
            }
        }
        else if (step === 2) {
            const payload = {
                password: data.get('new pass') as string
            }

            if (payload.password !== "" && payload.password !== data.get('actual pass') && payload.password === data.get('confirm pass')) {
                const response = await AdminService.change(payload);
                if (response === false) {
                    setcorrectPass("Une erreur est survenue");
                    return;
                }
                else {
                    setcorrectPass("Mot de passe modifié");
                    setStep(1);
                }
            }
        }
    }

    const searchID = async (givedId?: string) => {
        setLoading(true);

        if ((id !== null && id !== "") || (givedId !== null && givedId !== "")) {
            setIDresponse({
                id: null,
                patronyme: null,
                prenom: null,
                titresDignites: null,
                origine: null,
                date: null,
                siecle: null,
                fief: null,
                aire: null,
                pays: null,
                region: null,
                alliances: null,
                armoiries: null,
                notes: null,
                leClert: null,
                armorialGenChampagne: null,
                notesArmoriaux: null,
                cimiers: null,
                devise: null,
                embleme: null,
                lambrequins: null,
                support: null,
                observations: null,
                refBiblio: null,
                blasonnement_1: null,
                blasonnement_2: null,
                private: null
              });

              let response: HeraldiqueOneInterface;

              if (givedId) {
                response = await HeraldiqueService.getHeraldiqueOne(givedId as string);
              }
              else {
                response = await HeraldiqueService.getHeraldiqueOne(id as string);
              }

            

            if (!response) {
                setcorrectID(<span className='admin-label'>Désolé, mais il n'existe pas d'entrée pour cette ID. Vérifiez l'écriture et réessayez. ID saisi: <span className='actual-mail'>{id}</span>.</span>);
            }
            else {
                setIDresponse(response);
                setcorrectID(<span className='admin-label'>Entrée trouvée pour l'ID <span className='actual-mail'>{id}</span> :</span>)
            }
        }
        setLoading(false);
    }

    const addEquivalence = async (e: any) => {

        e.preventDefault();
        const newEquivalence = new FormData(e.currentTarget);

        const response = await AdminService.addEquivalence(newEquivalence);

        if (response === false) {
            setCorrectEquivalence("Une erreur est survenue");
        }
        else {
            setEquivalences([...equivalences, response]);
            setCorrectEquivalence("Equivalence ajoutée");
        }            
        (document.getElementsByName("expression")[0] as HTMLInputElement).value = "";
        (document.getElementsByName("signification")[0] as HTMLInputElement).value = "";
    }

    const deleteEquivalence = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer cette équivalence ?\n\n- Expression 1 : ${equivalences[index].expression}\n\n- Expression 2 : ${equivalences[index].signification}`)) {
            const response = await AdminService.deleteEquivalence(id);

            if (response === false) {
                alert("Une erreur est survenue");
            }
            else {
                setEquivalences(equivalences.filter((equivalence) => equivalence.id !== id));
                alert("Equivalence supprimée");     
                setEquivalences(equivalences.filter((equivalence) => equivalence.id !== id));
            }
        }
        else {
            alert("Suppression annulée");
        }        
    }

    const searchHeraldique = async (letter: string) => {
        const response = await HeraldiqueService.getHeraldiquesLetter(letter);
        if (response) {
            setHeraldiqueLetter(response);
            setLetter(letter);
        }        
    }

    const addHeraldique = async (e: any) => {

        e.preventDefault();

        const formData = new FormData(e.target);
        
        const payload: HeraldiqueOneInterface = {
            id: null,
            patronyme: formData.get('patronyme') as string,
            prenom: formData.get('prenom') as string,
            titresDignites: formData.get('titresDignites') as string,
            origine: formData.get('origine') as string,
            date: formData.get('date') as string,
            siecle: formData.get('siecle') as string,
            fief: formData.get('fief') as string,
            aire: formData.get('aire') as string,
            pays: formData.get('pays') as string,
            region: formData.get('region') as string,
            alliances: formData.get('alliances') as string,
            armoiries: formData.get('armoiries') as string,
            notes: formData.get('notes') as string,
            leClert: formData.get('leClert') as string,
            armorialGenChampagne: formData.get('armorialGenChampagne') as string,
            notesArmoriaux: formData.get('notesArmoriaux') as string,
            cimiers: formData.get('cimiers') as string,
            devise: formData.get('devise') as string,
            embleme: formData.get('embleme') as string,
            lambrequins: formData.get('lambrequins') as string,
            support: formData.get('support') as string,
            observations: formData.get('observations') as string,
            refBiblio: formData.get('refBiblio') as string,
            blasonnement_1: formData.get('blasonnement_1') as string,
            blasonnement_2: formData.get('blasonnement_2') as string,
            private: formData.get('private') === 'on'
        }

        const response = await HeraldiqueService.addHeraldique(payload);
        if (response) {
            setCorrectHeraldique("Héraldique ajoutée");
            if (formData?.get("patronyme")?.toString().startsWith(letter)) {
                setHeraldiqueLetter([...heraldiqueLetter, response]);
            }
            e.target?.reset();
        }
        else {
            setCorrectHeraldique("Une erreur est survenue");
        }
    }

    const importHeraldique = async (form: any) => {
        
        const data = new FormData(form);

        if (data) {
            const response = await HeraldiqueService.importHeraldique(data);
            if (response) {
                searchHeraldique(letter);
                setCorrectImportHeraldique("Héraldiques importées");
            }
            else {
                setCorrectImportHeraldique("Une erreur est survenue");
            }
        }
    }

    const deleteHeraldique = async (id: string, index: number) => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment supprimer l'héraldique suivante ?\n\n- ID : ${heraldiqueLetter[index].id}\n- Patronyme / Nom : ${heraldiqueLetter[index].patronyme}\n\nAttention, cette action est irréversible !`)) {
            const response = await HeraldiqueService.deleteHeraldique(id);
            if (response) {
                setHeraldiqueLetter(heraldiqueLetter.filter((heraldique) => heraldique?.id?.toString() !== id));
                alert("Héraldique supprimée");
            }
            else {
                setCorrectImportHeraldique("Une erreur est survenue, l'héraldique n'a pas été supprimée");
            }
        } 
        else {
            setCorrectImportHeraldique("Suppression annulée");
        }
        
    }

    const modifyArea = async (e: any) => {
        e.preventDefault();

        if(areaValue === "null-no-select") {
            return
        }       

        let good = true;

        if (supprCheck.area) {
            const areaName = areas.find((area) => area.id?.toString() === areaValue)?.name;
            if (confirm(`Voulez-vous vraiment supprimer cette aire géographique ?\n\n- Aire : ${areaName}`)) {
            const response = await AdminService.deleteArea(areaValue);
            if (!response) {
                good = false;
            } 
            } else {
            setCorrectArea("Suppression annulée");
            }
        } else if (supprCheck.pays) {
            const paysName = areas.find((area) => area.id?.toString() === areaValue)?.countries.find((country) => country.id?.toString() === paysValue)?.name;
            if (confirm(`Voulez-vous vraiment supprimer ce pays ?\n\n- Pays : ${paysName}`)) {
            const response = await AdminService.deletePays(paysValue);
            if (!response) {
                good = false;
            }
            } else {
            setCorrectArea("Suppression annulée");
            }
        } else if (supprCheck.region) {
            const regionName = areas.find((area) => area.id?.toString() === areaValue)?.countries.find((country) => country.id?.toString() === paysValue)?.regions.find((region) => region.id?.toString() === regionValue)?.name;
            if (confirm(`Voulez-vous vraiment supprimer cette région ?\n\n- Région : ${regionName}`)) {
            const response = await AdminService.deleteRegion(regionValue);
            if (!response) {
                good = false;
            } 
            } else {
            setCorrectArea("Suppression annulée");
            }
        } else if (supprCheck.ref) {
            const refName = areas.find((area) => area.id?.toString() === areaValue)?.countries.find((country) => country.id?.toString() === paysValue)?.regions.find((region) => region.id?.toString() === regionValue)?.references.find((ref) => ref.id?.toString() === refValue)?.name;
            if (confirm(`Voulez-vous vraiment supprimer cette référence ?\n\n- Référence : ${refName}`)) {
            const response = await AdminService.deleteReference(refValue);
            if (!response) {
                good = false;
            } 
            } else {
            setCorrectArea("Suppression annulée");
            }
        }

        let ids = {
            area: areaValue,
            pays: paysValue,
            region: regionValue,
            ref: refValue
        }

        if (areaValue === "Autre-Ajout" || newAreaValue !== "") {
            const response = await AdminService.postArea({name: newAreaValue});
            if (response) {
            ids.area = response.id?.toString();
            } else {
                good = false;
            }
        }
        if (paysValue === "Autre-Ajout" || newPaysValue !== "") {
            const response = await AdminService.postPays({name: newPaysValue, areaId: parseInt(ids.area)});
            if (response) {
            ids.pays = response.id?.toString();
            } else {
                good = false;
            }
        }
        if (regionValue === "Autre-Ajout" || newRegionValue !== "") {
            const response = await AdminService.postRegion({name: newRegionValue, countryId: parseInt(ids.pays)});
            if (response) {
            ids.region = response.id?.toString();
            } else {
                good = false;
            }
        }
        if (refValue === "Autre-Ajout" || newRefValue !== "") {
            const response = await AdminService.postReference({name: newRefValue, regionId: parseInt(ids.region)});
            if (response) {
            ids.ref = response.id?.toString();
            } else {
                good = false;
            }
        }

        if (good && correctArea !== "Suppression annulée") {
            setCorrectArea("Tout a été modifié avec succès");
            (async () => {
                let response = await AdminService.getAreas();
                setAreas(response);
            })();
        } else {
            setCorrectArea("Une erreur est survenue");
        }
    }

  return (   
    <div className="admin-container">

        <h3 className="admin-title">Espace d'administration</h3>

        <div className="onglets">
            <span className={active === 'admin' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                setActive('admin')
                setCorrectCount("")
                setcorrectPass("")
                setcorrectMail("")
                }}>Administrateur</span>
            <span className={active === 'refs' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('refs')}>Gestion des aires géo, pays, régions et réfs. biblio</span>
            <span className={active === 'equivalence' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('equivalence')}>Gestion des équivalences</span>
            <span className={active === 'ajout' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('ajout')}>Ajout de contenus</span>
            <span className={active === 'gestion' ? 'onglet-active' : 'onglet-inactive'} onClick={() => setActive('gestion')}>Gestion des contenus</span>
            <span className={active === 'recherche' ? 'onglet-active' : 'onglet-inactive'} onClick={() => {
                setActive('recherche')
                setcorrectID(null)
                }}>Recherche ID</span>
            </div>

        <div className="admin-functions-container" id="heraldique-functions-container">
            {active === 'admin' && (
                <div>
                    <form className='admin-form' onSubmit={(e) => modifyPassword(e)}>
                        <fieldset>
                        <legend className='admin-legend'>
                            Identifiants 
                            <img src={Help} alt="help" className="admin-help-img"/>

                            <div>
                                Pour changer de mot de passe :
                                <ul>
                                    <li>Saisissez le login et le mot de passe ACTUELS</li>
                                    <li>Saisissez 2 fois votre nouveau mot de passe</li>                                    
                                </ul>
                            </div>                        
                        </legend>

                        {step === 1 && (
                            <div>
                                <div>
                                    <label className='admin-label'>Pseudonyme :</label>
                                    <input type="text" name="login"/>
                                    <br/><br/>
                                </div>

                                <div>
                                    <label className='admin-label'>Mot de passe actuel : </label>
                                    <input type="password" name="actual pass"/>
                                    <br/><br/>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div>
                                <div>
                                    <label className='admin-label'>Nouveau mot de passe :</label>
                                    <input type="text" name="new pass"/>
                                    <br/><br/>
                                </div>

                                <div>
                                    <label className='admin-label'>Saisissez de nouveau : </label>
                                    <input type="text" name="confirm pass"/>
                                    <br/><br/>
                                </div>
                            </div>
                        )}

                        <button type='submit'>Valider</button>
                        {correctPass ? <span id='label10'>{correctPass}</span> : null}

                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Compteur de visites 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                Le nombre indique le nombre de visites effectuées sur le site la date indiquée<br/>
			                    En cliquant sur "Réinitialiser le compteur", le compte sera repris de zéro et <br/>la date remplacée par la date courante.
                                </div> 
                            </legend>

                            <div>
                                <span className='admin-label'>Le site a été visité  </span>                                
                                <span className='actual-mail'>{count.count}</span>
                                <span className='admin-label'> fois depuis le </span>
                                <span className='actual-mail'>{parseInt(count.resetDate.split('-')[2]) + ' ' + month[parseInt(count.resetDate.split('-')[1])-1] + ' ' + count.resetDate.split('-')[0]}</span>
                            </div> 

                            <button type="button" id="reset-count-button" onClick={resetCounter}>Réinitialiser le compteur</button>
                            {correctCount ? <span id='label10'>{correctCount}</span> : null}

                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space' onSubmit={(e) => modifyMail(e)}>
                        <fieldset>
                            <legend className='admin-legend'>
                                Modification de l'adresse mail 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                Entrez la nouvelle adresse mail puis cliquez sur "changer"
                                </div> 
                            </legend>

                            <div>
                                <span className='admin-label'>L'adresse mail administrateur configurée est : </span>
                                <span className='actual-mail'>{connect?.mail}</span>
                                <br/><br/>
                            </div>

                            <div>
                                <label className='admin-label'>Nouvelle adresse mail : </label>
                                <input type="email" name="new mail"/>
                                <br/><br/>
                            </div>

                            <button type='submit'>Changer</button>
                            {correctMail ? <span id='label10'>{correctMail}</span> : null}

                        </fieldset>
                    </form>
                </div>
            )}
            {active === 'refs' && (
                <div>
                    <form className='admin-form admin-form-space admin-form-table'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Modification des contenus
                            </legend>

                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Aire géographique</th>
                                            <th>Pays</th>
                                            <th>Région</th>
                                            <th>Référence</th>
                                            <th>Privée</th>
                                        </tr>
                                    </thead>
                                    <tbody>
  {areas.length > 0 ? (
    areas.map((area, indexArea) => {
      const isSingleArea = areas.length === 1;
      const hasCountries = area.countries.length > 0;
      
      const areaRowSpan = hasCountries
        ? area.countries.reduce(
            (acc, pays) =>
              acc +
              (pays.regions.reduce(
                (acc, region) => acc + (region.references.length || 1),
                0
              ) || 1),
            0
          ) || 1
        : 1;

      return hasCountries ? (
        area.countries.map((pays, indexPays) => {
          const hasRegions = pays.regions.length > 0;
          const paysRowSpan = hasRegions
            ? pays.regions.reduce(
                (acc, region) => acc + (region.references.length || 1),
                0
              )
            : 1;

          return hasRegions ? (
            pays.regions.map((region, indexRegion) => {
              return (region.references.length > 0
                ? region.references
                : [null]
              ).map((reference, indexReference) => (
                <tr key={`region-R${indexArea}${indexPays}${indexRegion}${indexReference}`}>
                  {indexPays === 0 && indexRegion === 0 && indexReference === 0 && (
                    <td rowSpan={areaRowSpan}>{isSingleArea ? "" : area.name}</td>
                  )}
                  {indexRegion === 0 && indexReference === 0 && (
                    <td rowSpan={paysRowSpan}>{pays.name || ""}</td>
                  )}
                  {indexReference === 0 && (
                    <td rowSpan={region.references.length || 1}>{region.name || ""}</td>
                  )}
                  <td>{reference?.name || ""}</td>
                  <td>
                    {reference ? (
                      <input
                        type="checkbox"
                        defaultChecked={reference.private || false}
                        onChange={() => AdminService.patchReference(reference.id)}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ));
            })
          ) : (
            <tr key={`region-R${indexArea}${indexPays}`}>
              {indexPays === 0 && <td rowSpan={areaRowSpan}>{isSingleArea ? "" : area.name}</td>}
              <td>{pays.name}</td>
              <td colSpan={3}></td>
            </tr>
          );
        })
      ) : (
        <tr key={`area-${indexArea}`}>
          <td>{isSingleArea ? "" : area.name}</td>
          <td colSpan={4}></td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={5}>Aucune donnée disponible</td>
    </tr>
  )}
</tbody>
                                </table>
                            </div>
                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space' onSubmit={(e) => modifyArea(e)}>
                        <fieldset>
                            <legend className='admin-legend'>Gérer le contenu</legend>
                            
                            <div className="admin-form-areas-container">
                                <div className={areaValue === "Autre-Ajout" ? 'admin-form-areas-input-container-add' : 'admin-form-areas-input-container'}>
                                    <label htmlFor="areas" className='results-armes'>Aire géographique :</label>
                                    <div>
                                        <select defaultValue="null-no-select" name="areas" onChange={(e) => {
                                            setAreaValue(e.currentTarget.value);
                                            setNewAreaValue("");
                                        }} disabled={supprCheck.ref || supprCheck.pays || supprCheck.region}>
                                            <option value={"null-no-select"}>Sélectionner une aire géographique</option>
                                            {areas.map((area) => (
                                                <option key={area.id} value={area.id?.toString()}>  
                                                    {area.name}
                                                </option>
                                            ))}
                                            <option value="Autre-Ajout">Ajouter</option>
                                        </select>
                                        <input type="checkbox" disabled={ (supprCheck.pays || supprCheck.region || supprCheck.ref) || areaValue  === "null-no-select" || areaValue === "Autre-Ajout"} onChange={(e) => setSupprCheck({area: e.target.checked, pays: false, region: false, ref: false})}/>
                                        <label htmlFor="delete-areas" className='results-armes'>Supprimer</label>
                                    </div>
                                    {areaValue === "Autre-Ajout" ? <input type="text" name="areas-text" onChange={(e) => setNewAreaValue(e.currentTarget.value)}/> : null}
                                </div>

                                <div className={paysValue === "Autre-Ajout" ? 'admin-form-areas-input-container-add' : 'admin-form-areas-input-container'}>
                                    <label htmlFor="pays" className='results-armes'>Pays :</label>
                                    <div>
                                        <select defaultValue="null-no-select" name="pays" onChange={(e) => {
                                            setPaysValue(e.currentTarget.value)
                                            setNewPaysValue("")
                                        }} disabled={(supprCheck.area || supprCheck.region || supprCheck.ref) || areaValue === "null-no-select" || (areaValue === "Autre-Ajout" && newAreaValue === "")}>
                                            <option value="null-no-select">Sélectionner un Pays</option>
                                            {areas
                                                .find((area) => area.id?.toString() === areaValue)
                                                ?.countries.map((country) => (
                                                    <option key={country.id} value={country.id?.toString()}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            <option value="Autre-Ajout">Ajouter</option>
                                        </select>
                                        <input type="checkbox" disabled={paysValue === "Autre-Ajout" || supprCheck.area || supprCheck.region || supprCheck.ref || areaValue === "null-no-select" || paysValue === "null-no-select" || (areaValue === "Autre-Ajout" && newAreaValue === "")} onChange={(e) => {
                                            setSupprCheck({area: false, pays: e.target.checked, region: false, ref: false})
                                        }}/>
                                        <label htmlFor="delete-areas" className='results-armes'>Supprimer</label>
                                    </div>
                                    {paysValue === "Autre-Ajout" ? <input type="text" name="pays-text" onChange={(e) => setNewPaysValue(e.currentTarget.value)}/> : null}
                                </div>

                                <div className={regionValue === "Autre-Ajout" ? 'admin-form-areas-input-container-add' : 'admin-form-areas-input-container'}>
                                    <label htmlFor="region" className='results-armes'>Région :</label>
                                    <div>
                                        <select defaultValue="null-no-select" name="region" onChange={(e) => {
                                            setRegionValue(e.currentTarget.value)
                                            setNewRegionValue("")
                                        }} disabled={supprCheck.area || supprCheck.pays || supprCheck.ref || paysValue === "null-no-select" || areaValue === "null-no-select" || (paysValue === "Autre-Ajout" && newPaysValue === "")}>
                                            <option value="null-no-select">Sélectionner une Région</option>
                                            <option value="Général">Général</option>
                                            {areas
                                                .find((area) => area.id?.toString() === areaValue)
                                                ?.countries.find((country) => country.id?.toString() === paysValue)
                                                ?.regions.map((region) => (
                                                    <option key={region.id} value={region.id?.toString()}>
                                                        {region.name}
                                                    </option>
                                                ))}
                                            <option value="Autre-Ajout">Ajouter</option>
                                        </select>
                                        <input type="checkbox" disabled={regionValue === "Autre-Ajout" || supprCheck.area || supprCheck.pays || supprCheck.ref || paysValue === "null-no-select" || areaValue === "null-no-select" || regionValue === "null-no-select" || (paysValue === "Autre-Ajout" && newPaysValue === "")} onChange={(e) => {
                                            setSupprCheck({area: false, pays: false, region: e.target.checked, ref: false})
                                        }}/>
                                        <label htmlFor="delete-areas" className='results-armes'>Supprimer</label>
                                    </div>
                                    {regionValue === "Autre-Ajout" ? <input type="text" name="region-text" onChange={(e) => setNewRegionValue(e.currentTarget.value)}/> : null}
                                </div>

                                <div className={refValue === "Autre-Ajout" ? 'admin-form-areas-input-container-add' : 'admin-form-areas-input-container'}>
                                    <label htmlFor="ref" className='results-armes'>Référence :</label>
                                    <div>
                                        <select defaultValue="null-no-select" name="ref" onChange={(e) => {
                                            setRefValue(e.currentTarget.value)
                                            setNewRefValue("")
                                        }} disabled={supprCheck.area || supprCheck.pays || supprCheck.region || regionValue === "null-no-select" || paysValue === "null-no-select" || areaValue === "null-no-select" || (regionValue === "Autre-Ajout" && newRegionValue === "")}>
                                            <option value="null-no-select">Sélectionner une Référence</option>
                                            {areas
                                                .find((area) => area.id?.toString() === areaValue)
                                                ?.countries.find((country) => country.id?.toString() === paysValue)
                                                ?.regions.find((region) => region.id?.toString() === regionValue)
                                                ?.references.map((ref) => (
                                                    <option key={ref.id} value={ref.id?.toString()}>
                                                        {ref.name}
                                                    </option>
                                                ))}
                                            <option value="Autre-Ajout">Ajouter</option>
                                        </select>
                                        <input type="checkbox" disabled={refValue === "Autre-Ajout" || supprCheck.area || supprCheck.pays || supprCheck.region || regionValue === "null-no-select" || paysValue === "null-no-select" || areaValue === "null-no-select" || refValue === "null-no-select" || (regionValue === "Autre-Ajout" && newRegionValue === "")}  onChange={(e) => {
                                            setSupprCheck({area: false, pays: false, region: false, ref: e.target.checked})
                                        }}/>
                                        <label htmlFor="delete-areas" className='results-armes'>Supprimer</label>
                                    </div>
                                    {refValue === "Autre-Ajout" ? <input type="text" name="ref-text" onChange={(e) => setNewRefValue(e.currentTarget.value)}/> : null}
                                </div>

                                <button type='submit' className='admin-modify-button'>Modifier</button>
                                {correctArea ? <span id='label10'>{correctArea}</span> : null}
                            </div>

                                

                        </fieldset>
                    </form>
                </div>
            )}
            {active === 'equivalence' && (
                <div>
                    <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addEquivalence(e)}>
                        <fieldset>
                            <legend className='admin-legend'>
                                Tableau des équivalences                                
                            </legend>
                            <div className="table-wrapper-equ">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Expression 1</th>
                                            <th>Expression 2</th>
                                            <th id="admin-form-delete-col">Supprimer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    equivalences?.map((equivalence, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{equivalence.expression}</td>
                                                    <td>{equivalence.signification}</td>
                                                    <td><img src={cross} alt="supprimer" onClick={() => deleteEquivalence(equivalence.id, index)}/></td>
                                                </tr>
                                            )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className='admin-equ-add'>
                                <div>
                                    <input type="text" name='expression' placeholder='Expression 1' required/>
                                    <input type="text" name='signification' placeholder='Expression 2' required/>
                                    <button type='submit'>Ajouter l'équivalence</button>
                                </div>
                                {correctEquivalence ? <span id='label10'>{correctEquivalence}</span> : null}
                            </div>

                        </fieldset>
                    </form>
                </div>
            )}
            {active === 'ajout' && (
                <div>

                <form className='admin-form admin-form-space' id='admin-excel-form'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Mise à jour de la base par le fichier Excel 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                    Pour insérer des contenus via un fichier Excel:
                                    <ul>
                                        <li>Cliquez sur "Générer..." et enregistrez le fichier</li>
                                        <li>Ouvrez le fichier précédemment téléchargé et remplissez-le</li>
                                        <li>Cliquez sur "Ajouter un fichier"/"Parcourir" (en fonction du navigateur) et sélectionnez le fichier rempli</li>
                                        <li>Cliquez sur "Ajouter"</li>
                                    </ul>
                                </div> 
                            </legend>
                            
                            <div className='admin-form-excel-container'>
                                <span className='admin-form-excel'>Familles : </span> 
                                <input type="file" name="file" accept=".xls, .xlsx"/>
                                <button type='button' onClick={() => importHeraldique(document.getElementById('admin-excel-form'))}>Ajouter</button>
                                <Link to={process.env.REACT_APP_MINIO_EXCELS + "heraldry_armorial.xlsx"} className='admin-form-excel-link'>Générer un ficher Excel type</Link>
                            </div> 
                            {correctImportHeraldique ? <span id='label10'>{correctImportHeraldique}</span> : null}
                        </fieldset>
                    </form>

                    <form className='admin-form admin-form-space admin-form-table' onSubmit={(e) => addHeraldique(e)}>
                        <fieldset>
                            <legend className='admin-legend'>Ajout manuel</legend>

                            <div className='admin-search-results-container'>

                                <div className="show show-armes">
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><input type='text' className='results-data-armes' name='patronyme' required/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Prénom : </span><input type='text' className='results-data-armes' name='prenom'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Titres & dignités : </span><input type='text' className='results-data-armes' name='titresDignites'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Origine : </span><input type='text' className='results-data-armes' name='origine'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Date : </span><input type='text' className='results-data-armes' name='date'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Siècle : </span><input type='text' className='results-data-armes' name='siecle'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Fief : </span><input type='text' className='results-data-armes' name='fief'/></div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Aire : </span>
                                        <select name="aire" className='results-data-armes' defaultValue={IDresponse.aire || ""} onChange={(e) => setIDresponse({...IDresponse, aire: e.target.value})}>
                                            <option value="">Sélectionner une aire géographique</option>
                                            {areas.map((area) => (
                                                <option key={area.id} value={area.name}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Pays : </span>
                                        <select name="pays" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, pays: e.target.value})}>
                                            <option value="">Sélectionner un pays</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.map((country) => (
                                                <option key={country.id} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Région : </span>
                                        <select name="region" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, region: e.target.value})}>
                                            <option value="">Sélectionner une région</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.find((country) => country.name === IDresponse.pays)?.regions.map((region) => (
                                                <option key={region.id} value={region.name}>
                                                    {region.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Alliances : </span><input type='text' className='results-data-armes' name='alliances'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Armoiries : </span><input type='text' className='results-data-armes' name='armoiries'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes : </span><input type='text' className='results-data-armes' name='notes'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Le Clert : </span><input type='text' className='results-data-armes' name='leClert'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Armorial Gen Champagne : </span><input type='text' className='results-data-armes' name='armorialGenChampagne'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes armoriaux : </span><input type='text' className='results-data-armes' name='notesArmoriaux'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' className='results-data-armes' name='cimiers'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Devise : </span><input type='text' className='results-data-armes' name='devise'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Emblème : </span><input type='text' className='results-data-armes' name='embleme'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Lambrequins : </span><input type='text' className='results-data-armes' name='lambrequins'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Support : </span><input type='text' className='results-data-armes' name='support'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Observations : </span><input type='text' className='results-data-armes' name='observations'/></div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Réf. Biblio : </span>
                                        <select name="refBiblio" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, refBiblio: e.target.value})}>
                                            <option value="">Sélectionner une réf. biblio</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.find((country) => country.name === IDresponse.pays)?.regions.find((region) => region.name === IDresponse.region)?.references.map((ref) => (
                                                <option key={ref.id} value={ref.name}>
                                                    {ref.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea className='results-data-armes' name='blasonnement_1'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea className='results-data-armes' name='blasonnement_2'/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Privée : </span><input type='checkbox' className='results-data-armes' name='private'/></div>
                                </div>
                                <button type='submit' className='admin-modify-button'>Ajouter</button>
                                {correctHeraldique ? <span id='label10'>{correctHeraldique}</span> : null}
                            </div>

                        </fieldset>
                    </form>
                
            </div>
            )}
            {active === 'gestion' && (
            <div>

                    <form className='admin-form admin-form-space admin-form-table'>
                        <fieldset>
                            <legend className='admin-legend'>
                                Modification des contenus 
                                <img src={Help} alt="help" className="admin-help-img"/>
                                
                                <div>
                                    <ul>
                                        <li> Le tableau affiche les contenus de la base, organisés par ordre alphabétique.<br/>
                                        Pour atteindre la lettre souhaitée, cliquez sur l'une des lettres au-dessus du tableau.<br/>
                                        </li>
                                        <li>
                                        <img src={cross} alt="supprimer"/> (dans la colonne de gauche) permet de supprimer le contenu correpondant.
                                        </li>
                                        <li>
                                            Afin de modifier l'entrée il suffit de cliquer sur le patronyme / nom correspondant.<br/>
                                            Cela ouvrira la recherche par ID avec l'ID correspondant à l'entrée, vous pourrez alors modifier les informations.
                                        </li>
                                    </ul>
                                </div>                               
                            </legend>

                            <div>

                                {alphabet.map((letter, index) => {
                                    return (
                                        <>
                                        <span key={"Alph"+index} className='admin-search-letter' onClick={() => searchHeraldique(letter)}>{letter}</span>
                                        <span key={"Blph"+index} className='admin-search-letter-dash'>{alphabet.length !== index+1 ? " - " : null }</span>
                                        </>
                                    )
                                })}
                                
                            </div>

                            <div className="table-wrapper">                            
                                <table>
                                    <thead>
                                        <tr key="header-row">
                                            <th id="admin-form-delete-col">Supprimer</th>                                        
                                            <th>Patronyme / Nom</th>
                                            <th>Prénom</th>
                                            <th>Titres & dignités</th>
                                            <th>Origine</th>
                                            <th>Alliances</th>
                                            <th>Date</th>
                                            <th>Siècle</th>
                                            <th>Fief</th>
                                            <th>Aire</th>
                                            <th>Pays</th>
                                            <th>Région</th>
                                            <th>Le Clert</th>
                                            <th>Armoiries</th>
                                            <th>Armorial Gen Champagne</th>
                                            <th>Notes armoriaux</th>
                                            <th>Notes</th>
                                            <th>Cimiers</th>
                                            <th>Devise</th>
                                            <th>Emblème</th>
                                            <th>Lambrequins</th>
                                            <th>Support</th>
                                            <th>Observations</th>
                                            <th>Réf. Biblio</th>
                                            <th>Blasonnement 1</th>
                                            <th>Blasonnement 2</th>
                                            <th>Privée</th>                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    heraldiqueLetter?.map((heraldique, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td key={"C"+index}><img src={cross} alt="supprimer" onClick={() => deleteHeraldique(heraldique?.id?.toString() || "", index)}/></td>
                                                    <td key={"B"+index} className='admin-patronyme' onClick={() => {
                                                        setID(heraldique.id?.toString() || "");
                                                        searchID(heraldique.id?.toString() || "");
                                                        setActive('recherche');
                                                    }}>{heraldique.patronyme}</td>
                                                    <td key={"A"+index}>{heraldique.prenom}</td>
                                                    <td key={"D"+index}>{heraldique.titresDignites}</td>
                                                    <td key={"E"+index}>{heraldique.origine}</td>
                                                    <td key={"F"+index}>{heraldique.alliances}</td>
                                                    <td key={"G"+index}>{heraldique.date}</td>
                                                    <td key={"H"+index}>{heraldique.siecle}</td>
                                                    <td key={"I"+index}>{heraldique.fief}</td>
                                                    <td key={"J"+index}>{heraldique.aire}</td>
                                                    <td key={"K"+index}>{heraldique.pays}</td>
                                                    <td key={"L"+index}>{heraldique.region}</td>
                                                    <td key={"N"+index}>{heraldique.leClert}</td>
                                                    <td key={"O"+index}>{heraldique.armoiries}</td>
                                                    <td key={"P"+index}>{heraldique.armorialGenChampagne}</td>
                                                    <td key={"Q"+index}>{heraldique.notesArmoriaux}</td>
                                                    <td key={"R"+index}>{heraldique.notes}</td>
                                                    <td key={"S"+index}>{heraldique.cimiers}</td>
                                                    <td key={"T"+index}>{heraldique.devise}</td>
                                                    <td key={"U"+index}>{heraldique.embleme}</td>
                                                    <td key={"V"+index}>{heraldique.lambrequins}</td>
                                                    <td key={"W"+index}>{heraldique.support}</td>
                                                    <td key={"X"+index}>{heraldique.observations}</td>
                                                    <td key={"Y"+index}>{heraldique.refBiblio}</td>
                                                    <td key={"Z"+index}>{heraldique.blasonnement_1}</td>
                                                    <td key={"AA"+index}>{heraldique.blasonnement_2}</td>
                                                    <td key={"AB"+index}>{heraldique.private ? "Privé" : "Publique"}</td>
                                                </tr>
                                            )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    </form>                   
                
            </div>
            )}
            {active === 'recherche' && (
            <div>
                <form className='admin-form' onSubmit={(e) => addHeraldique(e)}>
                    <fieldset>
                        <legend className='admin-legend'>Recherche par ID</legend>

                        <div>
                            <label className='admin-label'>Saisir un ID : </label>
                            <input type="number" name="id" min={1} onChange={(e) => setID(e.target.value)}/>
                            <br/><br/>
                        </div>

                        <button type='button' onClick={() => searchID()}>Rechercher</button>
                        {correctID ? correctID  : null}
                        {loading ? <img src={LoadingImage} alt="Chargement..."/>: null}

                        {IDresponse.id !== null ? 

                            <div className='admin-search-results-container'> 

                                <h3 className='admin-search-results-title'>{IDresponse.patronyme}</h3>

                                <div className="show show-armes">
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Patronyme / Nom : </span><input type='text' name='patronyme' className='results-data-armes' defaultValue={IDresponse.patronyme || ""} onChange={(e) => setIDresponse({...IDresponse, patronyme: e.target.value})} required/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Prénom : </span><input type='text' name='prenom' className='results-data-armes' defaultValue={IDresponse.prenom || ""} onChange={(e) => setIDresponse({...IDresponse, prenom: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Titres & dignités : </span><input type='text' name='titresDignites' className='results-data-armes' defaultValue={IDresponse.titresDignites || ""} onChange={(e) => setIDresponse({...IDresponse, titresDignites: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Origine : </span><input type='text' name='origine' className='results-data-armes' defaultValue={IDresponse.origine || ""} onChange={(e) => setIDresponse({...IDresponse, origine: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Alliances : </span><input type='text' name='alliances' className='results-data-armes' defaultValue={IDresponse.alliances || ""} onChange={(e) => setIDresponse({...IDresponse, alliances: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Date : </span><input type='text' name='date' className='results-data-armes' defaultValue={IDresponse.date || ""} onChange={(e) => setIDresponse({...IDresponse, date: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Siècle : </span><input type='text' name='siecle' className='results-data-armes' defaultValue={IDresponse.siecle || ""} onChange={(e) => setIDresponse({...IDresponse, siecle: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Fief : </span><input type='text' name='fief' className='results-data-armes' defaultValue={IDresponse.fief || ""} onChange={(e) => setIDresponse({...IDresponse, fief: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Aire : </span>
                                        <select name="aire" className='results-data-armes' defaultValue={IDresponse.aire || ""} onChange={(e) => setIDresponse({...IDresponse, aire: e.target.value})}>
                                            <option value="">Sélectionner une aire géographique</option>
                                            {areas.map((area) => (
                                                <option key={area.id} value={area.name}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Pays : </span>
                                        <select name="pays" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, pays: e.target.value})}>
                                            <option value="">Sélectionner un pays</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.map((country) => (
                                                <option key={country.id} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Région : </span>
                                        <select name="region" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, region: e.target.value})}>
                                            <option value="">Sélectionner une région</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.find((country) => country.name === IDresponse.pays)?.regions.map((region) => (
                                                <option key={region.id} value={region.name}>
                                                    {region.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Le Clert : </span><input type='text' name='leClert' className='results-data-armes' defaultValue={IDresponse.leClert || ""} onChange={(e) => setIDresponse({...IDresponse, leClert: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Armoiries : </span><input type='text' name='armoiries' className='results-data-armes' defaultValue={IDresponse.armoiries || ""} onChange={(e) => setIDresponse({...IDresponse, armoiries: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Armorial Gen Champagne : </span><input type='text' name='armorialGenChampagne' className='results-data-armes' defaultValue={IDresponse.armorialGenChampagne || ""} onChange={(e) => setIDresponse({...IDresponse, armorialGenChampagne: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes armoriaux : </span><input type='text' name='notesArmoriaux' className='results-data-armes' defaultValue={IDresponse.notesArmoriaux || ""} onChange={(e) => setIDresponse({...IDresponse, notesArmoriaux: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Notes : </span><input type='text' name='notes' className='results-data-armes' defaultValue={IDresponse.notes || ""} onChange={(e) => setIDresponse({...IDresponse, notes: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Cimiers : </span><input type='text' name='cimiers' className='results-data-armes' defaultValue={IDresponse.cimiers || ""} onChange={(e) => setIDresponse({...IDresponse, cimiers: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Devise : </span><input type='text' name='devise' className='results-data-armes' defaultValue={IDresponse.devise || ""} onChange={(e) => setIDresponse({...IDresponse, devise: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Emblème : </span><input type='text' name='embleme' className='results-data-armes' defaultValue={IDresponse.embleme || ""} onChange={(e) => setIDresponse({...IDresponse, embleme: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Lambrequins : </span><input type='text' name='lambrequins' className='results-data-armes' defaultValue={IDresponse.lambrequins || ""} onChange={(e) => setIDresponse({...IDresponse, lambrequins: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Support : </span><input type='text' name='support' className='results-data-armes' defaultValue={IDresponse.support || ""} onChange={(e) => setIDresponse({...IDresponse, support: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Observations : </span><input type='text' name='observations' className='results-data-armes' defaultValue={IDresponse.observations || ""} onChange={(e) => setIDresponse({...IDresponse, observations: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'>
                                        <span className='results-armes'>Réf. Biblio : </span>
                                        <select name="refBiblio" className='results-data-armes' onChange={(e) => setIDresponse({...IDresponse, refBiblio: e.target.value})}>
                                            <option value="">Sélectionner une réf. biblio</option>
                                            {areas.find((area) => area.name === IDresponse.aire)?.countries.find((country) => country.name === IDresponse.pays)?.regions.find((region) => region.name === IDresponse.region)?.references.map((ref) => (
                                                <option key={ref.id} value={ref.name}>
                                                    {ref.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Blasonnement 1 : </span><textarea name='blasonnement_1' className='results-data-armes' defaultValue={IDresponse.blasonnement_1 || ""} onChange={(e) => setIDresponse({...IDresponse, blasonnement_1: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Blasonnement 2 : </span><textarea name='blasonnement_2' className='results-data-armes' defaultValue={IDresponse.blasonnement_2 || ""} onChange={(e) => setIDresponse({...IDresponse, blasonnement_2: e.target.value})}/></div>
                                    <div className='results-heraldique-container-sheet'><span className='results-armes'>Privée : </span><input type='checkbox' name='private' className='results-data-armes' defaultChecked={IDresponse.private || false} onChange={(e) => setIDresponse({...IDresponse, private: e.target.checked})}/></div>
                                </div>
                                {correctModifyHeraldique ? <span id='label10'>{correctModifyHeraldique}</span> : null}
                                <button type='submit' className='admin-modify-button' onClick={() => {
                                    HeraldiqueService.modifyHeraldique(IDresponse).then((response) => {
                                        setCorrectModifyHeraldique("Héraldique modifiée.")
                                    }).catch((error) => {
                                        setCorrectModifyHeraldique("Une erreur est survenue.")
                                    })
                                }}>Modifier</button>
                            </div>
                        : null}

                    </fieldset>
                </form>
            </div>
            )}
        </div>
    </div>
  )
}
