import { Link, useNavigate } from 'react-router-dom'
import AdminService from '../services/Admin.service';

export default function Library() {

      
  let expiration = sessionStorage.getItem('expiration');
  let connect = expiration && Number(expiration) > Math.floor(Date.now() / 1000);
  const navigate = useNavigate();
    
  return (
    <div id='bibli-back'>
      {connect ? <button className='deconnexion' onClick={() => {
          AdminService.logout().then(() => {navigate('/bibliotheques')})
          }}>Déconnexion</button> : null}
      <h1 className='bibli-title'>Bibliothèques</h1>

      <div className='armorial-navbar navbar-biblio-container'>
            <Link to="/accueil">
              <div className='armorial-navbar-text navbar-biblio'>
                Accueil
              </div>                
            </Link>
            <Link to="/bibliotheques">
              <div className='armorial-navbar-text navbar-biblio'>
                Menu
              </div>                
            </Link>
            {connect ? 
            <div className='armorial-navbar-text navbar-biblio' onClick={() => {
            navigate('/bibliotheques/administration')
            }}>
              Administration
            </div> 
            : 
            <Link to="/administration/connexion" state={{ from: '/bibliotheques/administration' }}>
              <div className='armorial-navbar-text navbar-biblio'>
                Administration
              </div>                
            </Link>
            }
        </div>
            

        <div id="bibli-buttons-container">
            <button className='bibli-button'>
            <Link to="/bibliotheques/oeuvres">
                  Bibliothèque numérique                
              </Link>
          </button>

          <button className='bibli-button'>
            <Link to="http://annebhd.free.fr/heraldique/biblio_herald.htm">
                  Bibliographie               
            </Link>
          </button>
        </div>
        
    </div>
  )
}